<div class="cadre_scroll" >

  <section class="content ecommerce-page" style="overflow: auto !important;">
    
    <div class="block-header">
      <br>
      <div class="row">
        <div class="col-lg-7 col-md-6 col-sm-12">
          <h2>Liste des actualités
            <small class="text-muted">Bienvenue au Backoffice de Plaisir du Jardin</small>
          </h2>
        </div>
        <div class="col-lg-5 col-md-6 col-sm-12">
          <button
          style="
          background: #f4f6f9 !important;
          color: #ffd136!important;
          border:2px solid #ffd136;
          float: right;
          border-radius: 7px;
          font-weight: bolder;
          font-size: medium;
          margin-top: -23px;
          margin-right:17px;
        "
          class="btn btn-secondary"
          [routerLink]="['/user-profile', id, 'actualités','ajouter']"
          >
            Ajouter
          </button>
          
        </div>
      </div>
    </div>
  
    <div class="  cardblock py-5" style="margin-left: 9%;margin-right: 9%;">
      <div *ngIf="projectslist" class="row">
        <div *ngIf="sizeList > 6" class="d-flex justify-content-start" >
        
          <pagination-controls 
            class="my-pagination"
            previousLabel="Previous"
            nextLabel="Suivant"
            (pageChange)="onTableDataChange($event)"
          >
          </pagination-controls>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12"  *ngFor="let project of projectslist.reverse() | paginate
        : {
            itemsPerPage: tableSize,
            currentPage: page,
            totalItems: count
          };
              let i = index"
          >
          <div class="card zoom" style="height: 310px; cursor: pointer;" >
            <img
              src="{{ uploadUrl }}{{ project.background }}"
              class="image"
              alt=""
              style="max-height: 209px;min-height: 200px;"
            />
            <div class="card-body">
              <div class="colum">
                <div > <h5 class="card-title" style="margin-bottom: 2% ;text-transform: capitalize; font-size: 1.3em;" >
                  {{ (project.page_name.length>30)? (project.page_name | slice:0:20)+'...':(project.page_name) }}
                </h5>
                 </div>
                <div class="col" style="text-align: center !important;">


                <p class="card-text description">

                  <a
                    data-toggle="modal"
                    data-target="#exampleModal"
                    (click)="setObjectTotrait(project);setHideMessage(project);"
                  >
                    <i
                      *ngIf="!project.hide_page"
                      class="zmdi zmdi-eye"
                      style="cursor: pointer"
                      title="Masquer"
                    ></i>
                    <i
                      *ngIf="project.hide_page"
                      class="zmdi zmdi-eye-off"
                      style="cursor: pointer"
                      title="Afficher"
                     
                    ></i>
                  </a>
                  &nbsp;&nbsp;
                  <a >
                    <i
                      class="zmdi zmdi-edit"
                      style="cursor: pointer"
                      title="Modifier"
                      [routerLink]="['/user-profile',id,'actualités',project._id,'modifier']"
                    ></i
                  ></a>
                  &nbsp;&nbsp;
                  <a>
                    <i
                      *ngIf="!project.deleted_page"
                      class="zmdi zmdi-delete"
                      data-toggle="modal"
                      data-target="#exampleModall"
                      (click)="projectToDelete = project"
                      style="cursor: pointer"
                      title="Supprimer"
                    ></i>
                  </a>
                </p>
                </div>
                <br />
                <br />
                <br />
              </div>
            </div>
            <br /><br /><br /><br /><br />
          </div>
        </div>
      
       
        
       

      </div>

    </div>
    <div *ngIf="isloading" class="bodre" >
      <div *ngIf="isloading" class="loader" >
          <style>
              /* Safari */
              @-webkit-keyframes spin {
                  0% {
                      -webkit-transform: rotate(0deg);
                  }

                  100% {
                      -webkit-transform: rotate(360deg);
                  }
              }

              @keyframes spin {
                  0% {
                      transform: rotate(0deg);
                  }

                  100% {
                      transform: rotate(360deg);
                  }
              }
          </style>
      </div>
  </div>
    
  </section>
  
</div>

<!-- Modal -->
<div
  class="modal fade"
  id="exampleModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header" style="padding: 1rem 1rem;
      ">
        <h3 style="color: #868579;
        line-height: 1.625;
        font-size: 1.75rem !important;"  *ngIf="HideAction==true" class="modal-title" id="exampleModalLabel">
          Confirmation de masquage
        </h3>
        <h3 style="color: #868579;
        line-height: 1.625;
        font-size: 1.75rem !important;"  *ngIf="DisplayAction==true" class="modal-title" id="exampleModalLabel">
          Confirmation de récupération
        </h3>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-4">
            <img src="assets/images/1.jpg" alt="" />
          </div>
          <div class="col-8">
            <h4  *ngIf="HideAction==true" style="    position: relative;
            top: 0px !important;color: #868579;">
              Êtes-vous sûr(e) de vouloir masquer cette actualité ?
            </h4>
            <h4 *ngIf="DisplayAction==true" style="    position: relative;
            top: 0px !important;color: #868579;">
              Êtes-vous sûr(e) de vouloir réafficher cette actualité ?
            </h4>
          </div>
        </div>
      </div>
      <div class="modal-footer">  <div class="col-6"></div>
      <div class="col-6">
        <button
          type="button"
          style="
        background: white!important;
          color: #ffd136!important;
          border: 1px solid #ffd136!important;
          "
          class="btn btn-secondary"
          (click)="Annuler()"
          data-dismiss="modal"
        >
          Annuler
        </button>
        <button
        style="
          background: #ffd136!important;
          color: #fff!important;
      "
        class="btn btn-add "
        data-dismiss="modal"
          (click)="updateDisplayProject()"
        >
          Confirmer
        </button>
      </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="exampleModall"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabell"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header" style="padding: 1rem 1rem;
   ">
        <h3 style="color: #868579;
        line-height: 1.625;
        font-size: 1.75rem !important;"  class="modal-title" id="exampleModalLabel">
          Confirmation de suppression
        </h3>

        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div  class="modal-body">
        <div class="row">
          <div class="col-4"><img src="assets/images/delete.png" alt="" /></div>
          <div class="col-8">
            <h4 style="    position: relative;
            top: 0px !important;color: #868579;">
              Êtes-vous sûr(e) de vouloir supprimer cette actualité?
            </h4>
          </div>
        </div>
      </div>
      <!-- <div *ngIf="HideAction==true" class="modal-body">
        <div class="row">
          <div class="col-4"><img src="assets/images/wondering.png" alt="" /></div>
          <div class="col-8">
            <h3 style="position: relative; top: 0px !important">
              Êtes-vous sûr(e) de vouloir masquer cet article?
            </h3>
          </div>
        </div>
      </div> -->
      <!-- <div *ngIf="DisplayAction==true" class="modal-body">
        <div class="row">
          <div class="col-4"><img src="assets/images/wondering.png" alt="" /></div>
          <div class="col-8">
            <h3 style="position: relative; top: 0px !important">
              Êtes-vous sûr(e) de vouloir réafficher cet article?
            </h3>
          </div>
        </div>
      </div> -->
      
      <div class="modal-footer" style="    padding: 1rem;
      border-top: 1px solid #e7eaf0">

          <div class="col-6"></div>
          <div class="col-6">
        <button

          type="button"
          style="
          background: white!important;
          color: #ffd136!important;
          border: 1px solid #ffd136!important;
        "
          class="btn btn-secondary btn-lg"
          data-dismiss="modal"
          (click)="Annuler()"
        >
          Annuler
        </button>
        <button
          type="button"
          style="
          background: #ffd136!important;
          color: #fff!important;
        "
          class="btn btn-add btn-lg "
          data-dismiss="modal"
          (click)="deleteDisplayProject()"
        >
          Confirmer
        </button>
      </div>
   
      </div>
    </div>
  </div>
</div>

<!-- Modal -->

