
<div class="cadre_scroll">
  <section class="content ecommerce-page">
    <div class="block-header">
      <div class="row">
        <div class="col-lg-7 col-md-6 col-sm-12">
          <h2>Modification de la page Contact
            <small class="text-muted">Bienvenue au Backoffice de Plaisir du Jardin</small>
          </h2>
        </div>
        <div class="col-lg-5 col-md-6 col-sm-12">
          <ul class="breadcrumb float-md-right">
            <li class="breadcrumb-item"><a routerLink="../../"><i class="zmdi zmdi-home"></i>
              Backoffice</a>
            </li>
            <li class="breadcrumb-item"><a>Pages</a></li>
            <li class="breadcrumb-item active">Page contact</li>
          </ul>
        </div>
        
      </div>
    </div>
    <div class="container-fluid">
      <div class="row clearfix">
        <div class="col-lg-12">
          <div class="card">
            <div class="body">
              <div class="row">
                <div class="col-4">
                  <div class="list-group list-group-flush" id="list-tab" role="tablist">
                    <a *ngFor="let section of fixedPageContent.sections;let i = index"
                       class="list-group-item list-group-item-action" [ngClass]="(i==selectedPageContentIndex)?'active':''"
                       id="list-cover-list" data-toggle="list" href="#list-cover" role="tab"
                       aria-controls="cover"
                       (click)="selectedPageContentIndex=i;">{{section.section_name | replace: '_' : ' ' | titlecase}}</a>
                  </div>
                </div>
                <div class="col-8 border-left-yellow">
                  <div class="tab-content">
                    <div class="tab-pane fade show active" id="list-cover" role="tabpanel"
                         aria-labelledby="list-cover-list">
                      <h5><b>Section {{fixedPageContent.sections[selectedPageContentIndex].section_name | replace: '_' : ' ' | titlecase}}:</b>
                      </h5>
                      <h6>Langue:</h6>
                      <div class="row mb-4">
                        <ng-container
                          *ngFor="let contents of selectedPageContent.sections[selectedPageContentIndex].contents; let contentsI= index">
                          <button class="btn btn-admin col-1" [ngClass]="(contents.language=='fr')?'current':''"
                                  style="text-transform: uppercase">{{contents.language}}</button>
                        </ng-container>
                        <button class="btn btn-admin col-1"
                                style="text-transform: uppercase">nl
                        </button>
                      </div>
                      <div class="form-group mb-4">
                        <div class="d-flex"><label>Titre : <span
                          class="form-text text-muted">({{fixedPageContent.sections[selectedPageContentIndex].title}}
                          )</span></label>
                        </div>

                        <input type="email" class="form-control"
                               [(ngModel)]="selectedPageContent.sections[selectedPageContentIndex].title"
                               placeholder="Tapez le nouveau titre section">
                      </div>
                      <div class="form-group"
                           *ngIf="fixedPageContent.sections[selectedPageContentIndex].background != ''">
                        <hr/>
                        <div class="row"><label class="col-2">Arrière plan : </label>
                          <picture class="col-4">
                            <img [src]="uploadUrl+fixedPageContent.sections[selectedPageContentIndex].background"
                                 class="img-fluid img-thumbnail" style="width: 15vw;height: auto" alt="...">
                          </picture>
                          <input (change)="changeBackgroundSection($event.target)" type="file"
                                 class="form-control col-3" style="height: 50px">
                        </div>

                      </div>

                      <ng-container class="mb-2"
                                    *ngFor="let contents of selectedPageContent.sections[selectedPageContentIndex].contents; let contentsI= index">
                        <ng-container *ngFor="let content of contents.content;let contentI = index">

                          <div class="form-group"
                               *ngIf="content.content_type=='image'">
                            <hr/>
                            <div class="row"><label class="col-2">Arrière plan : </label>
                              <picture class="col-4">
                                <img [src]="uploadUrl+content.elements[0].background_img"
                                     class="img-fluid img-thumbnail" style="width: 15vw;height: auto" alt="...">
                              </picture>
                              <input (change)="changeBackgroundImg($event.target,[contentsI,contentI,0])" type="file"
                                     class="form-control col-3" style="height: 50px">
                            </div>

                          </div>

                          <div *ngIf="content.content_type=='paragraphes'">
                            <hr/>
                            <div class="form-group">
                              <label>Paragraphe :</label>
                              <angular-editor [placeholder]="'Enter text here...'"
                                              [(ngModel)]="content.elements[0].text"></angular-editor>
                            </div>
                            <div *ngIf="selectedPageContentIndex == 1" >
                              <hr/>
                              <div class="form-group">
                                <p>Horaires d’ouverture : </p>
                                <div class="row gx-3">
                                  <ng-container>
                                      <div class="row ml-1 mt-2">
                                        <input type="email" class=" col-7 form-control"
                                           [(ngModel)]="selectedPageContent?.sections[selectedPageContentIndex]?.contents[0]?.content[3]?.elements[0].jours1"
                                           placeholder="Tapez le nouveau titre section">
                                           <input type="email" class="ml-1 col-3 form-control"
                                           [(ngModel)]="selectedPageContent?.sections[selectedPageContentIndex]?.contents[0]?.content[3]?.elements[0].heures1"
                                           placeholder="Tapez le nouveau titre section">
                                                </div>
                                                <div class="row ml-1 mt-2">
                                                  <input type="email" class=" col-7 form-control"
                                                     [(ngModel)]="selectedPageContent?.sections[selectedPageContentIndex]?.contents[0]?.content[3]?.elements[0].jours2"
                                                     placeholder="Tapez le nouveau titre section">
                                                     <input type="email" class="ml-1 col-3 form-control"
                                                     [(ngModel)]="selectedPageContent?.sections[selectedPageContentIndex]?.contents[0]?.content[3]?.elements[0].heures2"
                                                     placeholder="Tapez le nouveau titre section">
                                                          </div>
                                      </ng-container>
                                      </div>
                                      </div>
                                      </div>
                          </div>
                          

                                    <div *ngIf="content.content_type=='inputadresse'" >
                                      <hr/>
                                      <div class="form-group">
                                        <p>Réseaux sociaux  : </p>
                                        <div class="row gx-3">
                                          <ng-container
                                            *ngFor="let element of content.elements;let elementI = index;">
                                            <div class="col-3">
                                      <input type="email" class="form-control"
                                         [(ngModel)]="selectedPageContent.sections[selectedPageContentIndex].contents[contentsI].content[contentI].elements[elementI].text"
                                         placeholder="Tapez le nouveau titre section">
                                   
                                              </div>
                                             
                                              </ng-container>
                                              </div>
                                              </div>
                                              </div>

                                    <div *ngIf="content.content_type=='inputQuestions'" >
                                      <hr/>
                                      <div class="form-group">
                                        <p>Questions : </p>
                                        <div class="row gx-3">
                                          <ng-container
                                            *ngFor="let element of content.elements;let elementI = index;">
                                            <div class="col-7">
                                      <input type="email" class="form-control mb-1"
                                         [(ngModel)]="fixedPageContent.sections[selectedPageContentIndex].contents[contentsI].content[contentI].elements[elementI].text"
                                         placeholder="Tapez le nouveau titre section">
                                   
                                              </div>
                                              
                                              </ng-container>
                                              </div>
                                              </div>
                                              </div>

                          <div *ngIf="content.content_type=='buttons'">
                            <hr/>
                            <div class="form-group">
                              <p *ngIf="fixedPageContent.sections[selectedPageContentIndex].contents[contentsI].content[contentI].elements.length > 1 " >Boutons : </p>
                              <p *ngIf="fixedPageContent.sections[selectedPageContentIndex].contents[contentsI].content[contentI].elements.length == 1 " >Bouton : </p>
                              <div class="row gx-3">
                                <ng-container
                                  *ngFor="let element of content.elements;let elementI = index;">
                                  <div class="col-3">
                                    <button class="btn btn-admin current"
                                            style="text-transform: capitalize">
                                      <small>{{fixedPageContent.sections[selectedPageContentIndex].contents[contentsI].content[contentI].elements[elementI].btn_text}}</small>
                                      <input class="border"
                                             [(ngModel)]="element.btn_text"
                                             style="padding: 7px;transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;box-shadow: none;height: auto;width: 100%;background-color: white !important;border: 1px solid #E3E3E3;border-radius: 30px;color: #2c2c2c;line-height: normal;font-size: .8571em;position: relative;"
                                             type="text"
                                             placeholder="Button Text"/>
                                    </button>
                                    <div class="form-group">
                                      <label>Redirection:
                                        <small>{{fixedPageContent.sections[selectedPageContentIndex].contents[contentsI].content[contentI].elements[elementI].btn_redirection}}</small></label>
                                      <input type="text" class="form-control" placeholder="Button Text"
                                             [(ngModel)]="element.btn_redirection"
                                             style="width: 100%;transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;box-shadow: none;height: auto;background-color: rgba(0,0,0,0);border: 1px solid #E3E3E3;border-radius: 30px;color: #2c2c2c;line-height: normal;font-size: .8571em;position: relative;"/>
                                    </div>
                                  </div>
                                </ng-container>
                              </div>
                            </div>
                          </div>
<div *ngIf="content.content_type=='slider' && content.content_name=='slider_equipe'">
  <hr/>
  <p>Sliders : </p>
  <div *ngFor="let element of content.elements; let elemI = index">
    <div >
   

      <div class="form-group">
        
        <div class="row card mb-4" style="width: 70%;float: initial">
          <ng-container class="pb-3"
                        >
            <p>Slider :{{elemI + 1}} </p>
            <div class="form-group">
              <label>Image :</label>
              <div class="d-flex">
               
                <picture>
                  <img
                    [src]="uploadUrl+fixedPageContent.sections[selectedPageContentIndex].contents[contentsI].content[contentI].elements[elemI].background_img"
                    class="img-fluid img-thumbnail" style="width: 15vw;height: auto; " alt="...">
                </picture>
              </div>
            </div>
            <input (change)="changeBackgroundImg($event.target,[contentsI,contentI,elemI])"
               type="file" class="form-control">

            <div class="col-8">
              <div class="form-group">
                <label>Redirection:
                  <small></small></label>
                <input type="text" class="form-control" placeholder="Button Text" value=""
                       style="transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;box-shadow: none;height: auto;background-color: rgba(0,0,0,0);border: 1px solid #E3E3E3;border-radius: 30px;color: #2c2c2c;line-height: normal;font-size: .8571em;position: relative;"/>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      




  </div>
  </div>
</div> 


                           <div class="d-flex justify-content-center" style="padding-top:1% ;">
                    
                  </div> 
                          <div *ngIf="content.content_type=='images'">
                            <hr/>
                            <div class="form-group">
                              <p>Images : </p>
                              <div class="row btn-row mb-4" style="width: 70%;float: initial">
                                <ng-container class="col-4"
                                              *ngFor="let element of content.elements;let elementI = index">
                                  <div class="form-group">
                                    <label>Image : {{elementI + 1}}</label>
                                    <div class="d-flex"><label>Arrière plan : </label>
                                      <picture>
                                        <img
                                          [src]="fixedPageContent.sections[selectedPageContentIndex].contents[contentsI].content[contentI].elements[elementI].img"
                                          class="img-fluid img-thumbnail" style="width: 15vw;height: auto" alt="...">
                                      </picture>
                                    </div>
                                  </div>
                                  <input (change)="changeImg($event.target,[contentsI,contentI,elementI])"
                                         type="file" class="form-control">

                                  <div class="col-8">
                                    <div class="form-group">
                                      <label>Redirection:
                                        <small>{{fixedPageContent.sections[selectedPageContentIndex].contents[contentsI].content[contentI].elements[elementI].url}}</small></label>
                                      <input type="text" class="form-control" placeholder="Button Text"
                                             [(ngModel)]="element.url"
                                             style="transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;box-shadow: none;height: auto;background-color: rgba(0,0,0,0);border: 1px solid #E3E3E3;border-radius: 30px;color: #2c2c2c;line-height: normal;font-size: .8571em;position: relative;"/>
                                    </div>
                                  </div>
                                </ng-container>
                              </div>
                            </div>
                          </div>
                          

                          
                        </ng-container>
                      </ng-container>

                      <div class="row btn-row">
                        <div class="col-6">
                          <button class="annulation-btn" type="button">Annuler
                          </button>
                        </div>
                        <div class="col-6">
                          <button class="validate-btn" value="submit" type="button"
                                  (click)="updateSection()"
                                  [disabled]="loading">Valider
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
