import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PreloaderComponent } from 'src/app/backoffice/_common/preloader/preloader.component';
import { HomePageService } from 'src/app/_shared/home-page.service';
import { ProductsService } from 'src/app/_shared/products.service';
import { ProjectService } from 'src/app/_shared/project.service';
import { environment } from 'src/environments/environment';
import { ReplacePipe } from '../../../../pipe/replace.pipe';

@Component({
  selector: 'app-home-cms',
  template: `<app-preloader></app-preloader>`,
  templateUrl: './home-cms.component.html',
  styleUrls: ['./home-cms.component.scss']
})
export class HomeCMSComponent implements OnInit {
  ftpUrl=`${environment.ftpUrl}`
  @ViewChild(PreloaderComponent) loader: PreloaderComponent;
  page: number = 1;
  count: number = 0;
  tableSize: number = 1;
  tableSizes: any = [3, 6, 9, 12];
  title = 'upload-image';
  filesPath = [] ;

  constructor(
    private homeService: HomePageService,
    private toastr: ToastrService,
    public replace: ReplacePipe,
    private projectService : ProjectService,
    private productService: ProductsService,
    private router: Router,
  ) {
    
  }
   uploadUrl=`${environment.uploadUrl}`
  p: number =1;
  currentPage : any;
  itemsPerPage : any;
  paginate : any;
  actualiteList: any;
  projectslist: any;
  actu_section: any;
  actuForm: FormGroup;
  actu: any;
  loading = false;
  submitted = false;
  projet_section: any;
  projetForm: FormGroup;
  projet: any;
  projectChanged: boolean = false;
  actualiteChanged: boolean = false;
  products: any;
  fixedProduct: any;
  searchproduct: string;
  searchproject: string;
  projects: any;
  searchactuality: string;
  actualities: any;
  items = [];
  
  project : any;
  actualite : any;
  loaderr : boolean = false;

  selectedPageContentIndex = 0;

  fixedPageContent: any;

  selectedPageContent: any;
  url = '/produit';
  slash= '/';
  //couvPic : any;

  get_page_content() {
    this.homeService.get_page_content('home').subscribe(homeContent => {
      this.fixedPageContent = homeContent;
      this.selectedPageContent = this.fixedPageContent;
      this.fixedPageContent.sections[1].section_name = 'Qui_sommes-nous';
      this.selectedPageContent.sections[1].section_name = 'Qui_sommes-nous';
      this.fixedPageContent.sections[4].section_name = 'Notre_Équipe';
      this.selectedPageContent.sections[4].section_name = 'Notre_Équipe';
      this.fixedPageContent.sections[5].section_name = 'Visiter_notre_boutique';
      this.selectedPageContent.sections[5].section_name = 'Visiter_notre_boutique';
      this.fixedPageContent.sections[9].section_name = 'Nos dernières actualités';
      this.selectedPageContent.sections[9].section_name = 'Nos dernières actualités';

     // this.couvPic = this.selectedPageContent.sections[0].background;
      
      this.loaderr = true;
      this.loader.remove();
        
     
      //console.log('aaaaaaaaaaa',this.fixedPageContent)
      //console.log('aaaaaaaaaaa',this.selectedPageContent)
    });
    
  }
  

  onTableDataChange(event: any) {
    this.page = event;
  }
  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    
  }
  addTeam(index: any){
    this.selectedPageContent.sections[4].contents[0].content[2].elements[index].equipe.push({})
  }

  changeBackgroundSection(input) {
    if(input.files[0].size < 10*1024*1024)
    {
    if (input.files && input.files[0]) {
      this.filesPath.push(input.files[0])
        this.selectedPageContent.sections[this.selectedPageContentIndex].background = input.files[0].name;
        this.fixedPageContent.sections[this.selectedPageContentIndex].background = input.files[0].name;
      };
  }else
  this.toastr.error('Image très large! merci de ne pas dépasser 10 Mo');
  }
  changeBackgroundSectionProject(input, index) {
    if(input.files[0].size < 10*1024*1024)
    {
    if (input.files && input.files[0]) {
      this.filesPath.push(input.files[0])
        this.selectedPageContent.sections[3].contents[1].content[0].elements[0].projects[index].background = input.files[0].name;
        this.fixedPageContent.sections[3].contents[1].content[0].elements[0].projects[index].background = input.files[0].name;
        this.projectslist[index].background = this.selectedPageContent.sections[3].contents[1].content[0].elements[0].projects[index].background;
        this.project = this.projectslist[index];
        this.projectChanged = true;
      };
  }else
  this.toastr.error('Image très large! merci de ne pas dépasser 10 Mo');
  }

  changeBackgroundSectionActualite(input, index) {
    if(input.files[0].size < 10*1024*1024)
    {
    if (input.files && input.files[0]) {
      this.filesPath.push(input.files[0])
        this.selectedPageContent.sections[9].contents[1].content[0].elements[0].actualities[index].background = input.files[0].name;
        this.fixedPageContent.sections[9].contents[1].content[0].elements[0].actualities[index].background = input.files[0].name;
        this.actualiteList[index].background = this.selectedPageContent.sections[9].contents[1].content[0].elements[0].actualities[index].background;
        this.actualite = this.actualiteList[index];
        this.actualiteChanged = true;
        
      };
    
  }else
  this.toastr.error('Image très large! merci de ne pas dépasser 10 Mo');
  }

  detecteChangeDescActualite(input, index) {
    this.actualiteList[index].description = input.target.outerText;
    this.actualite = this.actualiteList[index];
        this.actualiteChanged = true;
  }

  detecteChangeURLActualite(input, index) {
    this.actualiteList[index].page_url = input.target.value;
    this.actualite = this.actualiteList[index];
        this.actualiteChanged = true;
  }

  changeBackgroundImg(input, arrayElementIndex) {
    if(input.files[0].size < 10*1024*1024)
    {
    if (input.files && input.files[0]) {
      this.filesPath.push(input.files[0])
        this.selectedPageContent.sections[this.selectedPageContentIndex].contents[arrayElementIndex[0]].content[arrayElementIndex[1]].elements[arrayElementIndex[2]].background_img = input.files[0].name;
        this.fixedPageContent.sections[this.selectedPageContentIndex].contents[arrayElementIndex[0]].content[arrayElementIndex[1]].elements[arrayElementIndex[2]].background_img = input.files[0].name;
        // console.log(this.selectedPageContent.sections[this.selectedPageContentIndex].contents[arrayElementIndex[0]].content[arrayElementIndex[1]].elements[arrayElementIndex[2]])
      };
  }else
  this.toastr.error('Image très large! merci de ne pas dépasser 10 Mo');
  }


  changeImg(input, arrayElementIndex) {
    if(input.files[0].size < 10*1024*1024)
    {
    if (input.files && input.files[0]) {
      this.filesPath.push(input.files[0])
        this.selectedPageContent.sections[this.selectedPageContentIndex].contents[arrayElementIndex[0]].content[arrayElementIndex[1]].elements[arrayElementIndex[2]].img = input.files[0].name;
        this.fixedPageContent.sections[this.selectedPageContentIndex].contents[arrayElementIndex[0]].content[arrayElementIndex[1]].elements[arrayElementIndex[2]].img = input.files[0].name;
      };
  }else
  this.toastr.error('Image très large! merci de ne pas dépasser 10 Mo');
  }
  changeImgProduct(input, arrayElementIndex) {
    if(input.files[0].size < 10*1024*1024)
    {
    if (input.files && input.files[0]) {
      this.filesPath.push(input.files[0])
        this.selectedPageContent.sections[this.selectedPageContentIndex].contents[arrayElementIndex[0]].content[arrayElementIndex[1]].elements[arrayElementIndex[2]].s_image = input.files[0].name;
        this.fixedPageContent.sections[this.selectedPageContentIndex].contents[arrayElementIndex[0]].content[arrayElementIndex[1]].elements[arrayElementIndex[2]].s_image = input.files[0].name;
      };
    
  }else
  this.toastr.error('Image très large! merci de ne pas dépasser 10 Mo');
  }
  addProduct(productName: string, productImage: any, productId: string, s_cat1: string, rayon:string, index, img2) {
    this.products = this.fixedProduct
    this.searchproduct = ''
    const checkExist = obj => (obj._id === productId && obj.product === productName);
    if(!this.selectedPageContent.sections[7].contents[1].content[0].elements[0].produit.some(checkExist))
    {
    // @ts-ignore 
    this.selectedPageContent.sections[7].contents[1].content[0].elements[0].produit.push({product: productName, image: productImage, _id: productId, s_cat1: s_cat1, rayon : rayon, redirection: '/produit/' + s_cat1 + '/'+productName+'/'+productId, image2 : img2});
    this.toastr.success('Le produit a été ajouté avec succès!');
  }
    else
    this.toastr.error('Vous ne pouvez pas ajouter le même produit plus qu\'une fois ');
  
  }
  addProject(projectName: string, projectImage: any, projectId: string, url: string, index: string, hide_page: boolean, deleted_page: boolean) {
    this.projects = this.projectslist
    this.searchproject = ''
    const checkExist = obj => (obj._id === projectId);
    if(!this.selectedPageContent.sections[3].contents[1].content[0].elements[0].projects.some(checkExist))
    {
    // @ts-ignore 
    this.selectedPageContent.sections[3].contents[1].content[0].elements[0].projects.push({page_name: projectName, background: projectImage, _id: projectId, page_url: url, hide_page: hide_page, deleted_page: deleted_page});
    this.toastr.success('Le projet a été ajouté avec succès!');
  }
    else
    this.toastr.error('Vous ne pouvez pas ajouter le même projet plus qu\'une fois ');
}
addActuality(actualityName: string, actualityImage: any, actualityId: string, url: string, index: string, description: string, hide_page: boolean, deleted_page: boolean) {

  this.actualities = this.actualiteList
  this.searchactuality = ''
  const checkExist = obj => (obj._id === actualityId);
    if(!this.selectedPageContent.sections[9].contents[0].content[0].elements[0].actualities.some(checkExist)){
  // @ts-ignore
  this.selectedPageContent.sections[9].contents[0].content[0].elements[0].actualities.push({page_name: actualityName, background: actualityImage, _id: actualityId, page_url: url, description: description, hide_page: hide_page, deleted_page: deleted_page});
  this.toastr.success('L\'actualité a été ajoutée avec succès!');
    }
  else
  this.toastr.error('Vous ne pouvez pas ajouter la même actualité plus qu\'une fois ');
}
deleteProduct(index: number){
  this.selectedPageContent.sections[7].contents[1].content[0].elements[0].produit.splice(index, 1);
}
deleteProject(index: number){
  this.selectedPageContent.sections[3].contents[1].content[0].elements[0].projects.splice(index, 1);
}
deleteActuality(index: number){
  this.selectedPageContent.sections[9].contents[0].content[0].elements[0].actualities.splice(index, 1);
}
deleteEquipe(elemIndex: number,index: number){
  this.fixedPageContent?.sections[4]?.contents[0]?.content[2]?.elements[elemIndex].equipe.splice(index,1);
}

editProject(projectId) {
  this.router.navigate(['user-profile/' + 'null' + '/projet/' + projectId + '/modifier'])
}
editActualite(actualityId) {
  this.router.navigate(['user-profile/' + 'null' + '/actualités/' + actualityId + '/modifier'])
}

  searchProduct() {
    this.products = this.fixedProduct.filter(x => {
      let name = x.s_modele_market.toLowerCase().toString();
      return name.includes(this.searchproduct.toLowerCase());
    });
  }

  searchProject() {
    this.projects = this.projectslist.filter(x => {
      let name = x.page_name.toLowerCase().toString();
      return name.includes(this.searchproject.toLowerCase());
    });
  }

  searchActuality() {
    this.actualities = this.actualiteList.filter(x => {
      let name = x.page_name.toLowerCase().toString();
      return name.includes(this.searchactuality.toLowerCase());
    });
  }


  ngOnInit(): void {
  /*  setTimeout (function () {
    this.get_page_content();
  },50)*/
    this.projectService.get_project_list_client().subscribe(data => {
      this.projectslist = data;
      this.projects = data;});

      this.projectService.get_actualités_visible_not_deleted_list().subscribe(data => {
        this.get_page_content();
      this.actualiteList = data;
      this.actualities = this.actualiteList;
    });
    

      this.productService.get_all_product().subscribe(data => {
        this.fixedProduct = data;
        this.products = data;
      })
      
     
      
  }
  updateSection() {
    this.submitted = true;
    this.loading = true;
    for(let i=0; i<this.fixedPageContent?.sections[4]?.contents[0]?.content[2]?.elements?.length; i++){
            if(this.fixedPageContent?.sections[4]?.contents[0]?.content[2]?.elements[i].hasOwnProperty("equipe")){
    for(let j=this.fixedPageContent?.sections[4]?.contents[0]?.content[2]?.elements[i]?.equipe?.length; j>=0; j--)
    {
      if(this.fixedPageContent?.sections[4]?.contents[0]?.content[2]?.elements[i]?.equipe[j]?.name == undefined || this.fixedPageContent?.sections[4]?.contents[0]?.content[2]?.elements[i]?.equipe[j]?.name == ''){
      this.fixedPageContent?.sections[4]?.contents[0]?.content[2]?.elements[i].equipe.splice(j,1)
    }
    }
  }
  }
   //"jpg"|"JPG"|"jpeg"|"JPEG"|"png"|"PNG"|"gif"|"GIF"
   var types = ["image/png", "image/gif", "image/jpeg", "application/pdf"]
   console.log(this.filesPath)
   this.filesPath.forEach(element => {
     
       const formData: FormData = new FormData();
       formData.append('files', element);
   
       this.projectService.uploadImages(formData).subscribe(res =>{
         console.log("done")
       })
   
   });
   
    this.homeService.update_section_content('home', this.selectedPageContent.sections[this.selectedPageContentIndex]).subscribe((data) => {
      this.loading = false;
      this.submitted = false;
      let str = (this.replace.transform(this.selectedPageContent.sections[this.selectedPageContentIndex].section_name, '_', ' ')).toLowerCase();
      this.toastr.success('La section ' + str + ' a été modifiée avec succès!');
      this.get_page_content();
      if(this.projectChanged){
      this.projectService.update_porject_image_and_url(this.project).subscribe(data => {
        
      });
      this.projectChanged = false;
      }
      if(this.actualiteChanged){
      this.projectService.update_actualite_image_and_url(this.actualite).subscribe(data => {
      });
      this.actualiteChanged = false;
    }
    this.filesPath = []
    });
  }

}
