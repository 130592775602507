<div class="cadre_scroll">
  <section class="content ecommerce-page">
    <div class="block-header">
      <div class="row">
        <div class="col-lg-7 col-md-6 col-sm-12">
          <h2>Nouvelle actualité
            <small class="text-muted">Bienvenue au Backoffice de Plaisir du Jardin</small>
          </h2>
        </div>
          <div class="col-lg-5 col-md-6 col-sm-12">
            <ul class="breadcrumb float-md-right">
              <li class="breadcrumb-item"><a routerLink="../../"><i class="zmdi zmdi-home"></i>
                Backoffice</a>
              </li>
              <li class="breadcrumb-item"><a>Actualités</a></li>
              <li class="breadcrumb-item active">Ajouter</li>
            </ul>
          </div> 
      </div>
    </div>
    <ngx-spinner type="ball-spin"></ngx-spinner>
    <div class="container-fluid">
      <div class="row clearfix">
        <div class="col-lg-12">
          <div class="card">
            <div class="body">
              <div class="row">
                <div class="col-lg-12 col-12 project-listing">
                  <div class="tab-content">
                    <div class="tab-pane fade show active" id="list-cover" role="tabpanel"
                         aria-labelledby="list-cover-list">
                      <!--  <h6>Langues:</h6> -->
                      <!-- <div class="row mb-4">
                        <ng-container>
                          <button class="btn btn-admin col-1 current"
                                  style="text-transform: uppercase">FR
                          </button>
                        </ng-container>
                        <button class="btn btn-admin col-1"
                                style="text-transform: uppercase">NL
                        </button>
                      </div> -->

                      <h5>Informations générales </h5>

                      <!-- show page projet -->
                      <div class="form-group mb-4">
                        <div class="d-flex"> 
                          <label>Masquer l'actualité : 
                          <input type="checkbox"
                        [(ngModel)]="projectStructure.hide_page"></label>
                        </div>

                      </div>
                      <!-- titre page projet -->
                      <div class="form-group mb-4">
                        <div class="d-flex"><label>Titre : </label>
                        </div>

                        <input type="text" class="form-control" [(ngModel)]="projectStructure.page_name"
                               placeholder="Tapez le nouveau titre section"
                               (keyup)="generateUrlPoject();generateBannerTitle();checkUrlExist();"
                               (change)="checkExist()">
                      </div>


                      

                      <!-- url page projet -->
                      <div class="form-group mb-4">
                        <div class="d-flex"><label>URL : </label>
                        </div>

                        <input type="email" class="form-control" [(ngModel)]="projectStructure.page_url"
                               placeholder="Tapez le nouveau titre section" disabled>
                      </div>
                   

                      
                      

                      <h5>Contenu :</h5>



                      <div class="d-flex align-items-start">
                        <div class="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist"
                             aria-orientation="vertical">
                          <button class="nav-link active m-2" style="border:0" id="v-pills-banner-tab"
                                  data-bs-toggle="pill" data-bs-target="#v-pills-banner" type="button"
                                  role="tab" aria-controls="v-pills-home" aria-selected="true">Section 1 - Couverture
                          </button>
                          <button class="nav-link m-2" style="border:0" id="v-pills-les-attentes-du-client-tab"
                                  data-bs-toggle="pill" data-bs-target="#v-pills-les-attentes-du-client" type="button"
                                  role="tab" aria-controls="v-pills-home" aria-selected="true">Section 2
                          </button>
                          <button class="nav-link m-2" style="border:0" id="v-pills-notre-approche-tab"
                                  data-bs-toggle="pill" data-bs-target="#v-pills-notre-approche" type="button"
                                  role="tab" aria-controls="v-pills-profile" aria-selected="false">Section 3
                          </button>
                          <button class="nav-link m-2" style="border:0" id="v-pills-le-résultat-final-tab"
                                  data-bs-toggle="pill" data-bs-target="#v-pills-le-résultat-final" type="button"
                                  role="tab" aria-controls="v-pills-messages" aria-selected="false">Section 4
                          </button>
                          <button class="nav-link m-2" style="border:0" id="v-pills-meta-data-tab"
                                  data-bs-toggle="pill" data-bs-target="#v-pills-meta-data" type="button"
                                  role="tab" aria-controls="v-pills-messages" aria-selected="false">SEO
                          </button>
                        </div>
                        <div class="tab-content" id="v-pills-tabContent">
                          <div class="tab-pane fade show active" id="v-pills-banner" role="tabpanel"
                               aria-labelledby="v-pills-banner-tab">


                            <!--masquer section couverture-->
                               <div class="form-group mb-4">
                                <div class="d-flex"><label>Masquer la section 1 - Couverture {{projectStructure.sections[0].title}} :
                                  <input type="checkbox" [(ngModel)]="projectStructure.sections[0].hide"></label>
                                </div>
  
                              </div>

                               <!-- titre page projet -->
                      <div class="form-group mb-4">
                        <div class="d-flex"><label>Titre : </label>
                        </div>

                        <input type="text" class="form-control" [(ngModel)]="projectStructure.page_name"
                               placeholder="Tapez le nouveau titre section"
                               (keyup)="generateUrlPoject();generateBannerTitle()">
                      </div>

                      <!-- catégorie de l'article -->
                      <div class="form-group mb-4">
                        <select  [(ngModel)]="projectStructure.category">
                          <option *ngFor="let category of categories" [ngValue]="category.name">{{category.name}}</option>
                        </select>
                        </div>

                        <!-- Arrière-plan page projet -->
                      <div class="form-group">
                        <hr/>
                        <div class="row"><label class="col-2">Photo : </label>
                          <picture class="col-4">
                            <img class="img-fluid img-thumbnail" [src]="BackgroundImage"
                                 style="width: 15vw;height: auto;min-height: 200px" alt="">
                          </picture>
                          <input type="file"
                                accept="image/png, image/jpg, image/jpeg" id="photofrsec1"
                                 class="form-control col-3" (change)="changeBackgroundSection($event.target)"
                                 style="height: 50px">
                        </div>

                      </div>
                            
                      


                            <div class="form-group">
                              <label>Description :</label>
                              <angular-editor [placeholder]="'Enter text here...'" [config]="config"
                                              [(ngModel)]="projectStructure.description"></angular-editor>
                            </div>
                            <div class="form-group mb-4">
                              <div class="d-flex"><label>Bouton : </label>
                              </div>

                              <input type="text" class="form-control"
                                     [(ngModel)]="projectStructure.sections[0].contents[0].content[2].elements[0]['btn_text']"
                                     placeholder="Tapez le nouveau titre section">
                            </div>

                          </div>
                          <div class="tab-pane fade show" id="v-pills-les-attentes-du-client" role="tabpanel"
                               aria-labelledby="v-pills-les-attentes-du-client-tab">

                            <div class="form-group mb-4">
                              <div class="d-flex"><label>Masquer la section {{projectStructure.sections[1].title}} :
                                <input type="checkbox" [(ngModel)]="projectStructure.sections[1].hide"></label>
                              </div>
                            </div>

                            <div class="ml-4">

                              <div class="form-group mb-4">
                                <div class="d-flex"><label>Titre:</label>
                                </div>

                                <input type="text" class="form-control" [(ngModel)]="projectStructure.sections[1].title"
                                       placeholder="Tapez le nouveau titre section">
                              </div>

                              <div class="form-group">
                                <label>Description :</label>
                                <angular-editor [placeholder]="'Enter text here...'" [config]="config"
                                                [(ngModel)]="projectStructure.sections[1].contents[0].content[0].elements[0]['description']"></angular-editor>
                              </div>


                              <div class="form-group">
                                <hr/>
                                <div class="row"><label class="col-2">Photo : </label>
                                  <picture class="col-4">
                                    <img class="img-fluid img-thumbnail"
                                         [src]="sectionImage2"
                                         style="width: 15vw;height: auto;min-height: 200px" alt="">
                                  </picture>
                                  <input type="file"
                                         accept="image/png, image/jpg, image/jpeg" id="photofrsec2"
                                         class="form-control col-3"
                                         (change)="addImg($event.target,[1,0,1,0],2)"
                                         style="height: 50px">
                                </div>

                              </div>

                              <div class="form-group">
                                <label>Paragraphe 1 :</label>
                                <angular-editor [placeholder]="'Enter text here...'" [config]="config"
                                                [(ngModel)]="projectStructure.sections[1].contents[0].content[2].elements[0]['description']"></angular-editor>
                              </div>

                              <div class="form-group">
                                <label>Paragraphe 2 :</label>
                                <angular-editor [placeholder]="'Enter text here...'" [config]="config"
                                                [(ngModel)]="projectStructure.sections[1].contents[0].content[2].elements[1]['description']"></angular-editor>
                              </div>

                            </div>

                          </div>
                          <div class="tab-pane fade" id="v-pills-notre-approche" role="tabpanel"
                               aria-labelledby="v-pills-notre-approche-tab">

                            <div class="form-group mb-4">
                              <div class="d-flex">  <label>Masquer la section {{projectStructure.sections[2].title}} :
                                <input type="checkbox" [(ngModel)]="projectStructure.sections[2].hide"></label>
                              </div>
                            </div>

                            <div class="ml-4">
                              <div class="form-group mb-4">
                                <div class="d-flex"><label>Titre:</label>
                                </div>

                                <input type="text" class="form-control" [(ngModel)]="projectStructure.sections[2].title"
                                       placeholder="Tapez le nouveau titre section">
                              </div>

                              <div class="form-group">
                                <label>Description :</label>
                                <angular-editor [placeholder]="'Enter text here...'" [config]="config"
                                                [(ngModel)]="projectStructure.sections[2].contents[0].content[1].elements[0]['description']"></angular-editor>
                              </div>

                              <div class="form-group">
                                <div class="row"><label class="col-2">Photo : </label>
                                  <picture class="col-4">
                                    <img class="img-fluid img-thumbnail"
                                         [src]="sectionImage3"
                                         style="width: 15vw;height: auto;min-height: 200px" alt="">
                                  </picture>
                                  <input type="file"
                                         accept="image/png, image/jpg, image/jpeg"
                                         class="form-control col-3" id="photofrsec3"
                                         (change)="addImg($event.target,[2,0,2,0],3)"
                                         style="height: 50px">
                                </div>

                              </div>

                            </div>

                          </div>
                          <div class="tab-pane fade" id="v-pills-le-résultat-final" role="tabpanel"
                               aria-labelledby="v-pills-notre-approche-tab">

                            <div class="form-group mb-4">
                              <div class="d-flex"> <label>Masquer la section {{projectStructure.sections[3].title}} :
                                <input type="checkbox" [(ngModel)]="projectStructure.sections[3].hide"></label>
                              </div>
                            </div>

                            <div class="ml-4">
                              <div class="form-group mb-4">
                                <div class="d-flex"><label>Titre:</label>
                                </div>

                                <input type="text" class="form-control" [(ngModel)]="projectStructure.sections[3].title"
                                       placeholder="Tapez le nouveau titre section">
                              </div>

                              <div class="form-group">
                                <label>Description :</label>
                                <angular-editor [placeholder]="'Enter text here...'" [config]="config"
                                                [(ngModel)]="projectStructure.sections[3].contents[0].content[1].elements[0]['description']"></angular-editor>
                              </div>

                              <div class="form-group">
                                <div class="row"><label class="col-2">Photo : </label>
                                  <picture class="col-4">
                                    <img class="img-fluid img-thumbnail"
                                         [src]="sectionImage4"
                                         style="width: 15vw;height: auto;min-height: 200px" alt="">
                                  </picture>
                                  <input type="file"
                                         accept="image/png, image/jpg, image/jpeg"
                                         class="form-control col-3" id="photofrsec4"
                                         (change)="addImg($event.target,[3,0,2,0],4)"
                                         style="height: 50px">
                                </div>

                              </div>

                            </div>

                          </div>




                          <div class="tab-pane fade" id="v-pills-meta-data" role="tabpanel"
                               aria-labelledby="v-pills-meta-data-tab">

                            <div class="form-group mb-4">
                              <div class="d-flex">  <label>Masquer la section {{projectStructure.sections[4].title}} :
                                <input type="checkbox" [(ngModel)]="projectStructure.sections[4].hide"></label>
                              </div>
                            </div>

                            <div class="ml-4">
                              <div class="form-group mb-4">
                                <div class="d-flex"><label>Meta title :</label>
                                </div>

                                <input type="text" class="form-control" [(ngModel)]="projectStructure.sections[4].title"
                                       placeholder="Tapez le nouveau meta title">
                              </div>

                              <div class="form-group">
                                <label>Meta description :</label>
                                <angular-editor [placeholder]="'Enter text here...'" [config]="config"
                                                [(ngModel)]="projectStructure.sections[4].contents[0].content[0].elements[0]['description']"></angular-editor>
                              </div>

                              <div class="form-group">
                                <label>Meta keyword :</label>
                                <div [formGroup]="form">
                                  <div class="editor-tag" (click)="focusTagInput()">
                                    <span class="tag-item" *ngFor="let tag of projectStructure.sections[4].contents[0].content[1].elements[0]['keywords']">
                                            {{tag}}
                                            <span class="remove-tag" (click)="removeTag(tag)">×</span>
                                          </span>
                                          <input
                                          placeholder="keywords"
                                          #tagInput type="text"
                                          class="input-tag"
                                          (keyup)="onKeyUp($event)"
                                          formControlName="tag">
                                        </div>
                                  </div>
                              </div>
                            </div>
                            

                          </div>





                        </div>
                      </div>


                      <hr/>
                      <div class="row btn-row">
                        <div class="col-6">
                          <button  [disabled]="isloading" class="annulation-btn" (click)="navigateListProject()" type="button">Annuler
                          </button>
                        </div>
                        <div class="col-6">
                          <button [disabled]="isloading" class="validate-btn" value="submit" type="button" (click)="submit()">Valider
                          </button>
                        </div>
                      </div>
                      <div *ngIf="isloading" class="bodre" >
                        <div *ngIf="isloading" class="loader" >
                            <style>
                                /* Safari */
                                @-webkit-keyframes spin {
                                    0% {
                                        -webkit-transform: rotate(0deg);
                                    }

                                    100% {
                                        -webkit-transform: rotate(360deg);
                                    }
                                }

                                @keyframes spin {
                                    0% {
                                        transform: rotate(0deg);
                                    }

                                    100% {
                                        transform: rotate(360deg);
                                    }
                                }
                            </style>
                        </div>
                    </div>
                    </div>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
