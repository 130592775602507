<div class="auth-wrapper">
    <form class="form-signin" [formGroup]="signupForm" (ngSubmit)="registerUser()">
      <svg width="75px" height="75px" style="width: 10%;display: block;margin-left: auto;margin-right: auto;margin-top: 30px;" viewBox="0 0 139 86" version="1.1" xmlns="http://www.w3.org/2000/svg"
           xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>Plaisir du jardin</title>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="logo_plaisirdujardin_darkgrey" fill-rule="nonzero">
            <path
              d="M84.4,16.2 C84.4,21.5 80.1,25.8 74.8,25.8 C69.5,25.8 65.2,21.5 65.2,16.2 C65.2,10.9 69.5,6.6 74.8,6.6 C80.2,6.6 84.4,10.9 84.4,16.2"
              id="Path" fill="#FED16E"></path>
            <path
              d="M37.7,52.3 C37.7,53 37.2,53.5 36.5,53.5 C35.8,53.5 35.3,53 35.3,52.3 L35.3,30.7 C35.3,30 35.8,29.5 36.5,29.5 L43.5,29.5 C47.3,29.5 50.2,31.7 50.2,36.4 C50.2,41 47.3,43.3 43.5,43.3 L37.7,43.3 L37.7,52.3 L37.7,52.3 Z M37.7,41.1 L43.2,41.1 C46.1,41.1 47.9,39.4 47.9,36.4 C47.9,33.3 46.1,31.7 43.2,31.7 L37.7,31.7 L37.7,41.1 L37.7,41.1 Z"
              id="Shape" fill="#343A40"></path>
            <path
              d="M56.2,29.3 L56.2,49.4 C56.2,50.8 56.7,51.4 57.6,51.4 C58.2,51.4 58.7,51.9 58.7,52.5 C58.7,53.1 58.2,53.6 57.6,53.6 C55.2,53.6 53.9,52.2 53.9,49.5 L53.9,29.4 C53.9,28.8 54.4,28.3 55,28.3 C55.7,28.2 56.2,28.7 56.2,29.3"
              id="Path" fill="#343A40"></path>
            <path
              d="M63.5,40.7 C63.1,41.1 62.9,41.2 62.6,41.2 C62,41.2 61.6,40.7 61.6,40.2 C61.6,40 61.7,39.8 61.8,39.6 C62.8,38 64.7,36.8 67.7,36.8 C71.8,36.8 74,39.2 74,42.8 L74,52.5 C74,53.1 73.5,53.6 72.9,53.6 C72.3,53.6 71.8,53.1 71.8,52.5 L71.8,51.2 C70.9,52.9 68.9,53.7 66.8,53.7 C63.1,53.7 60.7,51.9 60.7,48.7 C60.7,45.6 63.1,43.7 66.9,43.7 L71.9,43.7 L71.9,42.7 C71.9,40.3 70.3,38.8 67.7,38.8 C65.7,38.9 64.6,39.4 63.5,40.7 M71.8,47.2 L71.8,45.7 L66.8,45.7 C64.5,45.7 62.9,46.7 62.9,48.7 C62.9,50.5 64.2,51.7 66.9,51.7 C69.4,51.7 71.8,50.1 71.8,47.2"
              id="Shape" fill="#343A40"></path>
            <path
              d="M77.9,32.3 C77.9,31.3 78.4,30.9 79.2,30.9 C80,30.9 80.5,31.3 80.5,32.3 C80.5,33.1 80,33.6 79.2,33.6 C78.4,33.7 77.9,33.2 77.9,32.3 M80.4,52.4 C80.4,53 79.9,53.5 79.3,53.5 C78.7,53.5 78.2,53 78.2,52.4 L78.2,38.1 C78.2,37.5 78.7,37 79.3,37 C79.9,37 80.4,37.5 80.4,38.1 L80.4,52.4 L80.4,52.4 Z"
              id="Shape" fill="#343A40"></path>
            <path
              d="M86.8,41.1 C86.8,44.8 96.1,43.4 96.1,49.1 C96.1,52.3 93.5,53.8 90.1,53.8 C87.2,53.8 85,52.4 84.1,51.3 C83.9,51.1 83.9,50.9 83.9,50.6 C83.9,50 84.4,49.6 84.9,49.6 C85.1,49.6 85.4,49.7 85.7,50 C86.8,51.1 88.2,51.7 90.2,51.7 C92.2,51.7 93.9,51 93.9,49.2 C93.9,45.6 84.6,47 84.6,41.2 C84.6,38.3 87,36.9 90,36.9 C92.4,36.9 94.3,37.7 95.4,39.2 C95.5,39.3 95.6,39.6 95.6,39.9 C95.6,40.5 95.1,40.9 94.6,40.9 C94.4,40.9 94.1,40.9 93.9,40.6 C92.9,39.7 91.9,39.1 90.1,39.1 C88.4,38.9 86.8,39.4 86.8,41.1"
              id="Path" fill="#343A40"></path>
            <path
              d="M99.7,32.3 C99.7,31.3 100.2,30.9 101,30.9 C101.8,30.9 102.3,31.3 102.3,32.3 C102.3,33.1 101.8,33.6 101,33.6 C100.2,33.7 99.7,33.2 99.7,32.3 M102.1,52.4 C102.1,53 101.6,53.5 101,53.5 C100.4,53.5 99.9,53 99.9,52.4 L99.9,38.1 C99.9,37.5 100.4,37 101,37 C101.6,37 102.1,37.5 102.1,38.1 L102.1,52.4 L102.1,52.4 Z"
              id="Shape" fill="#343A40"></path>
            <path
              d="M109,39.6 C109.7,38 111.5,36.8 113.9,36.8 C114.5,36.8 115,37.3 115,37.9 C115,38.5 114.5,39 113.9,39 C110.7,39 109,41.3 109,44.5 L109,52.4 C109,53 108.5,53.5 107.9,53.5 C107.3,53.5 106.8,53 106.8,52.4 L106.8,38.1 C106.8,37.5 107.3,37 107.9,37 C108.5,37 109,37.5 109,38.1 L109,39.6 L109,39.6 Z"
              id="Path" fill="#343A40"></path>
            <path
              d="M74.9,0 C40.1,0 11.8,28.3 11.8,63 L11.9,70.2 C11.1,69.1 9.4,67.9 7.1,67.9 C2.6,67.9 0,70.6 0,76.4 C0,82.2 2.7,84.9 7.2,84.9 C11.2,84.9 14.1,82.6 14.1,78 L14.1,60.8 C15.3,28.3 42.1,2.3 74.9,2.3 C108.5,2.3 135.8,29.6 135.8,63.2 C135.8,70.2 134.6,77.1 132.2,83.7 C132,84.3 132.3,84.9 132.9,85.2 C133,85.2 133.2,85.3 133.3,85.3 C133.8,85.3 134.2,85 134.4,84.5 C136.8,77.7 138.1,70.5 138.1,63.2 C138,28.3 109.7,0 74.9,0 M11.8,77.9 C11.8,81.2 9.9,82.8 7.1,82.8 C3.9,82.8 2.1,80.8 2.1,76.3 C2.1,72 3.9,69.9 7.1,69.9 C10.4,69.9 11.8,73 11.8,73 L11.8,77.9 L11.8,77.9 Z"
              id="Shape" fill="#343A40"></path>
            <path
              d="M30.3,68.2 C29.7,68.2 29.1,68.7 29.1,69.3 L29.1,78.5 C29.1,80.8 28.4,82.8 24.8,82.8 C21.2,82.8 20.5,80.8 20.5,78.5 L20.5,69.3 C20.5,68.7 20,68.2 19.4,68.2 C18.8,68.2 18.3,68.7 18.3,69.3 L18.3,78.5 C18.3,81.8 19.4,84.9 24.9,84.9 C30.4,84.9 31.5,81.8 31.5,78.5 L31.5,69.3 C31.4,68.6 30.9,68.2 30.3,68.2"
              id="Path" fill="#343A40"></path>
            <path
              d="M52.2,60.4 C51.5,60.4 51,61 51,61.6 L51,77.3 C51,80.4 49.8,82.7 46.7,82.7 C45.7,82.7 44.9,82.6 44.1,82.1 C43.8,82 43.7,81.9 43.4,81.9 C42.8,81.9 42.2,82.4 42.2,83.1 C42.2,83.6 42.4,83.9 42.9,84.2 C43.8,84.7 45.4,85 46.7,85 C51.6,85 53.4,81.7 53.4,77.4 L53.4,61.7 C53.4,61 52.9,60.4 52.2,60.4"
              id="Path" fill="#343A40"></path>
            <path
              d="M63.7,67.9 C60.7,67.9 58.8,69.1 57.8,70.7 C57.7,70.9 57.6,71.1 57.6,71.3 C57.6,71.9 58.1,72.3 58.6,72.3 C58.9,72.3 59.2,72.2 59.5,71.8 C60.6,70.6 61.7,70 63.6,70 C66.2,70 67.8,71.5 67.8,73.9 L67.8,74.9 L62.8,74.9 C59,74.9 56.6,76.8 56.6,79.9 C56.6,83.1 59,84.9 62.7,84.9 C64.8,84.9 66.9,84 67.7,82.4 L67.7,83.7 C67.7,84.3 68.2,84.8 68.8,84.8 C69.4,84.8 69.9,84.3 69.9,83.7 L69.9,74 C70.1,70.3 67.8,67.9 63.7,67.9 M68,78.3 C68,81.3 65.5,82.8 63.1,82.8 C60.4,82.8 59.1,81.6 59.1,79.8 C59.1,77.8 60.7,76.8 63,76.8 L68,76.8 L68,78.3 L68,78.3 Z"
              id="Shape" fill="#343A40"></path>
            <path
              d="M81.5,68 C79.1,68 77.3,69.2 76.6,70.8 L76.6,69.2 C76.6,68.6 76.1,68.1 75.5,68.1 C74.9,68.1 74.4,68.6 74.4,69.2 L74.4,83.5 C74.4,84.1 74.9,84.6 75.5,84.6 C76.1,84.6 76.6,84.1 76.6,83.5 L76.6,75.7 C76.6,72.5 78.3,70.2 81.5,70.2 C82.1,70.2 82.6,69.7 82.6,69.1 C82.6,68.5 82.1,68 81.5,68"
              id="Path" fill="#343A40"></path>
            <path
              d="M97,59.3 C96.4,59.3 95.9,59.8 95.9,60.4 L95.9,70.2 C95.1,69.1 93.4,67.9 91.1,67.9 C86.6,67.9 84,70.6 84,76.4 C84,82.2 86.7,84.9 91.2,84.9 C95.2,84.9 98.1,82.6 98.1,78 L98.1,60.5 C98.1,59.8 97.6,59.3 97,59.3 M95.8,77.9 C95.8,81.2 93.9,82.8 91.1,82.8 C87.9,82.8 86.1,80.8 86.1,76.3 C86.1,72 87.9,69.9 91.1,69.9 C94.4,69.9 95.8,73 95.8,73 L95.8,77.9 L95.8,77.9 Z"
              id="Shape" fill="#343A40"></path>
            <path
              d="M103.8,62.1 C103,62.1 102.5,62.5 102.5,63.5 C102.5,64.3 103,64.8 103.8,64.8 C104.6,64.8 105.1,64.3 105.1,63.5 C105.1,62.5 104.6,62.1 103.8,62.1"
              id="Path" fill="#343A40"></path>
            <path
              d="M103.8,68.2 C103.2,68.2 102.7,68.7 102.7,69.3 L102.7,83.6 C102.7,84.2 103.2,84.7 103.8,84.7 C104.4,84.7 104.9,84.2 104.9,83.6 L104.9,69.3 C104.9,68.6 104.4,68.2 103.8,68.2"
              id="Path" fill="#343A40"></path>
            <path
              d="M116.4,67.9 C114.1,67.9 112.4,69 111.7,70.4 L111.7,69.3 C111.7,68.7 111.2,68.2 110.6,68.2 C110,68.2 109.5,68.7 109.5,69.3 L109.5,83.6 C109.5,84.2 110,84.7 110.6,84.7 C111.2,84.7 111.7,84.2 111.7,83.6 L111.7,74.9 C111.7,72.1 112.9,70 116.4,70 C119.8,70 120.6,71.9 120.6,74.7 L120.6,83.7 C120.6,84.3 121.1,84.8 121.7,84.8 C122.3,84.8 122.8,84.3 122.8,83.7 L122.8,74.3 C122.9,71.2 121.9,67.9 116.4,67.9"
              id="Path" fill="#343A40"></path>
          </g>
        </g>
      </svg>
        <h2 style="text-align: center;margin-top: 30px; "> Inscription </h2>
        <div class="form-group">
            <input type="text" class="w3-input" formControlName="name" 
            [ngClass]="{ 'is-invalid': submitted && f.name.errors }"
            placeholder="Entrez votre nom" required style="display: table;margin: auto;margin-top: 30px; border-radius: 10px;width: 300px;">
            <small *ngIf="submitted && f.name.errors" class="invalid-feedback " style="display: table;margin: auto; width: 300px; color: red;">
              <div *ngIf="f.name.errors.required">Nom obligatoire</div>
              <div *ngIf="f.name.errors.minlength">
                Le nom doit comporter au moins 3 caractères
              </div>
              <div *ngIf="f.name.errors.maxlength">
                Le nom ne doit pas dépasser 20 caractères
              </div>
            </small>
          </div>
        <div class="form-group">
            <input type="email" class="w3-input" formControlName="email"
            [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
             placeholder="Entrez votre email" required style="display: table;margin: auto;margin-top: 20px;    border-radius: 10px;width: 300px;">
             <small *ngIf="submitted && f.email.errors" class="invalid-feedback" style="display: table;margin: auto; width: 300px; color: red;">
              <div *ngIf="f.email.errors.required">Email obligatoire</div>
              <div *ngIf="f.email.errors.email">Email non valide</div>
            </small>
            </div>
        <div class="form-group">
            <input type="password" class="w3-input" formControlName="password"
            [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
             placeholder="Mot de passe" required style="display: table;margin: auto;margin-top: 20px;    border-radius: 10px;width: 300px;">
             <small *ngIf="submitted && f.password.errors" class="invalid-feedback" style="display: table;margin: auto; width: 300px; color: red;">
              <div *ngIf="f.password.errors.required">Mot de passe obligatoire</div>
              <div *ngIf="f.password.errors.minlength">
                Le mot de passe doit comporter au moins 6 caractères
              </div>
              <div *ngIf="f.password.errors.maxlength">
                Le mot de passe ne doit pas dépasser 20 caractères
              </div>
            </small>
            </div>
        <button type="submit" class="btn btn-block btn-primary"style="background-color: #ffd136;border-color: #ffd136;display: block;margin-left: auto;margin-right: auto;margin-top: 30px;    border-radius: 10px;">Inscription</button>
    </form>
</div>
