import {Injectable} from '@angular/core';
import ScrollAnimation from '../../assets/js/views/scrollAnimation';
import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import {Draggable} from 'gsap/Draggable';
import {MotionPathPlugin} from 'gsap/MotionPathPlugin';
import * as $ from 'jquery';

@Injectable({
  providedIn: 'root'
})
export class GsapService {

  constructor() {
  }

  layerAnimations() {
    setTimeout(() => {

      const animation = new ScrollAnimation({
        scrollContainer: '#global',
      });
      gsap.utils.toArray('[data-animation-container]').forEach((section, i) => {
        const trigger = section;
        const animSlideIn = gsap.to($(section).find('[data-animation="slideIn"]'), {
          y: 0,
          z: 0.1,
          autoAlpha: 1,
          ease: 'power3',
          stagger: 0.1,
          duration: 2,
        });
        animation.addApparition(animSlideIn, trigger);


        if ($(section).find('[data-animation="slideFromBottom"]').length) {
          const animSlideFromBottom = gsap.to(
            $(section).find('[data-animation="slideFromBottom"]'),
            {
              y: '0%',
              z: 0.1,
              ease: 'power3.out',
              duration: 2,
            }
          );
          animation.addApparition(animSlideFromBottom, trigger);
        }
        if ($(section).find('[data-animation="slideFromTop"]').length) {
          const animSlideFromTop = gsap.to(
            $(section).find('[data-animation="slideFromTop"]'),
            {
              scale: 1,
              y: '0%',
              z: 0.1,
              ease: 'power3',
              duration: 2,
            }
          );
          animation.addApparition(animSlideFromTop, trigger);
        }
        if ($(section).find('[data-animation="slideFromLeft"]').length) {
          const animSlideFromLeft = gsap.to(
            $(section).find('[data-animation="slideFromLeft"]'),
            {
              scale: 1,
              x: '0%',
              z: 0.1,
              ease: 'power3',
              duration: 2,
            }
          );
          animation.addApparition(animSlideFromLeft, trigger);
        }
        if ($(section).find('[data-animation="slideFromRight"]').length) {
          const animSlideFromRight = gsap.to(
            $(section).find('[data-animation="slideFromRight"]'),
            {
              x: '0%',
              z: 0.1,
              ease: 'power3',
              duration: 2,
            }
          );
          animation.addApparition(animSlideFromRight, trigger);
        }
      });

      $('.button-advices').on('click', function (e) {
        $(this).find('.step--01').removeClass('active');
        $(this).find('.step--02').addClass('active');
      });

      if ($('[data-overlay-sticky').length) {
        animation.addTrigger({
          trigger: '[data-sticky-button-advice] .button-advices',
          pin: true,
          start: 'bottom top+=100%',
          end: 'top bottom',
          markers: false,
          invalidateOnRefresh: true,
          endTrigger: '[data-remove-sticky]',
        });
        animation.addTrigger({
          trigger: '[data-overlay-sticky]',
          start:
            'top bottom-=' +
            ($('.button-advices .step--01').outerHeight() +
              $('.button-advices .step--01').position().top),
          end: 'bottom bottom-=' + $('.button-advices .step--01').position().top,
          invalidateOnRefresh: true,
          // markers:true,
          onToggle: (self) => {
            if (self.isActive) {
              $('[data-sticky-button-advice] .button-advices').addClass(
                'overlaps'
              );
            } else {
              $('[data-sticky-button-advice] .button-advices').removeClass(
                'overlaps'
              );
            }
          },
        });
      }
      if ($('.social-networks').length) {
        animation.addTrigger({
          trigger: '.social-networks',
          pin: true,
          start: () => dynamicPosition(),
          end: 'top bottom',
          endTrigger: '.blog-inner-navigation',
          invalidateOnRefresh: true,
          onToggle(self) {
            if (self.isActive) {
              $('.social-networks').addClass('is-sticky');
            } else {
              $('.social-networks').removeClass('is-sticky');
            }
          },
        });

        function dynamicPosition() {
          if (window.outerWidth >= 992) {
            return (
              'top bottom-=' +
              ((window.outerHeight * 7.887) / 100 -
                $('.social-networks').outerHeight() / 4)
            );
          } else {
            return 'top center-=' + $('.social-networks').outerHeight() / 2;
          }
        }
      }

      gsap.registerPlugin(ScrollTrigger, Draggable, MotionPathPlugin);
    }, 100);
  }
}
