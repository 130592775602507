import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
export default class ScrollAnimation {
  constructor(options = {}) {
    this.scrollContainer = document.querySelectorAll(options.scrollContainer);
    this.trigger = null;
    this._init();
  }
  _init() {
    gsap.registerPlugin(ScrollTrigger);
    /*this.scrollContainer[0].addEventListener("scroll", () =>
      ScrollTrigger.update()
    );*/
  }
  addApparition(anim, trigger, opts = {}) {
    this.trigger = trigger;
    let defaultOption = {
      trigger: this.trigger,
      animation: anim,
      markers: false,
      start: "top bottom",
      end: "bottom center",
      invalidateOnrefresh: true,
      scroller: this.scrollContainer[0],
    };
    //let options = Object.assign(options, opts);

    ScrollTrigger.create(defaultOption);
  }
}
