import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ToastrService } from 'ngx-toastr';
import { ReplacePipe } from 'src/app/pipe/replace.pipe';
import { ProductsService } from 'src/app/_shared/products.service';
import { ProjectService } from 'src/app/_shared/project.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddComponent implements OnInit {
  title = 'upload-image';
  filesPath = [] ;
  uploadUrl=`${environment.uploadUrl}`
  
  fixedProjectStructure = {
    sections: [
      {
        hide: false,
        section_name: 'banner',
        title: '',
        background: '',
        contents: [
          {
            language: 'fr',
            content: [
              {
                elements: [
                  {
                    title: ''
                  }
                ],
                content_name: 'title',
                content_type: 'title'
              },
              {
                elements: [
                  {
                    description: ''
                  }
                ],
                content_name: 'description',
                content_type: 'description'
              },
              {
                elements: [
                  {
                    btn_text: 'Découvrez-le'
                  }
                ],
                content_name: 'buttons',
                content_type: 'buttons'
              }
            ]
          }
        ]
      },
      {
        hide: false,
        section_name: 'les_attentes_du_clients',
        title: 'Les attentes du clients',
        background: '',
        contents: [
          {
            language: 'fr',
            content: [
              {
                elements: [
                  {
                    description: ''
                  }
                ],
                content_name: 'paragraphes',
                content_type: 'paragraphes'
              },
              {
                elements: [
                  {
                    btn_text: 'BESOIN D\'UN CONSEIL ?'
                  }
                ],
                content_name: 'buttons',
                content_type: 'buttons'
              },
              {
                elements: [
                  {
                    background_img: ''
                  }
                ],
                content_name: 'background_left_section',
                content_type: 'image'
              }
            ]
          }
        ]
      },
      {
        hide: false,
        section_name: 'notre_approche',
        title: 'Notre approche',
        background: '',
        contents: [
          {
            language: 'fr',
            content: [
              {
                elements: [
                  {
                    description: ''
                  }
                ],
                content_name: 'paragraphes',
                content_type: 'paragraphes'
              },
              {
                elements: [
                  {
                    btn_text: 'Nos projet',
                    btn_redirection: '/projets'
                  },
                  {
                    btn_text: 'Nos Produits',
                    btn_redirection: '/produits'
                  }
                ],
                content_name: 'buttons',
                content_type: 'buttons'
              },
              {
                elements: [
                  {
                    background_img: ''
                  }
                ],
                content_name: 'background_right_section',
                content_type: 'image'
              }
            ]
          }
        ]
      },
      {
        hide: false,
        section_name: 'le_resultat_final',
        title: 'Le résultat final',
        background: '',
        contents: [
          {
            language: 'fr',
            content: [
              {
                elements: [
                  {
                    description: ''
                  }
                ],
                content_name: 'paragraphes',
                content_type: 'paragraphes'
              },
              {
                elements: [
                  {
                    btn_text: 'Être contacté'
                  }
                ],
                content_name: 'buttons',
                content_type: 'buttons'
              },
              {
                elements: [],
                content_name: 'slider_project',
                content_type: 'slider'
              }
            ]
          }
        ]
      },
      {
        hide: false,
        section_name: 'leur_temoignage',
        title: 'Leur témoignage',
        background: '',
        contents: [
          {
            language: 'fr',
            content: [
              {
                elements: [
                  {
                    description: ''
                  }
                ],
                content_name: 'paragraphes',
                content_type: 'paragraphes'
              },
              {
                elements: [
                  {
                    background_img: ''
                  }
                ],
                content_name: 'background_left_section',
                content_type: 'image'
              }
            ]
          }
        ]
      },
      {
        hide: false,
        section_name: 'mobilier_lies_au_projet',
        title: 'Mobiliers liés au projet',
        background: '',
        contents: [
          {
            language: 'fr',
            content: [
              {
                elements: [
                  {
                    description: ''
                  }
                ],
                content_name: 'paragraphes',
                content_type: 'paragraphes'
              },
              {
                elements: [],
                content_name: 'slider',
                content_type: 'slider'
              }
            ]
          }
        ]
      }
    ],
    page_url: '',
    page_name: '',
    description: '',
    hide_page: false,
    deleted_page: false,
    page_type: 'project',
    background: ''
  };
  projectStructure = {
    sections: [
      {
        hide: false,
        section_name: 'banner',
        title: '',
        background: '',
        contents: [
          {
            language: 'fr',
            content: [
              {
                elements: [
                  {
                    title: ''
                  }
                ],
                content_name: 'title',
                content_type: 'title'
              },
              {
                elements: [
                  {
                    description: ''
                  }
                ],
                content_name: 'description',
                content_type: 'description'
              },
              {
                elements: [
                  {
                    btn_text: 'Découvrez-le'
                  }
                ],
                content_name: 'buttons',
                content_type: 'buttons'
              }
            ]
          }
        ]
      },
      {
        hide: false,
        section_name: 'les_attentes_du_clients',
        title: 'Les attentes du clients',
        background: '',
        contents: [
          {
            language: 'fr',
            content: [
              {
                elements: [
                  {
                    description: ''
                  }
                ],
                content_name: 'paragraphes',
                content_type: 'paragraphes'
              },
              {
                elements: [
                  {
                    btn_text: 'BESOIN D\'UN CONSEIL ?'
                  }
                ],
                content_name: 'buttons',
                content_type: 'buttons'
              },
              {
                elements: [
                  {
                    background_img: ''
                  }
                ],
                content_name: 'background_left_section',
                content_type: 'image'
              }
            ]
          }
        ]
      },
      {
        hide: false,
        section_name: 'notre_approche',
        title: 'Notre approche',
        background: '',
        contents: [
          {
            language: 'fr',
            content: [
              {
                elements: [
                  {
                    description: ''
                  }
                ],
                content_name: 'paragraphes',
                content_type: 'paragraphes'
              },
              {
                elements: [
                  {
                    btn_text: 'Nos projet',
                    btn_redirection: '/projets'
                  },
                  {
                    btn_text: 'Nos Produits',
                    btn_redirection: '/produits'
                  }
                ],
                content_name: 'buttons',
                content_type: 'buttons'
              },
              {
                elements: [
                  {
                    background_img: ''
                  }
                ],
                content_name: 'background_right_section',
                content_type: 'image'
              }
            ]
          }
        ]
      },
      {
        hide: false,
        section_name: 'le_resultat_final',
        title: 'Le résultat final',
        background: '',
        contents: [
          {
            language: 'fr',
            content: [
              {
                elements: [
                  {
                    description: ''
                  }
                ],
                content_name: 'paragraphes',
                content_type: 'paragraphes'
              },
              {
                elements: [
                  {
                    btn_text: 'Être contacté'
                  }
                ],
                content_name: 'buttons',
                content_type: 'buttons'
              },
              {
                elements: [],
                content_name: 'slider_project',
                content_type: 'slider'
              }
            ]
          }
        ]
      },
      {
        hide: false,
        section_name: 'leur_temoignage',
        title: 'Leur témoignage',
        background: '',
        contents: [
          {
            language: 'fr',
            content: [
              {
                elements: [
                  {
                    description: ''
                  }
                ],
                content_name: 'paragraphes',
                content_type: 'paragraphes'
              },
              {
                elements: [
                  {
                    background_img: ''
                  }
                ],
                content_name: 'background_left_section',
                content_type: 'image'
              }
            ]
          }
        ]
      },
      {
        hide: false,
        section_name: 'mobilier_lies_au_projet',
        title: 'Mobiliers liés au projet',
        background: '',
        contents: [
          {
            language: 'fr',
            content: [
              {
                elements: [
                  {
                    description: ''
                  }
                ],
                content_name: 'paragraphes',
                content_type: 'paragraphes'
              },
              {
                elements: [],
                content_name: 'slider',
                content_type: 'slider'
              }
            ]
          }
        ]
      }
    ],
    page_url: '',
    page_name: '',
    description: '',
    hide_page: false,
    deleted_page: false,
    page_type: 'project',
    background: ''
  };
  products: any;
  fixedProduct: any;
  searchproduct: string;
  id: any;

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ],
    toolbarHiddenButtons: [
      [
        // 'undo',
        // 'redo',
        // 'bold',
        // 'italic',
        // 'underline',
        // 'strikeThrough',
        // 'subscript',
        // 'superscript',
        // 'justifyLeft',
        // 'justifyCenter',
        // 'justifyRight',
        // 'justifyFull',
        // 'indent',
        // 'outdent',
        // 'insertUnorderedList',
        // 'insertOrderedList',
        // 'heading',
        // 'fontName'
      ],
      [
        //'fontSize',
        //'textColor',
        'backgroundColor',
        'customClasses',
        'link',
        'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        //'toggleEditorMode'
      ]
    ]
  };
  NetUrl: string;

  constructor(private toastr: ToastrService, private productService: ProductsService, private replaceString: ReplacePipe, private actRoute: ActivatedRoute, private projectService: ProjectService, private router: Router) {
    this.id = this.actRoute.snapshot.paramMap.get("id");
  }

  ngOnInit(): void {
    this.productService.get_all_product().subscribe(data => {
      
      this.fixedProduct = data;
    })
  }

  changeBackgroundSection(input) {
    if(input.files[0].size < 10*1024*1024)
    {
    if (input.files && input.files[0]) {
        this.filesPath.push(input.files[0])
        this.projectStructure.background = input.files[0].name;;
      };
  }else
  this.toastr.error('Image très large! merci de ne pas dépasser 10 Mo');
  }

  changeBackgroundImg(input, arrayElementIndex) {
    if(input.files[0].size < 10*1024*1024)
    {
    if (input.files && input.files[0]) {
      this.filesPath.push(input.files[0])
        // @ts-ignore
        this.projectStructure.sections[arrayElementIndex[0]].contents[arrayElementIndex[1]].content[arrayElementIndex[2]].elements[arrayElementIndex[3]].background_img = input.files[0].name;
      };
  }else
  this.toastr.error('Image très large! merci de ne pas dépasser 10 Mo');
  }

  addGalleryImg() {
    // @ts-ignore
    this.projectStructure.sections[3].contents[0].content[2].elements.push({img: ''});
  }
  deleteFromGallery(index: number){
    this.projectStructure?.sections[3]?.contents[0]?.content[2]?.elements.splice(index, 1);
  }

  addImg(input, arrayElementIndex) {
    if(input.files[0].size < 10*1024*1024)
    {
    if (input.files && input.files[0]) {
        this.filesPath.push(input.files[0])
        this.projectStructure.sections[arrayElementIndex[0]].contents[arrayElementIndex[1]].content[arrayElementIndex[2]].elements[arrayElementIndex[3]]['img'] = input.files[0].name;
    }
  }else
  this.toastr.error('Image très large! merci de ne pas dépasser 10 Mo');
  }

  addProduct(productName: string) {
    this.searchproduct = ''
    // @ts-ignore
    this.projectStructure.sections[5].contents[0].content[1].elements.push({product: productName});

  }


  searchProduct() {
    this.products = this.fixedProduct.filter(x => {
      let name = x.s_modele_market.toLowerCase().toString();
      return name.includes(this.searchproduct.toLowerCase());
    });
  }
  transformChar() {
    let titleToUdapte = this.projectStructure.page_name
    titleToUdapte = titleToUdapte.replace(/\W+(?!$)/g, '-').toLowerCase();
    titleToUdapte = titleToUdapte.replace(/\W$/, '').toLowerCase();

    this.NetUrl=titleToUdapte;
    this.projectStructure.page_url = '/projet/' + titleToUdapte;

  }
  generateUrlPoject() {
    // this.projectStructure.page_url = '/projet/' + this.replaceString.transform(this.projectStructure.page_name.toLowerCase(), ' ', '_');
    this.transformChar();
    this.projectStructure.page_url = (this.projectStructure.page_url[this.projectStructure.page_url.length - 1] == '_') ? this.projectStructure.page_url.slice(0, -1) : this.projectStructure.page_url;
  }

  submit() {
    let validProject = true;
    if (this.projectStructure.background == '') {
      validProject = false;
    }

    if (this.projectStructure.page_url.length < 7) {
      validProject = false;
    }
    if (this.projectStructure.page_name == '') {
      validProject = false;
    }
    if (validProject) {
       //"jpg"|"JPG"|"jpeg"|"JPEG"|"png"|"PNG"|"gif"|"GIF"
     /*  var types = ["image/png", "image/gif", "image/jpeg", "application/pdf"]
       
       this.filesPath.forEach(element => {
         
           const formData: FormData = new FormData();
           formData.append('files', element);
       
           this.projectService.uploadImages(formData).subscribe(res =>{
            
           })
       
       });*/
       
       
      //console.log('prj',this.projectStructure)
      if (JSON.stringify(this.projectStructure).length > 1024*1024*50)
      this.toastr.error('La taille totale des images ne doit pas dépasser 50 Mo');
      else{
        var types = ["image/png", "image/gif", "image/jpeg", "application/pdf"]
      for (let index = 0; index < this.filesPath.length; index++) {
        const formData: FormData = new FormData();
          formData.append('files', this.filesPath[index]);
      
          this.projectService.uploadImages(formData).subscribe(res =>{
            console.log("done")
          })
          if (index == Number(this.filesPath.length-1)){
            let urlToSavedInBd = this.projectStructure.page_url.split("/")[2];      
            if(urlToSavedInBd[(urlToSavedInBd.length)-1] == "-")
            {
              
              urlToSavedInBd =urlToSavedInBd.slice(0,(urlToSavedInBd.length)-1)
            }
            this.projectStructure.page_url = urlToSavedInBd
            this.projectService.add_project(this.projectStructure).subscribe(data => {
              this.toastr.success('Le projet a été ajouté avec succès!');
              this.projectStructure = this.fixedProjectStructure;
              setTimeout(() => {
                this.router.navigate(['user-profile/' + this.id + '/projets']).then(() => {
                  //window.location.reload();
                });
              }, 1000);
            })
          }
        }
      
    }}
  }

  generateBannerTitle() {
    this.projectStructure.sections[0].title = this.projectStructure.page_name;
    this.projectStructure.sections[0].contents[0].content[0].elements[0]['title'] = this.projectStructure.page_name;
  }

  navigateListProject() {
    this.router.navigate(['user-profile/' + this.id + '/projets']);
  }

  removeProduct(indexprd: number) {
    this.projectStructure.sections[5].contents[0].content[1]['elements'].splice(indexprd, 1);
  }
}
