import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PreloaderComponent } from 'src/app/backoffice/_common/preloader/preloader.component';
import { HomePageService } from 'src/app/_shared/home-page.service';
import { ProjectService } from 'src/app/_shared/project.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-list',
  template: `<app-preloader></app-preloader>`,
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {
  @ViewChild(PreloaderComponent) loader: PreloaderComponent;
  projectslist: any;
  showDeletedProject = false;
  projectToDelete: any;
  homeProj: any;
  uploadUrl=`${environment.uploadUrl}`

  constructor(private projectService: ProjectService, private toastr: ToastrService, private homeService: HomePageService) {
  }

  get_projects_list() {
    this.projectService.get_project_list().subscribe(async data => {
      this.projectslist = await data;
       this.loader.remove();
    });
  }
  async getHomeAct() {
    this.homeService.get_page_content_proj('home').subscribe(async data => {
      this.homeProj = await data
    })
      this.get_projects_list()
   
  }

  ngOnInit(): void {
    //this.get_projects_list();
    this.getHomeAct();
  }

  updateDisplayProject(project) {
    for (let i = 0; i < this.homeProj?.sections[3]?.contents[1]?.content[0]?.elements[0]?.projects?.length; i++) {
      if (this.homeProj?.sections[3]?.contents[1]?.content[0].elements[0].projects[i]._id == project._id) {
        this.homeProj.sections[3].contents[1].content[0].elements[0].projects[i].hide_page = !this.homeProj?.sections[3]?.contents[1]?.content[0].elements[0].projects[i].hide_page
      }
    }
    this.projectService.updateDisplayProject(project).subscribe(data => {
    });
    this.homeService.update_section_content('home', this.homeProj.sections[3]).subscribe((data) => {
      this.toastr.success('Le projet a été modifier avec succès!');
      this.getHomeAct();
    })
  }


  deleteDisplayProject() {
    this.projectToDelete.deleted_page = !this.projectToDelete.deleted_page;
    for (let i = 0; i < this.homeProj?.sections[3]?.contents[1]?.content[0]?.elements[0]?.projects?.length; i++) {
      if (this.homeProj?.sections[3]?.contents[1]?.content[0].elements[0].projects[i]._id == this.projectToDelete._id) {
        this.homeProj.sections[3].contents[1].content[0].elements[0].projects[i].deleted_page = !this.homeProj?.sections[3]?.contents[1]?.content[0].elements[0].projects[i].deleted_page
      }
    }
    this.projectService.delete_project(this.projectToDelete._id).subscribe(data => {
    });
    this.homeService.update_section_content('home', this.homeProj.sections[3]).subscribe((data) => {
      this.toastr.success('Le projet a été supprimer avec succès!');
      this.getHomeAct();
      })
  }

  displayingDeletedProjects() {
    this.showDeletedProject = !this.showDeletedProject;
  }
}
