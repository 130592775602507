import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageStructure } from '../model/page-structure';

// const baseUrl = `http://149.202.54.170:3020/pages/home`;
 const baseUrl = `/pagesCMS/`;
 const baseUrlPage = `/pagesCMS/`;
 //const baseUrlPage = `/pagesCMS/`;

// const baseUrl = `/pagesCMS/`;

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  constructor(private http: HttpClient) {
  }
  uploadImages(formData:FormData) {
    var headers = new HttpHeaders({
      "Accept": "multipart/form-data",
      "Access-Control-Allow-Origin":"*"
    });
      const req = new HttpRequest('POST', `/api/images`, formData, {
        reportProgress: true,
        responseType: 'json',
        headers: headers
      });
      return this.http.request(req);
    }
  get_all_contents() {
    return this.http.get(`${baseUrl}/all`);
  }

  get_project_list() {
    return this.http.get(`${baseUrlPage}projects`);
  }

  get_project_visible_not_deleted_list() {
    return this.http.get(`${baseUrlPage}/projects/visiblenotdeleted`);
  }

  get_actualités_list() {
    return this.http.get(`${baseUrlPage}/actualites`);
  }

  get_actualités_list_with_contents() {
    return this.http.get(`${baseUrlPage}/actualitesWithContents`);
  }
  

  get_actualités_visible_not_deleted_list() {
    return this.http.get(`${baseUrlPage}/actualites/visiblenotdeleted`);
  }

  get_project_list_client() {
    return this.http.get(`${baseUrlPage}projects/client`);
  }

  get_project_by_id(projectId) {
    return this.http.get(`${baseUrlPage}/project/${projectId}`);
  }
  get_project_by_name(name) {
    return this.http.get(`${baseUrlPage}/project/name/${encodeURIComponent(name)}`);
  }
  get_act_by_name(name) {
    return this.http.get(`${baseUrlPage}/actualite/name/${encodeURIComponent(name)}`);
  }
  get_all_marque() {
    return this.http.get(`${baseUrlPage}marques/all`);
  }

  update_project(project) {
    return this.http.put(`${baseUrlPage}project/update`, project, {responseType: 'json'});
  }

  updateDisplayProject(project) {
    console.log('in the jungle',project)
    return this.http.put(`${baseUrlPage}/project/${project._id}/change-display-status`, {hide_page: !project.hide_page});
  }

  update_porject_image_and_url(project) {
    return this.http.put(`${baseUrlPage}/project/${project._id}/change-img-and-url`, project);
  }

  update_actualite_image_and_url(actualite) {
    return this.http.put(`${baseUrlPage}/actualite/${actualite._id}/change-img-and-url`, actualite);
  }
  

  add_project(project) {
    return this.http.post(`${baseUrlPage}projects/add`, project, {responseType: 'json'}
    );
  }

  add_actualités(project) {
    return this.http.post(`${baseUrlPage}actualites/add`, project, {responseType: 'json'}
    );
  }

  get_page_content(pageName: string) {
    return this.http.get<PageStructure>(`${baseUrlPage}` + pageName);
  }

  delete_project(projectId) {
    return this.http.delete(`${baseUrlPage}project/${projectId}/delete`);
  }

  // add 2 fnct --> check url & title

  check_actualite_existance(title){
    return this.http.get(`${baseUrlPage}/checktitle-verification/`+title);
  }
  check_Url_actualite_existance(title){
    return this.http.get(`${baseUrlPage}/checkUrl-fromtitle-verification/`+title);
  }


  // Fn of checking URL and title before update
  check_Urlactualite_existanceForupadte(title, id){
    return this.http.get(`${baseUrlPage}/checkurl-fromTitle-verification-update/`+title+`/`+id);
  }

  check_actualite_existanceupadte(title, id){
    console.log(title)
    return this.http.get(`${baseUrlPage}/checktitle-verification-update/`+id+`/`+title);
  }
  

  uploadImagesNews(formData:FormData) {
    var headers = new HttpHeaders({
      "Accept": "multipart/form-data",
      "Access-Control-Allow-Origin":"*"
    });
      return this.http.post(`${baseUrlPage}/upload/images`,formData,{headers:headers});
    }

  
}



























