import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SigninComponent } from './backoffice/signin/signin.component';
import { SignupComponent } from './backoffice/signup/signup.component';
import { UserProfileComponent } from './backoffice/user-profile/user-profile.component';
//import {MainDashboardComponent} from './backoffice/user-profile/main-dashboard/main-dashboard.component';
import { AddComponent as AddActualiteComponent } from './backoffice/pageBuilder/actualite/add/add.component';
import { ListComponent as ListActualiteComponent } from './backoffice/pageBuilder/actualite/list/list.component';
/*import { AddComponent as AddFamilleComponent } from './backoffice/pageBuilder/famille/add/add.component';
import { ListComponent as ListFamilleComponent } from './backoffice/pageBuilder/famille/list/list.component';
import { AddComponent as AddMarqueComponent } from './backoffice/pageBuilder/marque/add/add.component';
import { ListComponent as ListMarqueComponent } from './backoffice/pageBuilder/marque/list/list.component';*/
import { AddComponent } from './backoffice/pageBuilder/project/add/add.component';
import { ListComponent } from './backoffice/pageBuilder/project/list/list.component';
import { UpdateComponent } from './backoffice/pageBuilder/project/update/update.component';
import { HomeCMSComponent } from './backoffice/pages/cms/home-cms/home-cms.component';
import { AuthGuard } from './_shared/auth.guard';

import { UpdateComponent as UpdateActualiteComponent } from './backoffice/pageBuilder/actualite/update/update.component';
//import { UpdateComponent as UpdateMarqueComponent } from './backoffice/pageBuilder/marque/update/update.component';
import { AproposComponent } from './backoffice/pages/cms/apropos/apropos.component';
import { ContactBOComponent } from './backoffice/pages/cms/contact-bo/contact-bo.component';
import { FaqCMSComponent } from './backoffice/pages/cms/faq-cms/faq-cms.component';
let a : string;
// import { ListingCollectionsComponent } from './frontoffice/listing-collections/listing-collections.component';

export const routes: Routes = [
  // FO
  
  
  // BO
  {path: '', component: SigninComponent},
  {path: 'sign-up', component: SignupComponent},
 // { path: '**', redirectTo: '/' },
  {
    path: 'user-profile/:id',
    component: UserProfileComponent,
    canActivate: [AuthGuard],
    children: [
     // {path: '', component: MainDashboardComponent},
     { path: '',   redirectTo: 'pages/home', pathMatch: 'full' },
      
      {
        path: 'pages',
        children: [{path: 'home', component: HomeCMSComponent}, 
        {path: 'A propos', component: ContactBOComponent},
        {path: 'contact', component: AproposComponent},
        {path: 'faq', component: FaqCMSComponent}],
      },
      {path: 'projets', component: ListComponent},
      {path: 'projet/ajouter', component: AddComponent},
      {path: 'projet/:projectId/modifier', component: UpdateComponent},
      {path: 'actualités', component: ListActualiteComponent},
      {path: 'actualités/ajouter', component: AddActualiteComponent},
      {path: 'actualités/:projectId/modifier', component: UpdateActualiteComponent},
     /* {path: 'marques', component: ListMarqueComponent},
      {path: 'marque/ajouter', component: AddMarqueComponent},
      {path: 'marques/:marqueId/modifier', component: UpdateMarqueComponent},
      {path: 'familles', component: ListFamilleComponent},
      {path: 'famille/ajouter', component: AddFamilleComponent},*/
      
      
      
    ],
  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // scrollPositionRestoration: "enabled",
      useHash: false,
      anchorScrolling: 'enabled',
      // scrollOffset: [0, 64],
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
