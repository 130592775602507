<div class="cadre_scroll">
  <section class="content ecommerce-page">
    <div class="block-header">
      <div class="row">
        <div class="col-lg-7 col-md-6 col-sm-12">
          <h2>Ajouter un projet
            <small class="text-muted">Bienvenue au Backoffice de Plaisir du Jardin</small>
          </h2>
        </div>
        <div class="col-lg-5 col-md-6 col-sm-12">
          <ul class="breadcrumb float-md-right">
            <li class="breadcrumb-item"><a routerLink="../../"><i class="zmdi zmdi-home"></i>
              Backoffice</a>
            </li>
            <li class="breadcrumb-item"><a>Projets</a></li>
            <li class="breadcrumb-item active">Ajouter</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row clearfix">
        <div class="col-lg-12">
          <div class="card">
            <div class="body">
              <div class="row">
                <div class="col-lg-12 col-12 project-listing">
                  <div class="tab-content">
                    <div class="tab-pane fade show active" id="list-cover" role="tabpanel"
                         aria-labelledby="list-cover-list">
                      <h6>Langue:</h6>
                      <div class="row mb-4">
                        <ng-container>
                          <button class="btn btn-admin col-1 current"
                                  style="text-transform: uppercase">Fr
                          </button>
                        </ng-container>
                        <button class="btn btn-admin col-1"
                                style="text-transform: uppercase">nl
                        </button>
                      </div>

                      <h5>Informations générales: </h5>

                      <!-- show page projet -->
                      <div class="form-group mb-4">
                        <div class="d-flex"><label>Masquer le projet : <input type="checkbox" *ngIf="projectStructure"
                                                                              [(ngModel)]="projectStructure.hide_page"></label>
                        </div>

                      </div>

                      <!-- url page projet -->
                      <div class="form-group mb-4">
                        <div class="d-flex"><label>url : </label>
                        </div>

                        <input type="email" class="form-control" *ngIf="projectStructure" [(ngModel)]="projectStructure.page_url"
                               placeholder="Tapez le nouveau titre section" disabled>
                      </div>

                      <!-- titre page projet -->
                      <div class="form-group mb-4">
                        <div class="d-flex"><label>Titre : </label>
                        </div>

                        <input type="text" class="form-control" *ngIf="projectStructure" [(ngModel)]="projectStructure.page_name"
                               placeholder="Tapez le nouveau titre section"
                               (keyup)="generateUrlPoject();generateBannerTitle()">
                      </div>

                      <!-- description page projet -->
                      <div>
                        <hr/>
                        <div class="form-group">
                          <label>Description :</label>
                          <angular-editor [placeholder]="'Enter text here...'" [config]="config" *ngIf="projectStructure"
                                          [(ngModel)]="projectStructure.description" ></angular-editor>
                        </div>
                      </div>

                      <!-- Arrière-plan page projet -->
                      <div class="form-group">
                        <hr/>
                        <div class="row"><label class="col-2">Arrière plan : </label>
                          <picture class="col-4">
                            <img class="img-fluid img-thumbnail" [src]="uploadUrl+projectStructure?.background"
                                 style="width: 15vw;height: auto;min-height: 200px" alt="">
                          </picture>
                          <input type="file"
                                 class="form-control col-3" (change)="changeBackgroundSection($event.target)"
                                 style="height: 50px">
                        </div>

                      </div>

                      <h5>Contenu: </h5>

                      <div class="d-flex align-items-start">
                        <div class="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist"
                             aria-orientation="vertical">
                          <button class="nav-link active m-2" style="border:0" id="v-pills-banner-tab"
                                  data-bs-toggle="pill" data-bs-target="#v-pills-banner" type="button"
                                  role="tab" aria-controls="v-pills-home" aria-selected="true">Couverture
                          </button>
                          <button class="nav-link m-2" style="border:0" id="v-pills-les-attentes-du-client-tab"
                                  data-bs-toggle="pill" data-bs-target="#v-pills-les-attentes-du-client" type="button"
                                  role="tab" aria-controls="v-pills-home" aria-selected="true">Les attentes du client
                          </button>
                          <button class="nav-link m-2" style="border:0" id="v-pills-notre-approche-tab"
                                  data-bs-toggle="pill" data-bs-target="#v-pills-notre-approche" type="button"
                                  role="tab" aria-controls="v-pills-profile" aria-selected="false">Notre approche
                          </button>
                          <button class="nav-link m-2" style="border:0" id="v-pills-le-résultat-final-tab"
                                  data-bs-toggle="pill" data-bs-target="#v-pills-le-résultat-final" type="button"
                                  role="tab" aria-controls="v-pills-messages" aria-selected="false">Le résultat final
                          </button>
                          <button class="nav-link m-2" style="border:0" id="v-pills-temoignage-tab"
                                  data-bs-toggle="pill" data-bs-target="#v-pills-temoignage" type="button" role="tab"
                                  aria-controls="v-pills-messages" aria-selected="false">Témoignage
                          </button>
                          <button class="nav-link m-2" style="border:0" id="v-pills-mobilier-lies-au-projet-tab"
                                  data-bs-toggle="pill" data-bs-target="#v-pills-mobilier-lies-au-projet" type="button"
                                  role="tab" aria-controls="v-pills-settings" aria-selected="false">Mobiliers liés au projet
                          </button>
                        </div>
                        <div class="tab-content" id="v-pills-tabContent">
                          <div class="tab-pane fade show active" id="v-pills-banner" role="tabpanel"
                               aria-labelledby="v-pills-banner-tab">

                            <div class="form-group mb-4">
                              <div class="d-flex"><label>Masquer la section Couverture :
                                <input type="checkbox" [(ngModel)]="projectStructure?.sections[0].hide"></label>
                              </div>

                            </div>

                            <div class="form-group">
                              <label>Description :</label>
                              <angular-editor [placeholder]="'Enter text here...'" [config]="config"
                                              [(ngModel)]="projectStructure?.sections[0]?.contents[0]?.content[1]?.elements[0].description"></angular-editor>
                            </div>

                            <div class="form-group mb-4">
                              <div class="d-flex"><label>Bouton : </label>
                              </div>

                              <input type="text" class="form-control"
                                     [(ngModel)]="projectStructure?.sections[0]?.contents[0]?.content[2]?.elements[0].btn_text"
                                     placeholder="Tapez le nouveau titre section">
                            </div>

                          </div>
                          <div class="tab-pane fade show" id="v-pills-les-attentes-du-client" role="tabpanel"
                               aria-labelledby="v-pills-les-attentes-du-client-tab">

                            <div class="form-group mb-4">
                              <div class="d-flex"><label>Masquer la section {{projectStructure?.sections[1].title}} :
                                <input type="checkbox" [(ngModel)]="projectStructure?.sections[1].hide"></label>
                              </div>
                            </div>

                            <div class="ml-4">

                              <div class="form-group mb-4">
                                <div class="d-flex"><label>Titre:</label>
                                </div>

                                <input type="text" class="form-control" [(ngModel)]="projectStructure?.sections[1].title"
                                       placeholder="Tapez le nouveau titre section">
                              </div>

                              <div class="form-group">
                                <label>Description :</label>
                                <angular-editor [placeholder]="'Enter text here...'" [config]="config"
                                                [(ngModel)]="projectStructure?.sections[1]?.contents[0]?.content[0]?.elements[0].description"></angular-editor>
                              </div>

                              <div class="form-group mb-4">
                                <div class="d-flex"><label>Bouton : </label>
                                </div>

                                <input type="text" class="form-control"
                                       [(ngModel)]="projectStructure?.sections[1]?.contents[0]?.content[1]?.elements[0].btn_text"
                                       placeholder="Tapez le nouveau titre section">
                              </div>

                              <div class="form-group">
                                <hr/>
                                <div class="row"><label class="col-2">Arrière plan : </label>
                                  <picture class="col-4">
                                    <img class="img-fluid img-thumbnail"
                                         [src]="uploadUrl+projectStructure?.sections[1]?.contents[0]?.content[2]?.elements[0].background_img"
                                         style="width: 15vw;height: auto;min-height: 200px" alt="">
                                  </picture>
                                  <input type="file"
                                         class="form-control col-3"
                                         (change)="changeBackgroundImg($event.target,[1,0,2,0])"
                                         style="height: 50px">
                                </div>

                              </div>

                            </div>

                          </div>
                          <div class="tab-pane fade" id="v-pills-notre-approche" role="tabpanel"
                               aria-labelledby="v-pills-notre-approche-tab">

                            <div class="form-group mb-4">
                              <div class="d-flex"><label>Masquer la section {{projectStructure?.sections[2].title}} :
                                <input type="checkbox" [(ngModel)]="projectStructure?.sections[2].hide"></label>
                              </div>
                            </div>

                            <div class="ml-4">
                              <div class="form-group mb-4">
                                <div class="d-flex"><label>Titre:</label>
                                </div>

                                <input type="text" class="form-control" [(ngModel)]="projectStructure?.sections[2].title"
                                       placeholder="Tapez le nouveau titre section">
                              </div>

                              <div class="form-group">
                                <label>Description :</label>
                                <angular-editor [placeholder]="'Enter text here...'" [config]="config"
                                                [(ngModel)]="projectStructure?.sections[2]?.contents[0]?.content[0]?.elements[0].description"></angular-editor>
                              </div>

                              <div class="form-group">
                                <div class="row"><label class="col-2">Arrière plan : </label>
                                  <picture class="col-4">
                                    <img class="img-fluid img-thumbnail"
                                         [src]="uploadUrl+projectStructure?.sections[2]?.contents[0]?.content[2]?.elements[0].background_img"
                                         style="width: 15vw;height: auto;min-height: 200px" alt="">
                                  </picture>
                                  <input type="file"
                                         class="form-control col-3"
                                         (change)="changeBackgroundImg($event.target,[2,0,2,0])"
                                         style="height: 50px">
                                </div>

                              </div>

                            </div>

                          </div>
                          <div class="tab-pane fade" id="v-pills-le-résultat-final" role="tabpanel"
                               aria-labelledby="v-pills-le-résultat-final-tab">

                            <div class="form-group mb-4">
                              <div class="d-flex"><label>Masquer la section {{projectStructure?.sections[3].title}} :
                                <input type="checkbox" [(ngModel)]="projectStructure?.sections[3].hide"></label>
                              </div>
                            </div>

                            <div class="ml-4">

                              <div class="form-group mb-4">
                                <div class="d-flex"><label>Titre:</label>
                                </div>

                                <input type="text" class="form-control" [(ngModel)]="projectStructure?.sections[3].title"
                                       placeholder="Tapez le nouveau titre section">
                              </div>

                              <div class="form-group">
                                <label>Description :</label>
                                <angular-editor [placeholder]="'Enter text here...'" [config]="config"
                                                [(ngModel)]="projectStructure?.sections[3]?.contents[0]?.content[0]?.elements[0].description"></angular-editor>
                              </div>

                              <div class="form-group mb-4">
                                <div class="d-flex"><label>Bouton : </label>
                                </div>

                                <input type="text" class="form-control"
                                       [(ngModel)]="projectStructure?.sections[3]?.contents[0]?.content[1]?.elements[0].btn_text"
                                       placeholder="Tapez le nouveau titre section">
                              </div>

                              <div class="form-group">
                                <hr/>
                                <div class="row"><label class="col-2">Gallerie : </label>
                                  <div class="row mb-3"
                                    *ngFor="let gallery of projectStructure?.sections[3]?.contents[0]?.content[2]?.elements;let index=index">
                                    <div class="col-4">
                                    <picture >
                                      <img class="img-fluid img-thumbnail"
                                           [src]="uploadUrl+gallery['img']"
                                           style="width: 15vw;height: auto;min-height: 200px" alt="">
                                    </picture>
                                  </div>
                                    <div class="col-1">
                                      <i class="zmdi zmdi-delete mr-3" data-toggle="tooltip" data-placement="top"
                 title="Supprimer" style="cursor:pointer;" 
                 (click)="deleteFromGallery(index)"></i></div>
                                  
                                  <div class="mt-1">
                                    <input type="file"
                                           class="form-control col-3"
                                           (change)="addImg($event.target,[3,0,2,index])"
                                           style="height: 50px">
                                          </div>
                                           
                                  </div>
                                </div>
                                <button class="btn btn-admin col-1 current d-block"
                                        style="text-transform: uppercase" (click)="addGalleryImg()"><i
                                  class="zmdi zmdi-plus"></i>
                                </button>

                              </div>

                            </div>

                          </div>

                          <div class="tab-pane fade" id="v-pills-temoignage" role="tabpanel"
                               aria-labelledby="v-pills-temoignage-tab">

                            <div class="form-group mb-4">
                              <div class="d-flex"><label>Masquer la section {{projectStructure?.sections[4].title}} :
                                <input type="checkbox" [(ngModel)]="projectStructure?.sections[4].hide"></label>
                              </div>
                            </div>

                            <div class="ml-4">

                              <div class="form-group mb-4">
                                <div class="d-flex"><label>Titre:</label>
                                </div>

                                <input type="text" class="form-control" [(ngModel)]="projectStructure?.sections[4].title"
                                       placeholder="Tapez le nouveau titre section">
                              </div>

                              <div class="form-group">
                                <label>Description :</label>
                                <angular-editor [placeholder]="'Enter text here...'" [config]="config"
                                                [(ngModel)]="projectStructure?.sections[4]?.contents[0]?.content[0]?.elements[0].description"></angular-editor>
                              </div>

                              <div class="form-group">
                                <hr/>
                                <div class="row"><label class="col-2">Arrière plan : </label>
                                  <picture class="col-4">
                                    <img class="img-fluid img-thumbnail"
                                         [src]="uploadUrl+projectStructure?.sections[4]?.contents[0]?.content[1]?.elements[0].background_img"
                                         style="width: 15vw;height: auto;min-height: 200px" alt="">
                                  </picture>
                                  <input type="file"
                                         class="form-control col-3"
                                         (change)="changeBackgroundImg($event.target,[4,0,1,0])"
                                         style="height: 50px">
                                </div>

                              </div>

                            </div>

                          </div>

                          <div class="tab-pane fade" id="v-pills-mobilier-lies-au-projet" role="tabpanel"
                               aria-labelledby="v-pills-mobilier-lies-au-projet-tab">

                            <div class="form-group mb-4">
                              <div class="d-flex"><label>Masquer la section {{projectStructure?.sections[5].title}} :
                                <input type="checkbox" [(ngModel)]="projectStructure?.sections[5].hide"></label>
                              </div>
                            </div>

                            <div class="ml-4">

                              <div class="form-group mb-4">
                                <div class="d-flex"><label>Titre:</label>
                                </div>

                                <input type="text" class="form-control" [(ngModel)]="projectStructure?.sections[5].title"
                                       placeholder="Tapez le nouveau titre section">
                              </div>

                              <div class="form-group">
                                <label>Description :</label>
                                <angular-editor [placeholder]="'Enter text here...'" [config]="config"
                                                [(ngModel)]="projectStructure?.sections[5]?.contents[0]?.content[0]?.elements[0].description"></angular-editor>
                              </div>

                              <div class="form-group">
                                <hr/>
                                <div class="row"><label class="col-2">produits : </label>
                                  <div
                                    *ngFor="let product of projectStructure?.sections[5]?.contents[0]?.content[1]?.elements;let indexprd = index"
                                    
                                    class="d-flex my-2">
                                    <input type="text"
                                           class="form-control col-3" [(ngModel)]="product['product']"
                                           style="height: 50px" disabled>
                                    <button class="btn ml-1 mt-0 mb-0" type="button" (click)="removeProduct(indexprd)">X</button>
                                  </div>
                                </div>
                                <button class="btn btn-admin col-1 current d-block"
                                        style="text-transform: uppercase" data-toggle="modal"
                                        data-target="#addProductModal"><i class="zmdi zmdi-plus"></i>
                                </button>

                              </div>

                            </div>

                          </div>
                        </div>
                      </div>

                      <hr/>

                      <!-- Modal -->
                      <div class="modal fade" id="addProductModal" tabindex="-1" role="dialog"
                           aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered" role="document">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h5 class="modal-title" id="exampleModalLongTitle">Ajouter un mobilier lié au projet</h5>
                              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div class="modal-body">
                              <div class="form-group">
                                <label>Saisir le nom du produit: </label>
                                <input type="text"
                                       class="form-control col-8" [(ngModel)]="searchproduct" (keyup)="searchProduct()"
                                       style="height: 50px" placeholder="Saisir le nom du produit">
                              </div>

                              <div class="m-2" style="max-height: 400px;overflow-y: scroll;overflow-x: hidden">
                                <div class="row ml-2 " *ngFor="let product of products"
                                     (click)="addProduct(product.s_modele_market);products=[]"
                                     data-dismiss="modal">{{product.s_modele_market}}
                                  <hr/>
                                </div>
                              </div>
                            </div>
                            <div class="modal-footer">
                              <!--                              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>-->
                              <!--                                <button type="button" class="btn btn-primary" (click)="addProduct();products=[]">Ajouter</button>-->
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row btn-row">
                        <div class="col-6">
                          <button class="annulation-btn" (click)="navigateListProject()" type="button">Liste des projets
                          </button>
                        </div>
                        <div class="col-6">
                          <button class="validate-btn" value="submit" type="button" (click)="submit()">Valider
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
