import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ReplacePipe } from 'src/app/pipe/replace.pipe';
import { HomePageService } from 'src/app/_shared/home-page.service';
import { ProjectService } from 'src/app/_shared/project.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-contact-bo',
  templateUrl: './contact-bo.component.html',
  styleUrls: ['./contact-bo.component.scss']
})
export class ContactBOComponent implements OnInit {
  
  filesPath = [] ;
  uploadUrl=`${environment.uploadUrl}`
  constructor(
    private homeService: HomePageService,
    private toastr: ToastrService,
    public replace: ReplacePipe,
    public projectService: ProjectService
  ) {
    
  }
  loading = false;
  submitted = false;

  selectedPageContentIndex = 0;
  fixedPageContent: any;
  selectedPageContent: any;

  get_page_content() {
    this.homeService.get_page_content('A propos').subscribe(homeContent => {
      this.fixedPageContent = homeContent;
      this.selectedPageContent = homeContent;
      this.fixedPageContent.sections[2].section_name = 'qui_sommes-nous?';
      this.selectedPageContent.sections[2].section_name = 'qui_sommes-nous?';
    });
  }

  
  


  changeBackgroundSection(input) {
    if(input.files[0].size < 10*1024*1024)
    {
    if (input.files && input.files[0]) {
      this.filesPath.push(input.files[0])
        this.fixedPageContent.sections[this.selectedPageContentIndex].background = input.files[0].name;
        this.selectedPageContent.sections[this.selectedPageContentIndex].background = input.files[0].name;
      };
    
  }else
  this.toastr.error('Image très large! merci de ne pas dépasser 10 Mo');
  }



  changeBackgroundImg(input, arrayElementIndex) {
    if(input.files[0].size < 10*1024*1024)
    {
    if (input.files && input.files[0]) {
      this.filesPath.push(input.files[0])
        this.selectedPageContent.sections[this.selectedPageContentIndex].contents[arrayElementIndex[0]].content[arrayElementIndex[1]].elements[arrayElementIndex[2]].background_img = input.files[0].name;
       // console.log(this.selectedPageContent.sections[this.selectedPageContentIndex].contents[arrayElementIndex[0]].content[arrayElementIndex[1]].elements[arrayElementIndex[2]])
      };
    
  }else
  this.toastr.error('Image très large! merci de ne pas dépasser 10 Mo');
  }


  changeImg(input, arrayElementIndex) {
    if(input.files[0].size < 10*1024*1024)
    {
    if (input.files && input.files[0]) {
      this.filesPath.push(input.files[0])
        this.selectedPageContent.sections[this.selectedPageContentIndex].contents[arrayElementIndex[0]].content[arrayElementIndex[1]].elements[arrayElementIndex[2]].img = input.files[0].name;
      };
    
  }else
  this.toastr.error('Image très large! merci de ne pas dépasser 10 Mo');
  }


  ngOnInit(): void {
    this.get_page_content();
   
    
  }
  updateSection() {
    this.submitted = true;
    this.loading = true;
    var types = ["image/png", "image/gif", "image/jpeg", "application/pdf"]
    if(this.filesPath.length > 0){
      for (let index = 0; index < this.filesPath.length; index++) {
        const formData: FormData = new FormData();
          formData.append('files', this.filesPath[index]);
      
          this.projectService.uploadImages(formData).subscribe(res =>{
            //console.log("done")
          })
          if (index == Number(this.filesPath.length-1)){
            this.homeService.update_section_content('A propos', this.selectedPageContent.sections[this.selectedPageContentIndex]).subscribe((data) => {
              let str = (this.replace.transform(this.selectedPageContent.sections[this.selectedPageContentIndex].section_name, '_', ' ')).toLowerCase();
              this.toastr.success('La section ' + str + ' a été modifiée avec succès!');
              setTimeout(() => {
                window.location.reload();
              }, 1000);
              
            });
          }
          }
        }
        else if(this.filesPath.length == 0) {
          this.homeService.update_section_content('A propos', this.selectedPageContent.sections[this.selectedPageContentIndex]).subscribe((data) => {
            let str = (this.replace.transform(this.selectedPageContent.sections[this.selectedPageContentIndex].section_name, '_', ' ')).toLowerCase();
            this.toastr.success('La section ' + str + ' a été modifiée avec succès!');
            setTimeout(() => {
              window.location.reload();
            }, 1000);
            
          });
        }
      }
      

    

}
