// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
 // uploadUrl: `http://localhost:3021/public/uploads/`,
   uploadUrl: `/public/uploads/`,
  //ftpUrl: `https://ftp.pdj.clevermint-dev.be/`,
  ftpUrl: `http://ftp-pdj.plaisirdujardin.com/`,
};
/*export const environment = {
  production: true,
  hostURL: 'http://5.196.224.9:4500/',
  port: 3020,
  envName: 'test'

};*/
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
