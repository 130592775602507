import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {map} from 'rxjs/operators';
// const baseUrl = `http://149.202.54.170:3020/api/products`;
 const baseUrl = `/api/products`;
//const baseUrl = `http://localhost:3021/api/products`;

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  constructor(private http: HttpClient) {
  }

  get_all(page, limit) {
    return this.http.get(`${baseUrl}/all?page=${page}&limit=${limit}`);
  }

  get_all_product() {
    return this.http.get(`${baseUrl}/all-products`);
  }

  get_all_rayons() {
    return this.http.get(`${baseUrl}/all-rayon-names`);
  }

  get_all_famille_by_rayon(rayon: string) {
    return this.http.post(`${baseUrl}/all-famille-by-rayon`, {rayon});
  }

  get_all_ssfam_by_fam(famille: string) {
    return this.http.post(`${baseUrl}/all-ssam-by-fam`, {famille});
  }

  get_all_specific_marques(data) {
    return this.http.post(`${baseUrl}/all-specific-marque-names`, data);
  }

  getbrandsall() {
    return this.http
      .get('http://localhost/pdjdev/backend/assets/brands.json')
      .pipe(map((res) => JSON.stringify(res)));
  }

  get_all_count() {
    return this.http.get(`${baseUrl}/all-count`);
  }

  getById(id) {
    return this.http.get(`${baseUrl}/find-one/${id}`);
  }

  getBySid(s_id) {
    return this.http.get(`${baseUrl}/find-one-sid/${s_id}`);
  }

  getBySModeleMarket(s_cat1, s_modele_market,s_id) {
    return this.http.get(`${baseUrl}/find-one/${s_cat1}/${s_modele_market}/${s_id}`);
  }

  getBySModeleMarket_only(s_modele_market) {
    return this.http.get(`${baseUrl}/find_by_smodele/${s_modele_market}`);
  }

  getBySousFam(page, limit, sous_fam) {
    return this.http.post(
      `${baseUrl}/all-by-sous-fam?page=${page}&limit=${limit}`,
      sous_fam
    );
  }

  getByFam(page, limit, fam) {
    return this.http.post(
      `${baseUrl}/all-by-fam?page=${page}&limit=${limit}`,
      fam
    );
  }

  getByBrand(brand) {
    return this.http.post(`${baseUrl}/all-by-brand`, brand);
  }

  getByCollection(collection) {
    return this.http.post(`${baseUrl}/all-by-collection`, collection);
  }

  getFirstFiveByCollection(collection) {
    return this.http.post(`${baseUrl}/five-by-collection`, collection);
  }

  getFirstFiveByMarque(collection) {
    return this.http.post(`${baseUrl}/five-by-marque`, collection);
  }

  getFirstFiveByFamille(collection) {
    return this.http.post(`${baseUrl}/five-by-fam`, collection);
  }

  getFirstFiveByRayon(collection) {
    return this.http.post(`${baseUrl}/five-by-rayon`, collection);
  }

  getByHoreca(horeca) {
    return this.http.post(`${baseUrl}/all-by-horeca`, horeca);
  }

  searchByName(page, limit, name) {
    return this.http.post(
      `${baseUrl}/search-by-name?page=${page}&limit=${limit}`,
      name
    );
  }

  getFamilleGroupByRayon() {
    return this.http.get(`${baseUrl}/get-famille-by-rayon-groupby`);
  }

  getStructureTreeProducts() {
    return this.http.get(`${baseUrl}/get-structure-category-marque`);
  }

}
