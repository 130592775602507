import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ToastrService } from 'ngx-toastr';
import { ReplacePipe } from 'src/app/pipe/replace.pipe';
import { HomePageService } from 'src/app/_shared/home-page.service';
import { ProductsService } from 'src/app/_shared/products.service';
import { ProjectService } from 'src/app/_shared/project.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.scss']
})
export class UpdateComponent implements OnInit {
  title = 'upload-image';
  filesPath = [] ;
  uploadUrl=`${environment.uploadUrl}`
  tesr:any
  products: any;
  fixedProduct: any;
  searchproduct: string;
  projectId: any;
  id:any;
  selectedPageContent: any;
  fixedProjectStructure : any;
  projectStructure: any;
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ],
    toolbarHiddenButtons: [
      [
        // 'undo',
        // 'redo',
        // 'bold',
        // 'italic',
        // 'underline',
        // 'strikeThrough',
        // 'subscript',
        // 'superscript',
        // 'justifyLeft',
        // 'justifyCenter',
        // 'justifyRight',
        // 'justifyFull',
        // 'indent',
        // 'outdent',
        // 'insertUnorderedList',
        // 'insertOrderedList',
        // 'heading',
        // 'fontName'
      ],
      [
        //'fontSize',
        //'textColor',
        'backgroundColor',
        'customClasses',
        'link',
        'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        //'toggleEditorMode'
      ]
    ]
  };
  NetUrl: any;

  constructor(private toastr: ToastrService,
              private productService: ProductsService,
              private replaceString: ReplacePipe,
              private actRoute: ActivatedRoute,
              private projectService: ProjectService,
              private router: Router,
              private route: ActivatedRoute,
              private homeService: HomePageService) {
    this.projectId = this.actRoute.snapshot.paramMap.get('projectId');
    this.id = this.actRoute.snapshot.paramMap.get('id');
    
  }

  ngOnInit(): void {
    this.projectService.get_project_by_id(this.projectId).subscribe( dataa => {
      // console.log(data)
      // this.setDataProject(data);
      let merged = {...this.fixedProjectStructure, ...dataa};
      this.fixedProjectStructure = merged
      //this.fixedProjectStructure = data;
      this.projectStructure = merged;
      console.log('aaa', this.fixedProjectStructure)
      this.tesr = this.projectStructure?.sections[3].contents[0].content[1].elements[0].btn_text
    });
    this.productService.get_all_product().subscribe(data => {
      this.fixedProduct = data;
    });

    //console.log(this.fixedProjectStructure)
    // this.projectId = this.actRoute.snapshot.paramMap.get('projectId');
    // console.log(this.projectId);

    // setTimeout(() => {
    //   this.get_page_content() 
    // }, 8000);
    
  }

  changeBackgroundSection(input) {
    if(input.files[0].size < 10*1024*1024)
    {
    if (input.files && input.files[0]) {
      this.filesPath.push(input.files[0])
        // console.log(obj)
        this.projectStructure.background = input.files[0].name;
        if(this.selectedPageContent?.sections[3]?.contents[1]?.content[0]?.elements[0]?.projects.length >0 && this.selectedPageContent?.sections[3]?.contents[1]?.content[0]?.elements[0]?.projects.findIndex((obj => obj._id == this.projectId)) != -1){
        let objIndex = this.selectedPageContent?.sections[3]?.contents[1]?.content[0]?.elements[0]?.projects.findIndex((obj => obj._id == this.projectId));
        this.selectedPageContent.sections[3].contents[1].content[0].elements[0].projects[objIndex].page_name=this.projectStructure.background;
      }
    }
  }else
  this.toastr.error('Image très large! merci de ne pas dépasser 10 Mo');
  }

  changeBackgroundImg(input, arrayElementIndex) {
    if(input.files[0].size < 10*1024*1024)
    {
    if (input.files && input.files[0]) {
      this.filesPath.push(input.files[0])
        this.projectStructure.sections[arrayElementIndex[0]].contents[arrayElementIndex[1]].content[arrayElementIndex[2]].elements[arrayElementIndex[3]]['background_img'] = input.files[0].name;
      };
  }else
  this.toastr.error('Image très large! merci de ne pas dépasser 10 Mo');
  }

  addGalleryImg() {
    // @ts-ignore
    this.projectStructure.sections[3].contents[0].content[2].elements.push({img: ''});
  }

  deleteFromGallery(index: number){
    this.projectStructure?.sections[3]?.contents[0]?.content[2]?.elements.splice(index, 1);
  }

  addImg(input, arrayElementIndex) {
    if(input.files[0].size < 10*1024*1024)
    {
    if (input.files && input.files[0]) {
      this.filesPath.push(input.files[0])
        this.projectStructure.sections[arrayElementIndex[0]].contents[arrayElementIndex[1]].content[arrayElementIndex[2]].elements[arrayElementIndex[3]]['img'] = input.files[0].name;
      };
  }else
  this.toastr.error('Image très large! merci de ne pas dépasser 10 Mo');
  }

  addProduct(productName: string) {
    this.searchproduct = '';
    // @ts-ignore
    this.projectStructure.sections[5].contents[0].content[1].elements.push({product: productName});

  }


  searchProduct() {
    this.products = this.fixedProduct.filter(x => {
      let name = x.s_modele_market.toLowerCase().toString();
      return name.includes(this.searchproduct.toLowerCase());
    });
  }
  transformChar() {
    let titleToUdapte = this.projectStructure.page_name
    titleToUdapte = titleToUdapte.replace(/\W+(?!$)/g, '-').toLowerCase();
    titleToUdapte = titleToUdapte.replace(/\W$/, '').toLowerCase();

    this.NetUrl=titleToUdapte;
    this.projectStructure.page_url = titleToUdapte;

  }
  generateUrlPoject() {
    this.transformChar()
  //  this.projectStructure.page_url = '/projet/' + this.replaceString.transform(this.projectStructure.page_name.toLowerCase(), ' ', '_');
    this.projectStructure.page_url = (this.projectStructure.page_url[this.projectStructure.page_url.length - 1] == '-') ? this.projectStructure.page_url.slice(0, -1) : this.projectStructure.page_url;
  }

  // get_page_content() {
  //   this.homeService.get_page_content_proj('home').subscribe(homeContent => {
  //     this.selectedPageContent = homeContent;
  //     //console.log('aaaaaaaaaaa',this.fixedPageContent)
  //    // console.log('bbbbbbbbbb',this.selectedPageContent)
  //   });
  // }

  submit() {
    let validProject = true;
    if (this.projectStructure.background == '') {
      validProject = false;
    }

    if (this.projectStructure.page_url.length < 7) {
      validProject = false;
    }
    if (this.projectStructure.page_name == '') {
      validProject = false;
    }
    if (validProject) {
      if (JSON.stringify(this.projectStructure).length > 1024*1024*50){
      this.toastr.error('La taille totale des images ne doit pas dépasser 50 Mo');
    }
      else{
         //"jpg"|"JPG"|"jpeg"|"JPEG"|"png"|"PNG"|"gif"|"GIF"
      /* var types = ["image/png", "image/gif", "image/jpeg", "application/pdf"]
       console.log(this.filesPath)
       this.filesPath.forEach(element => {
         
           const formData: FormData = new FormData();
           formData.append('files', element);
       
           this.projectService.uploadImages(formData).subscribe(res =>{
             console.log("done")
           })
       
       });*/

      
      var types = ["image/png", "image/gif", "image/jpeg", "application/pdf"]
      if(this.filesPath.length > 0){
      for (let index = 0; index < this.filesPath.length; index++) {
        const formData: FormData = new FormData();
          formData.append('files', this.filesPath[index]);
      
          this.projectService.uploadImages(formData).subscribe(res =>{
            console.log("done")
          })
          if (index == Number(this.filesPath.length-1)){
            if(this.selectedPageContent?.sections[3]?.contents[1]?.content[0]?.elements[0]?.projects.length >0 && this.selectedPageContent?.sections[3]?.contents[1]?.content[0]?.elements[0].projects.findIndex((obj => obj._id == this.projectId)) != -1){
              let objIndex = this.selectedPageContent?.sections[3]?.contents[1]?.content[0]?.elements[0]?.projects.findIndex((obj => obj._id == this.projectId));
          this.selectedPageContent.sections[3].contents[1].content[0].elements[0].projects[objIndex].page_name=this.projectStructure.sections[0].title;
          this.selectedPageContent.sections[3].contents[1].content[0].elements[0].projects[objIndex].page_url=this.projectStructure.page_url;
   
          this.homeService.update_section_content('home', this.selectedPageContent.sections[3]).subscribe((data) => {
          })
        
      }
      if(this.projectStructure.page_url[(this.projectStructure.page_url.length)-1] == "-")
      {
        
        this.projectStructure.page_url =this.projectStructure.page_url.slice(0,(this.projectStructure.page_url.length)-1)
      }
            this.projectService.update_project(this.projectStructure).subscribe(data => {
              //console.log(data)
              this.toastr.success('Le projet a été modifier avec succès!');
            }, (error) => {
              //console.log(error)
              if (error.status == 200) {
                this.toastr.success('Le projet a été modifiée avec succès!');
               
                setTimeout(() => {
                  this.router.navigate(['user-profile/' + this.id + '/projets']).then(() => {
                    window.location.reload();
                  });
                }, 1000);
              }
              else{
                this.toastr.error('Le projet n\'a pas été modifiée avec succès!');
              }
            });
          }
        }
      }
      else if(this.filesPath.length == 0) {
        if(this.selectedPageContent?.sections[3]?.contents[1]?.content[0]?.elements[0]?.projects.length >0 && this.selectedPageContent?.sections[3]?.contents[1]?.content[0]?.elements[0].projects.findIndex((obj => obj._id == this.projectId)) != -1){
          let objIndex = this.selectedPageContent?.sections[3]?.contents[1]?.content[0]?.elements[0]?.projects.findIndex((obj => obj._id == this.projectId));
      this.selectedPageContent.sections[3].contents[1].content[0].elements[0].projects[objIndex].page_name=this.projectStructure.sections[0].title;
      this.selectedPageContent.sections[3].contents[1].content[0].elements[0].projects[objIndex].page_url=this.projectStructure.page_url;

      this.homeService.update_section_content('home', this.selectedPageContent.sections[3]).subscribe((data) => {
      })
    
  }
        this.projectService.update_project(this.projectStructure).subscribe(data => {
          //console.log(data)
          this.toastr.success('Le projet a été modifier avec succès!');
        }, (error) => {
          //console.log(error)
          if (error.status == 200) {
            this.toastr.success('Le projet a été modifiée avec succès!');
           
            setTimeout(() => {
              this.router.navigate(['user-profile/' + this.id + '/projets']).then(() => {
                window.location.reload();
              });
            }, 1000);
          }
          else{
            this.toastr.error('Le projet n\'a pas été modifiée avec succès!');
          }
        });
      }
      
    }}
    }

  generateBannerTitle() {
    this.projectStructure.sections[0].title = this.projectStructure.page_name;
    this.projectStructure.sections[0].contents[0].content[0].elements[0]['title'] = this.projectStructure.page_name;
  }

  navigateListProject() {
     this.router.navigate(['user-profile/' + this.id + '/projets']);
  }

  removeProduct(indexprd: number) {
    this.projectStructure.sections[5].contents[0].content[1]['elements'].splice(indexprd, 1);
  }

  // private setDataProject(data) {
  //   this.projectStructure.page_url = data.page_url;
  //   this.fixedProjectStructure.page_url = data.page_url;
  // }
}
