import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageStructure } from '../model/page-structure';

// const baseUrl = `http://149.202.54.170:3020/pages/home`;
 const baseUrl = `/pages/home`;
 const baseUrlPage = `/pagesCMS/`;
// const baseUrlPage = `http://localhost:3021/pagesCMS/`;

//const baseUrl = `http://localhost:3020/pages/home`;

@Injectable({
  providedIn: "root",
})
export class HomePageService {
  constructor(private http: HttpClient) {
  }

  get_all_contents() {
    return this.http.get(`${baseUrl}/all`);
  }

  get_page_list() {
    return this.http.get(`${baseUrlPage}/list`);
  }

  get_page_list_apropos() {
    return this.http.get(`${baseUrlPage}/list/apropos`);
  }

  

  get_page_content(pageName: string) {
    return this.http.get<PageStructure>(`${baseUrlPage}` + pageName);
  }

  get_page_content_act(pageName: string) {
    return this.http.get<PageStructure>(`${baseUrlPage}` + pageName+'/getAct');
  }

  get_page_content_proj(pageName: string) {
    return this.http.get<PageStructure>(`${baseUrlPage}`+ pageName+'/getProj');
  }

  update_section_content(pageName: string, section: any) {
    return this.http.put(`${baseUrlPage}` + pageName + '/update/section', section);
  }

  update_cover_section(data) {
    return this.http.put(`${baseUrl}/update-cover`, data);
  }

  update_welcome_section(data) {
    return this.http.put(`${baseUrl}/update-welcome`, data);
  }
  update_confiance_section(data) {
    return this.http.put(`${baseUrl}/update-confiance`, data);
  }

  update_actu_section(data) {
    return this.http.put(`${baseUrl}/update-actu`, data);
  }

  update_horeca_section(data) {
    return this.http.put(`${baseUrl}/update-horeca`, data);
  }

  update_inspiration_section(data) {
    return this.http.put(`${baseUrl}/update-inspiration`, data);
  }

  update_excellence_section(data) {
    return this.http.put(`${baseUrl}/update-excellence`, data);
  }

  update_noel_section(data) {
    return this.http.put(`${baseUrl}/update-noel`, data);
  }

  update_projet_section(data) {
    return this.http.put(`${baseUrl}/update-projet`, data);
  }

  update_equipe_section(data) {
    return this.http.put(`${baseUrl}/update-equipe`, data);
  }

  update_boutique_section(data) {
    return this.http.put(`${baseUrl}/update-boutique`, data);
  }

  update_marque_section(data) {
    return this.http.put(`${baseUrl}/update-marque`, data);
  }

  update_gamme_section(data) {
    return this.http.put(`${baseUrl}/update-gamme`, data);
  }
}
