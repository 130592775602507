import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PreloaderComponent } from 'src/app/backoffice/_common/preloader/preloader.component';
import { HomePageService } from 'src/app/_shared/home-page.service';
import { ProjectService } from 'src/app/_shared/project.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-list',
  template: `<app-preloader></app-preloader>`,
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {

  id:any;
  projectslist: any;
  showDeletedProject = false;
  projectToDelete: any;
  homeAct: any;
  uploadUrl=`${environment.uploadUrl}`
  page: number = 1;
  count: number = 0;
  tableSize: number = 6;
  tableSizes: any = [3, 6, 9, 12];
  DeleteAction: boolean;
  HideAction: boolean;
  DisplayAction: boolean;
  choosedProject: any;
  sizeList
  verif
  isloading: boolean =false;
  constructor(private projectService: ProjectService, private toastr: ToastrService, private homeService: HomePageService) {
  }

  get_projects_list() {

    this.projectService.get_actualités_list().subscribe(async data => {
      this.projectslist = await data;
      this.sizeList = this.projectslist.length
    });
  }

  async getHomeAct() {
    this.homeService.get_page_content_act('home').subscribe(async data => {
      this.homeAct = await data
    })
     
  }

  ngOnInit(): void {
    //this.get_projects_list();
    if(localStorage.getItem("reload") == "1")
    {
      this.isloading =true
      setTimeout(()=>{
        localStorage.setItem("reload","0")
        this.isloading =false;
      },4000)

      // window.location.reload();
    }
    this.get_projects_list()
    this.id=localStorage.getItem('user')
  }

  updateDisplayProject() {
    let project = this.choosedProject
    

    this.projectService.updateDisplayProject(project).subscribe(data => {
      this.toastr.success('L\'actualité a été modifiée avec succès!');
      this.get_projects_list()

    });

  }


  deleteDisplayProject() {
    this.projectService.delete_project(this.projectToDelete._id).subscribe(data => {
      this.toastr.success('L\'actualité a été supprimer avec succès!');
      this.get_projects_list()
    });

  }
  Annuler(){
    this.DeleteAction = false;
    this.HideAction = false;
    this.DisplayAction = false;
  }
  displayingDeletedProjects() {
    this.showDeletedProject = !this.showDeletedProject;
  }
  onTableDataChange(event: any) {
    this.page = event;
    this.get_projects_list()
  }
  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = event;
    this.get_projects_list()
  }
  setObjectTotrait(project){
    this.choosedProject = project
  }

  setHideMessage(project){
    if(project.hide_page){
      this.HideAction =false;
      this.DisplayAction =true;

    }else{
      this.HideAction =true;
      this.DisplayAction =false;
    }
  }

}
