import {Component, OnInit} from '@angular/core';
import {NgRecaptcha3Service} from 'ng-recaptcha3';
import {CookieService} from 'ngx-cookie-service';
import {GsapService} from 'src/app/_shared/gsap.service';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  private siteKey = "6LdqQG8cAAAAAIg1SX2AwrjHvqc0C7m61l-cNHcU";

  private SIGNING_KEY: string;
  private cookieValue: string;

  perf_fonc_cookies_checked: boolean;
  pub_cookies_checked: boolean;
  analysis_cookies_checked: boolean;
  consent_policy = {
    ess: null,
    func: null,
    adv: null,
    pers: null,
    dts: null,
    ts: null,
  };

  constructor(private recaptcha3: NgRecaptcha3Service, private cookieService: CookieService, private gsapAnimationService: GsapService) {
  }

  ngOnInit(): void {
    this.gsapAnimationService.layerAnimations();
    this.recaptcha3.init(this.siteKey);
    if (!this.cookieService.get('consent-policy')) {
      document
        .getElementById('cookies-banner')
        .setAttribute('style', 'display: block');
    } else {
      document
        .getElementById('cookies-banner')
        .setAttribute('style', 'display: none');
    }

  }

  check_cookies() {
    if (this.cookieService.get('consent-policy')) {
      document
        .getElementById('cookies-banner')
        .setAttribute('style', 'display: none');
    }
  }

  check_cookies_params() {
    if (this.cookieService.get('consent-policy')) {
      document
        .getElementById('cookies-param')
        .setAttribute('style', 'display: none');
      document
        .getElementById('cookies-banner')
        .setAttribute('style', 'display: none');
    }
  }

  cookie_settings() {
    document
      .getElementById('cookies-param')
      .setAttribute('style', 'display: block');
  }

  close_settings() {
    document
      .getElementById('cookies-param')
      .setAttribute('style', 'display: none');
  }

  dismiss_all_cookie() {
    this.consent_policy = {
      ess: 0,
      func: 0,
      adv: 0,
      pers: 0,
      dts: 0,
      ts: this.SIGNING_KEY,
    };
    this.cookieService.set(
      'consent-policy',
      JSON.stringify(this.consent_policy)
    );
    this.cookieValue = this.cookieService.get('consent-policy');
    console.log('all-refused : ', this.cookieValue);
  }

  accept_all_cookie() {
    this.consent_policy = {
      ess: 1,
      func: 1,
      adv: 1,
      pers: 1,
      dts: 4,
      ts: this.SIGNING_KEY,
    };
    this.cookieService.set(
      'consent-policy',
      JSON.stringify(this.consent_policy)
    );
    this.cookieValue = this.cookieService.get('consent-policy');
    console.log('consent-policy : ', this.cookieValue);
  }

  accept_some_cookie() {
    var counter = 1;
    counter =
      counter +
      (this.perf_fonc_cookies_checked ? 1 : 0) +
      (this.pub_cookies_checked ? 1 : 0) +
      (this.analysis_cookies_checked ? 1 : 0);
    this.consent_policy = {
      ess: 1,
      func: this.perf_fonc_cookies_checked ? 1 : 0,
      adv: this.pub_cookies_checked ? 1 : 0,
      pers: this.analysis_cookies_checked ? 1 : 0,
      dts: counter,
      ts: this.SIGNING_KEY,
    };
    this.cookieService.set(
      'consent-policy',
      JSON.stringify(this.consent_policy)
    );
    this.cookieValue = this.cookieService.get('consent-policy');
    console.log('consent-policy : ', this.cookieValue);
  }

  checked() {
    var perf_fonc_cookies = <HTMLInputElement>(
      document.getElementById('perf_fonc_cookies')
    );
    var pub_cookies = <HTMLInputElement>document.getElementById('pub_cookies');
    var analysis_cookies = <HTMLInputElement>(
      document.getElementById('analysis_cookies')
    );
    this.perf_fonc_cookies_checked = perf_fonc_cookies.checked;
    this.pub_cookies_checked = pub_cookies.checked;
    this.analysis_cookies_checked = analysis_cookies.checked;
    console.log(this.perf_fonc_cookies_checked);
    console.log(this.pub_cookies_checked);
    console.log(this.analysis_cookies_checked);
  }

  title = "pdjall";
}
