<router-outlet></router-outlet>

<!-- <app-footer></app-footer> -->
<!-- Cookies -->
<div class="all-cookies text-center">
  <div id="cookies-banner" class="bg-custom text-white button-fixed" data-aos="fade-up" data-aos-duration="3000"
       (click)="check_cookies()">
    <div class="row">
      <div class="col-md-10">
        <p>
          Nous utilisons des cookies pour fournir des fonctionnalités de site Web, pour analyser le
          trafic sur notre site Plaisir du jardin, personnaliser le contenu, diffuser des publicités ciblées et
          activer la fonctionnalité de médias sociaux. Notre Déclaration relative aux cookies fournit
          plus d'informations et explique comment mettre à jour vos paramètres de cookies. <br>
          Consultez <a class="a-cookies" href="../../../assets/pdf/politique-vie-privee-sundis-clean-fr.pdf"
                       target="_blank">notre déclaration relative aux cookies</a>.
        </p>
      </div>
      <div class="col-md-2 d-flex text-center justify-content-center">
        <table class="cookies-btns">
          <tr>
            <button type="button" class="btn btn-accept w-100" (click)="cookie_settings()" style="width: 100%;">Paramètres des
              cookies
            </button>
          </tr>
          <tr>
            <button type="button" class="btn btn-reject w-100" (click)="dismiss_all_cookie()"> Rejeter tout</button>
          </tr>
          <tr>
            <button type="button" class="btn btn-accept w-100" (click)="accept_all_cookie()">Accepter tous
              les cookies
            </button>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <div *responsive="{bootstrap: ['xl', 'lg', 'md']}" class="cookies-param" id="cookies-param" data-aos="fade-right"
       data-aos-duration="3000" style="display: none;" (click)="check_cookies_params()">
    <div class="close-btn" (click)="close_settings()">
      <span aria-hidden="true">&times;</span>
    </div>
    <hr>
    <div class="param-intro justify-content-center">
      <p class="intro-1" style="color: #343a40;">
        Le site Plaisir du jardin peut demander que des cookies soient définis sur votre appareil. Nous utilisons des
        cookies pour nous informer lorsque vous visitez notre site, pour comprendre comment vous interagissez
        avec nous, pour enrichir et personnaliser votre expérience utilisateur, pour activer les fonctionnalités
        de médias sociaux et pour personnaliser votre relation avec Plaisir du jardin, notamment en vous fournissant des
        publicités plus pertinentes. Cliquez sur les différentes rubriques des catégories pour en savoir plus.
        Vous pouvez également modifier vos préférences en matière de cookies à tout moment.
      </p>
      <div class="row justify-content-left row-btn-param">
        <button type="button" class="btn btn-1-param" (click)="accept_all_cookie()" style="color: #343a40;">Accepter
          tous les
          cookies
        </button>
      </div>
      <h6>Gérer les préférences de consentement </h6>
      <div class="params">
        <details>
          <summary class="row sum-row">
            <div class="col-10">Cookies de site Web</div>
            <div class="col-1">
              <label class="switch">
                <input id="website_cookies" type="checkbox" checked disabled>
                <span class="slider round"></span>
              </label>
            </div>
          </summary>
          <p>Ces cookies sont strictement nécessaires pour vous fournir les services et fonctionnalités
            disponibles sur notre site. Étant donné que ces cookies sont strictement nécessaires à la
            livraison du site, vous ne pouvez pas les refuser sans impacter le fonctionnement du site.</p>
        </details>
        <details>
          <summary class="row sum-row">
            <div class="col-10">Cookies de performance<br>et de fonctionnalité</div>
            <div class="col-1">
              <label class="switch">
                <input id="perf_fonc_cookies" type="checkbox" checked (change)="checked()">
                <span class="slider round"></span>
              </label>
            </div>
          </summary>
          <p>Ces cookies sont utilisés pour améliorer la fonctionnalité du site. Ils nous aident à
            personnaliser le site et l'application (le cas échéant) pour vous afin d'améliorer votre
            expérience. Bien qu'importants pour nous, ces cookies ne sont pas essentiels à l'utilisation du
            site. Cependant, sans ces cookies, certaines fonctionnalités peuvent devenir indisponibles.</p>
        </details>
        <details>
          <summary class="row sum-row">
            <div class="col-10">Cookies publicitaires (ciblés)</div>
            <div class="col-1">
              <label class="switch">
                <input id="pub_cookies" type="checkbox" checked (change)="checked()">
                <span class="slider round"></span>
              </label>
            </div>
          </summary>
          <p>Ces cookies sont utilisés pour rendre les messages publicitaires plus pertinents pour vous et vos
            intérêts. Ils remplissent également des fonctions comme empêcher la même annonce de réapparaître
            continuellement, s'assurer que les publicités sont correctement affichées et, dans certains cas,
            sélectionner des publicités en fonction de vos intérêts.</p>
        </details>
        <details>
          <summary class="row sum-row">
            <div class="col-10">Cookies d'analyse<br>et de personnalisation</div>
            <div class="col-1">
              <label class="switch">
                <input id="analysis_cookies" type="checkbox" checked (change)="checked()">
                <span class="slider round"></span>
              </label>
            </div>
          </summary>
          <p>Ces cookies collectent des informations pour nous aider à comprendre comment le site est utilisé
            ou à quel point nos campagnes marketing sont efficaces. Ils nous aident à savoir quelles pages
            sont les plus et les moins populaires et à voir comment les visiteurs se déplacent sur le site.
            Ces cookies peuvent être définis par nous ou par des fournisseurs tiers dont nous avons ajouté
            les services à nos pages.</p>
        </details>
      </div>
    </div>
    <div class="row justify-content-center">
      <button type="button" class="col-5 btn btn-reject-param" (click)="dismiss_all_cookie()"
              style="margin-right: 3%;">Rejeter tout
      </button>
      <button type="button" class="col-5 btn btn-accept-param" (click)="accept_some_cookie()">Confirmer mes
        choix
      </button>
    </div>
  </div>
  <div *sm class="cookies-param-sm" id="cookies-param" data-aos="fade-right" data-aos-duration="3000"
       style="display: none;" (click)="check_cookies_params()">
    <div class="close-btn" (click)="close_settings()">
      <span aria-hidden="true">&times;</span>
    </div>
    <hr>
    <div class="param-intro justify-content-center">
      <p class="intro-1">
        Le site Lulu&Co peut demander que des cookies soient définis sur votre appareil. Nous utilisons des
        cookies pour nous informer lorsque vous visitez notre site, pour comprendre comment vous interagissez
        avec nous, pour enrichir et personnaliser votre expérience utilisateur, pour activer les fonctionnalités
        de médias sociaux et pour personnaliser votre relation avec Lulu&Co, notamment en vous fournissant des
        publicités plus pertinentes. Cliquez sur les différentes rubriques des catégories pour en savoir plus.
        Vous pouvez également modifier vos préférences en matière de cookies à tout moment.
      </p>
      <div class="row justify-content-left row-btn-param">
        <button type="button" class="btn btn-1-param" (click)="accept_all_cookie()">Accepter tous les
          cookies
        </button>
      </div>
      <h6>Gérer les préférences de consentement </h6>
      <div class="params">
        <details>
          <summary class="row sum-row">
            <div class="col-10">Cookies de site Web</div>
            <div class="col-1">
              <label class="switch">
                <input id="website_cookies" type="checkbox" checked disabled>
                <span class="slider round"></span>
              </label>
            </div>
          </summary>
          <p>Ces cookies sont strictement nécessaires pour vous fournir les services et fonctionnalités
            disponibles sur notre site. Étant donné que ces cookies sont strictement nécessaires à la
            livraison du site, vous ne pouvez pas les refuser sans impacter le fonctionnement du site.</p>
        </details>
        <details>
          <summary class="row sum-row">
            <div class="col-10">Cookies de performance<br>et de fonctionnalité</div>
            <div class="col-1">
              <label class="switch">
                <input id="perf_fonc_cookies" type="checkbox" checked (change)="checked()">
                <span class="slider round"></span>
              </label>
            </div>
          </summary>
          <p>Ces cookies sont utilisés pour améliorer la fonctionnalité du site. Ils nous aident à
            personnaliser le site et l'application (le cas échéant) pour vous afin d'améliorer votre
            expérience. Bien qu'importants pour nous, ces cookies ne sont pas essentiels à l'utilisation du
            site. Cependant, sans ces cookies, certaines fonctionnalités peuvent devenir indisponibles.</p>
        </details>
        <details>
          <summary class="row sum-row">
            <div class="col-10">Cookies publicitaires (ciblés)</div>
            <div class="col-1">
              <label class="switch">
                <input id="pub_cookies" type="checkbox" checked (change)="checked()">
                <span class="slider round"></span>
              </label>
            </div>
          </summary>
          <p>Ces cookies sont utilisés pour rendre les messages publicitaires plus pertinents pour vous et vos
            intérêts. Ils remplissent également des fonctions comme empêcher la même annonce de réapparaître
            continuellement, s'assurer que les publicités sont correctement affichées et, dans certains cas,
            sélectionner des publicités en fonction de vos intérêts.</p>
        </details>
        <details>
          <summary class="row sum-row">
            <div class="col-10">Cookies d'analyse<br>et de personnalisation</div>
            <div class="col-1">
              <label class="switch">
                <input id="analysis_cookies" type="checkbox" checked (change)="checked()">
                <span class="slider round"></span>
              </label>
            </div>
          </summary>
          <p>Ces cookies collectent des informations pour nous aider à comprendre comment le site est utilisé
            ou à quel point nos campagnes marketing sont efficaces. Ils nous aident à savoir quelles pages
            sont les plus et les moins populaires et à voir comment les visiteurs se déplacent sur le site.
            Ces cookies peuvent être définis par nous ou par des fournisseurs tiers dont nous avons ajouté
            les services à nos pages.</p>
        </details>
      </div>
    </div>
    <div class="row justify-content-center">
      <button type="button" class="col-5 btn btn-reject-param" (click)="dismiss_all_cookie()"
              style="margin-right: 3%;">Rejeter tout
      </button>
      <button type="button" class="col-5 btn btn-accept-param" (click)="accept_some_cookie()">Confirmer mes
        choix
      </button>
    </div>
  </div>
  <div *xs class="cookies-param-xs" id="cookies-param" data-aos="fade-right" data-aos-duration="3000"
       style="display: none;" (click)="check_cookies_params()">
    <div class="close-btn" (click)="close_settings()">
      <span aria-hidden="true">&times;</span>
    </div>
    <hr>
    <div class="param-intro justify-content-center">
      <p class="intro-1">
        Le site Lulu&Co peut demander que des cookies soient définis sur votre appareil. Nous utilisons des
        cookies pour nous informer lorsque vous visitez notre site, pour comprendre comment vous interagissez
        avec nous, pour enrichir et personnaliser votre expérience utilisateur, pour activer les fonctionnalités
        de médias sociaux et pour personnaliser votre relation avec Lulu&Co, notamment en vous fournissant des
        publicités plus pertinentes. Cliquez sur les différentes rubriques des catégories pour en savoir plus.
        Vous pouvez également modifier vos préférences en matière de cookies à tout moment.
      </p>
      <div class="row justify-content-left row-btn-param">
        <button type="button" class="btn btn-1-param" (click)="accept_all_cookie()">Accepter tous les
          cookies
        </button>
      </div>
      <h6>Gérer les préférences de consentement </h6>
      <div class="params">
        <details>
          <summary class="row sum-row">
            <div class="col-10">Cookies de site Web</div>
            <div class="col-1">
              <label class="switch">
                <input id="website_cookies" type="checkbox" checked disabled>
                <span class="slider round"></span>
              </label>
            </div>
          </summary>
          <p>Ces cookies sont strictement nécessaires pour vous fournir les services et fonctionnalités
            disponibles sur notre site. Étant donné que ces cookies sont strictement nécessaires à la
            livraison du site, vous ne pouvez pas les refuser sans impacter le fonctionnement du site.</p>
        </details>
        <details>
          <summary class="row sum-row">
            <div class="col-10">Cookies de performance<br>et de fonctionnalité</div>
            <div class="col-1">
              <label class="switch">
                <input id="perf_fonc_cookies" type="checkbox" checked (change)="checked()">
                <span class="slider round"></span>
              </label>
            </div>
          </summary>
          <p>Ces cookies sont utilisés pour améliorer la fonctionnalité du site. Ils nous aident à
            personnaliser le site et l'application (le cas échéant) pour vous afin d'améliorer votre
            expérience. Bien qu'importants pour nous, ces cookies ne sont pas essentiels à l'utilisation du
            site. Cependant, sans ces cookies, certaines fonctionnalités peuvent devenir indisponibles.</p>
        </details>
        <details>
          <summary class="row sum-row">
            <div class="col-10">Cookies publicitaires (ciblés)</div>
            <div class="col-1">
              <label class="switch">
                <input id="pub_cookies" type="checkbox" checked (change)="checked()">
                <span class="slider round"></span>
              </label>
            </div>
          </summary>
          <p>Ces cookies sont utilisés pour rendre les messages publicitaires plus pertinents pour vous et vos
            intérêts. Ils remplissent également des fonctions comme empêcher la même annonce de réapparaître
            continuellement, s'assurer que les publicités sont correctement affichées et, dans certains cas,
            sélectionner des publicités en fonction de vos intérêts.</p>
        </details>
        <details>
          <summary class="row sum-row">
            <div class="col-10">Cookies d'analyse<br>et de personnalisation</div>
            <div class="col-1">
              <label class="switch">
                <input id="analysis_cookies" type="checkbox" checked (change)="checked()">
                <span class="slider round"></span>
              </label>
            </div>
          </summary>
          <p>Ces cookies collectent des informations pour nous aider à comprendre comment le site est utilisé
            ou à quel point nos campagnes marketing sont efficaces. Ils nous aident à savoir quelles pages
            sont les plus et les moins populaires et à voir comment les visiteurs se déplacent sur le site.
            Ces cookies peuvent être définis par nous ou par des fournisseurs tiers dont nous avons ajouté
            les services à nos pages.</p>
        </details>
      </div>
    </div>
    <div class="row justify-content-center">
      <button type="button" class="col-5 btn btn-reject-param" (click)="dismiss_all_cookie()"
              style="margin-right: 3%;">Rejeter tout
      </button>
      <button type="button" class="col-5 btn btn-accept-param" (click)="accept_some_cookie()">Confirmer mes
        choix
      </button>
    </div>
  </div>
</div>
<!-- End of Cookies -->
