<div class="cadre_scroll">
  <section class="content ecommerce-page">
    <div class="block-header">
      <div class="row">
        <div class="col-lg-7 col-md-6 col-sm-12">
          <h2>Modification de la page FAQ
            <small class="text-muted">Bienvenue au Backoffice de Plaisir du Jardin</small>
          </h2>
        </div>
        <div class="col-lg-5 col-md-6 col-sm-12">
          <ul class="breadcrumb float-md-right">
            <li class="breadcrumb-item"><a routerLink="../../"><i class="zmdi zmdi-home"></i>
                Backoffice</a>
            </li>
            <li class="breadcrumb-item"><a>Pages</a></li>
            <li class="breadcrumb-item active">Page FAQ</li>
          </ul>
        </div>

      </div>
    </div>
    <div class="container-fluid">
      <div class="row clearfix">
        <div class="col-lg-12">
          <div class="card">
            <div class="body">
              <div class="row">
                <div class="col-4">
                  <div class="list-group list-group-flush" id="list-tab" role="tablist">
                    <a *ngFor="let section of fixedPageContent?.sections;let i = index"
                      class="list-group-item list-group-item-action"
                      [ngClass]="(i==selectedPageContentIndex)?'active':''" id="list-cover-list" data-toggle="list"
                      href="#list-cover" role="tab" aria-controls="cover"
                      (click)="selectedPageContentIndex=i;">{{section.section_name | replace: '_' : ' ' }}</a>
                  </div>
                </div>
                <div class="col-8 border-left-yellow">
                  <div class="tab-content">
                    <div class="tab-pane fade show active" id="list-cover" role="tabpanel"
                      aria-labelledby="list-cover-list">
                      <!--                      <h5><b>Contenu actuel :</b></h5>-->
                      <!--                      <p><b>Titre section : </b>{{pageContent.sections[0].title}}</p>-->
                      <!--                      <p><b>Arrière plan section : </b>{{pageContent.sections[0].background}}</p>-->
                      <!--                      <ng-container *ngFor="let contents of pageContent.sections[0].contents; let contentsI= index">-->
                      <!--                        <div class="row btn-row mb-4" style="display: block;width: 50%;float: initial">-->
                      <!--                          <div class="col-4">-->
                      <!--                            <button class="validate-btn" style="border-radius: 10px">{{contents.language}}</button>-->
                      <!--                          </div>-->
                      <!--                        </div>-->
                      <!--                        <ng-container *ngFor="let content of contents.content">-->
                      <!--                          <div *ngIf="content.content_type=='buttons'">-->
                      <!--                            <p>Type contenu: {{content.content_type}}</p>-->
                      <!--                            <div class="row btn-row mb-4" style="width: 100%;float: initial">-->
                      <!--                              <ng-container class="col-4" *ngFor="let element of content.elements">-->
                      <!--                                <div class="col-4">-->
                      <!--                                  <p class="mt-2 mb-1">button:</p>-->
                      <!--                                  <button class="validate-btn"-->
                      <!--                                          style="border-radius: 10px">{{element.btn_text}}</button>-->
                      <!--                                  <p class="mt-2 mb-1">{{element.btn_redirection}}</p>-->
                      <!--                                </div>-->
                      <!--                                &lt;!&ndash;                                <li>Redirection: {{element.btn_redirection}}</li>&ndash;&gt;-->
                      <!--                                &lt;!&ndash;                                <li class="mt-1 mb-4">Text: {{element.btn_text}}</li>&ndash;&gt;-->
                      <!--                              </ng-container>-->
                      <!--                            </div>-->
                      <!--                          </div>-->
                      <!--                        </ng-container>-->
                      <!--                      </ng-container>-->
                      <!--                      <hr/>-->

                      <h5><b>Section {{fixedPageContent?.sections[selectedPageContentIndex].section_name | replace: '_'
                          : ' '}}:</b>
                      </h5>
                      <h6>Langue:</h6>
                      <div class="row mb-4">
                        <ng-container
                          *ngFor="let contents of selectedPageContent?.sections[selectedPageContentIndex].contents; let contentsI= index">
                          <button class="btn btn-admin col-1" [ngClass]="(contents.language=='fr')?'current':''"
                            style="text-transform: uppercase">{{contents.language}}</button>
                        </ng-container>
                        <button class="btn btn-admin col-1" style="text-transform: uppercase">nl
                        </button>
                      </div>
                      <!--                      <ng-container-->
                      <!--                        *ngFor="let contents of selectedPageContent?.sections[selectedPageContentIndex].contents; let contentsI= index">-->
                      <!--                        <div class="row btn-row mb-4" style="display: block;width: 50%;float: initial">-->
                      <!--                          <div class="col-4">-->
                      <!--                            <button class="validate-btn"-->
                      <!--                                    style="border-radius: 10px;text-transform: capitalize">{{contents.language}}</button>-->
                      <!--                          </div>-->
                      <!--                        </div>-->
                      <!--                      </ng-container>-->
                      <div class="form-group mb-4">
                        <div class="d-flex"><label>Titre : <span
                              class="form-text text-muted">({{fixedPageContent?.sections[selectedPageContentIndex].title
                              | replace: '_' : ' '}}
                              )</span></label>
                        </div>

                        <input type="email" class="form-control"
                          [(ngModel)]="selectedPageContent?.sections[selectedPageContentIndex].title"
                          placeholder="Tapez le nouveau titre section">
                      </div>
                      <div class="form-group"
                        *ngIf="fixedPageContent?.sections[selectedPageContentIndex].background != ''">
                        <hr />
                        <div class="row"><label class="col-2">Arrière plan : </label>
                          <!--                          <small-->
                          <!--                            class="form-text text-muted">({{fixedPageContent.sections[selectedPageContentIndex].background}}-->
                          <!--                            )</small>-->
                          <picture class="col-4">
                            <img [src]="fixedPageContent?.sections[selectedPageContentIndex].background"
                              [(ngModel)]="fixedPageContent?.sections[selectedPageContentIndex].background"
                              class="img-fluid img-thumbnail" style="width: 15vw;height: auto" alt="...">
                          </picture>
                          <input (change)="changeBackgroundSection($event.target)" type="file"
                            class="form-control col-3" style="height: 50px">
                        </div>

                      </div>

                      <ng-container class="mb-2"
                        *ngFor="let contents of selectedPageContent?.sections[selectedPageContentIndex].contents; let contentsI= index">
                        <ng-container *ngFor="let content of contents.content;let contentI = index">

                          <div class="form-group" *ngIf="content.content_type=='image'">
                            <hr />
                            <div class="row"><label class="col-2">Arrière plan : </label>
                              <!--                          <small-->
                              <!--                            class="form-text text-muted">({{fixedPageContent.sections[selectedPageContentIndex].background}}-->
                              <!--                            )</small>-->
                              <picture class="col-4">
                                <img [src]="content.elements[0].background_img" class="img-fluid img-thumbnail"
                                  style="width: 15vw;height: auto" alt="...">
                              </picture>
                              <input (change)="changeBackgroundImg($event.target,[contentsI,contentI,0])" type="file"
                                class="form-control col-3" style="height: 50px">
                            </div>

                          </div>

                          <!--                          <div *ngIf="content.content_type=='image'">-->
                          <!--                            <div class="form-group">-->
                          <!--                              <label>Image :</label>-->
                          <!--                              <div class="d-flex"><label>Arrière plan : </label>-->
                          <!--                                <picture>-->
                          <!--                                  <img-->
                          <!--                                    [src]="fixedPageContent.sections[selectedPageContentIndex].contents[contentsI].content[contentI].elements[0].background_img"-->
                          <!--                                    class="img-fluid img-thumbnail" style="width: 15vw;height: auto" alt="...">-->
                          <!--                                </picture>-->
                          <!--                              </div>-->
                          <!--                            </div>-->
                          <!--                            <input (change)="changeBackgroundImg($event.target,[contentsI,contentI,0])" type="file"-->
                          <!--                                   class="form-control">-->

                          <!--                          </div>-->

                          <!--up-->
                          <div *ngIf="content.content_type=='inputadresse' && content.content_name=='sections'">
                            <hr>
                            <div class="form-group">
                              <p>Sections : </p>
                              <div class="row gx-3">
                                <ng-container *ngFor="let element of content.elements;let elementI = index;">
                                  <div class="col-6 mb-1">

                                    <input type="email" class="form-control" [(ngModel)]="element.text_1"
                                      placeholder="Tapez le nouveau titre section">
                                  </div>


                                </ng-container>
                              </div>
                            </div>
                          </div>

                          <div *ngIf="content.content_type=='inputadresse' && content.content_name=='title'">



                            <hr>

                            <div class="form-group">
                              <div class="row gx-3 ">
                                <ng-container *ngFor="let element of content.elements;let elementI = index;">
                                  <div class="col-10 mb-2">
                                    <label>Titre de la section :</label>
                                    <br>

                                  </div>
                                  <b style="margin-left: 2px;" class="ml-1 mt-1"> {{element.text_1}} </b>

                                </ng-container>
                              </div>
                            </div>
                          </div>


                          <div *ngIf="content.content_type=='inputadresse' && content.content_name=='questions'">

                            <!-- <div *ngFor="let cont of contents.content | paginate: { itemsPerPage: 10, currentPage: p };let contentId = index;">-->


                            <div class="form-group">
                              <p>Question : </p>
                              <div class="row gx-3">
                                <ng-container *ngFor="let element of content.elements;let elementI = index;">
                                  <div class="col-8 mb-1">

                                    <input type="email" class="form-control" [(ngModel)]="element.text_1"
                                      placeholder="Tapez le nouveau titre section">
                                  </div>


                                </ng-container>
                              </div>
                            </div>
                          </div>
                          <!--    </div> -->


                          <div *ngIf="content.content_type=='paragraphes'">
                            <div class="form-group">
                              <label>Réponse :</label>
                              <angular-editor [placeholder]="'Enter text here...'"
                                [(ngModel)]="content.elements[0].text"></angular-editor>
                            </div>
                          </div>
                          <div *ngIf="content.content_type=='inputQuestions'">
                            <div class="form-group">
                              <p>Redirection : </p>
                              <div class="row gx-3">
                                <ng-container *ngFor="let element of content.elements;let elementI = index;">
                                  <div class="col-7">
                                    <input type="email" class="form-control mb-1"
                                      [(ngModel)]="content.elements[0].text_2"
                                      placeholder="Tapez le nouveau titre section">


                                  </div>

                                </ng-container>
                              </div>
                            </div>
                          </div>
                          <div *ngIf="content.content_type=='input'">
                            <hr />
                            <div class="form-group">
                              <p>Horaires : </p>
                              <div class="row gx-3">
                                <ng-container *ngFor="let element of content.elements;let elementI = index;">
                                  <div class="col-7">
                                    <input type="email" class="form-control"
                                      [(ngModel)]="fixedPageContent?.sections[selectedPageContentIndex].contents[contentsI].content[contentI].elements[elementI].input_text"
                                      placeholder="Tapez le nouveau titre section">

                                  </div>
                                  <div class="row ml-1 mt-2">
                                    <input type="email" class=" col-7 form-control"
                                      [(ngModel)]="fixedPageContent?.sections[selectedPageContentIndex].contents[contentsI].content[contentI].elements[elementI].jours1"
                                      placeholder="Tapez le nouveau titre section">
                                    <input type="email" class="ml-1 col-3 form-control"
                                      [(ngModel)]="fixedPageContent?.sections[selectedPageContentIndex].contents[contentsI].content[contentI].elements[elementI].heures1"
                                      placeholder="Tapez le nouveau titre section">
                                  </div>
                                  <div class="row ml-1 mt-2">
                                    <input type="email" class=" col-7 form-control"
                                      [(ngModel)]="fixedPageContent?.sections[selectedPageContentIndex].contents[contentsI].content[contentI].elements[elementI].jours2"
                                      placeholder="Tapez le nouveau titre section">
                                    <input type="email" class="ml-1 col-3 form-control"
                                      [(ngModel)]="fixedPageContent?.sections[selectedPageContentIndex].contents[contentsI].content[contentI].elements[elementI].heures2"
                                      placeholder="Tapez le nouveau titre section">
                                  </div>
                                </ng-container>
                              </div>
                            </div>
                          </div>



                          <div *ngIf="content.content_type=='buttons'">
                            <hr />
                            <div class="form-group">
                              <p>Boutons : </p>
                              <div class="row gx-3">
                                <ng-container *ngFor="let element of content.elements;let elementI = index;">
                                  <div class="col-3">
                                    <button class="btn btn-admin current" style="text-transform: capitalize">
                                      <small>{{fixedPageContent?.sections[selectedPageContentIndex].contents[contentsI].content[contentI].elements[elementI].btn_text}}</small>
                                      <input class="border" [(ngModel)]="element.btn_text"
                                        style="padding: 7px;transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;box-shadow: none;height: auto;width: 100%;background-color: white !important;border: 1px solid #E3E3E3;border-radius: 30px;color: #2c2c2c;line-height: normal;font-size: .8571em;position: relative;"
                                        type="text" placeholder="Button Text" />
                                    </button>
                                    <div class="form-group">
                                      <label>Redirection:
                                        <small>{{fixedPageContent?.sections[selectedPageContentIndex].contents[contentsI].content[contentI].elements[elementI].btn_redirection}}</small></label>
                                      <input type="text" class="form-control" placeholder="Button Text"
                                        [(ngModel)]="element.btn_text"
                                        style="width: 100%;transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;box-shadow: none;height: auto;background-color: rgba(0,0,0,0);border: 1px solid #E3E3E3;border-radius: 30px;color: #2c2c2c;line-height: normal;font-size: .8571em;position: relative;" />
                                    </div>
                                  </div>
                                </ng-container>
                              </div>
                              <!--                              <div class="row btn-row mb-4" style="width: 70%;float: initial">-->
                              <!--                                <ng-container class="col-4"-->
                              <!--                                              *ngFor="let element of content.elements;let elementI = index">-->
                              <!--                                  <div class="col-4">-->

                              <!--                                    <p class="mt-2 mb-1">button:</p>-->
                              <!--                                    <button class="validate-btn"-->
                              <!--                                            style="border-radius: 10px">-->
                              <!--                                      <div class="col">-->
                              <!--                                        <div class="row">-->
                              <!--                                          <small>{{fixedPageContent.sections[selectedPageContentIndex].contents[contentsI].content[contentI].elements[elementI].btn_text}}</small>-->
                              <!--                                        </div>-->
                              <!--                                        <div class="row" style="right: 0;left: 0; margin: auto">-->
                              <!--                                        </div>-->
                              <!--                                      </div>-->
                              <!--                                    </button>-->
                              <!--                                    <div class="form-group">-->
                              <!--                                      <label>Redirection:-->
                              <!--                                        <small>{{fixedPageContent.sections[selectedPageContentIndex].contents[contentsI].content[contentI].elements[elementI].btn_redirection}}</small></label>-->
                              <!--                                      <input type="text" class="form-control" placeholder="Button Text"-->
                              <!--                                             [(ngModel)]="element.btn_redirection"-->
                              <!--                                             style="transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;box-shadow: none;height: auto;background-color: rgba(0,0,0,0);border: 1px solid #E3E3E3;border-radius: 30px;color: #2c2c2c;line-height: normal;font-size: .8571em;position: relative;"/>-->
                              <!--                                    </div>-->
                              <!--                                  </div>-->
                              <!--                                </ng-container>-->
                              <!--                              </div>-->
                            </div>
                          </div>



                          <div *ngIf="content.content_type=='slider' && content.content_name=='slider_equipe'">
                            <hr />
                            <p>Sliders : </p>
                            <div *ngFor="let element of content.elements; let elemI = index">
                              <div>


                                <div class="form-group">

                                  <div class="row card mb-4" style="width: 70%;float: initial">
                                    <ng-container class="pb-3">
                                      <p>Slider :{{elemI + 1}} </p>
                                      <div class="form-group">
                                        <label>Image :</label>
                                        <div class="d-flex">

                                          <picture>
                                            <img
                                              [src]="fixedPageContent?.sections[selectedPageContentIndex].contents[contentsI].content[contentI].elements[elemI].background_img"
                                              class="img-fluid img-thumbnail" style="width: 15vw;height: auto; "
                                              alt="...">
                                          </picture>
                                        </div>
                                      </div>
                                      <input (change)="changeBackgroundImg($event.target,[contentsI,contentI,elemI])"
                                        type="file" class="form-control">

                                      <div class="col-8">
                                        <div class="form-group">
                                          <label>Redirection:
                                            <small></small></label>
                                          <input type="text" class="form-control" placeholder="Button Text" value=""
                                            style="transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;box-shadow: none;height: auto;background-color: rgba(0,0,0,0);border: 1px solid #E3E3E3;border-radius: 30px;color: #2c2c2c;line-height: normal;font-size: .8571em;position: relative;" />
                                        </div>
                                      </div>
                                    </ng-container>
                                  </div>
                                </div>





                              </div>
                            </div>
                          </div>



                          <div class="d-flex justify-content-center" style="padding-top:1% ;">

                          </div>
                          <div *ngIf="content.content_type=='images'">
                            <hr />
                            <div class="form-group">
                              <p>Images : </p>
                              <div class="row btn-row mb-4" style="width: 70%;float: initial">
                                <ng-container class="col-4"
                                  *ngFor="let element of content.elements;let elementI = index">
                                  <div class="form-group">
                                    <label>Image : {{elementI + 1}}</label>
                                    <div class="d-flex"><label>Arrière plan : </label>
                                      <picture>
                                        <img
                                          [src]="fixedPageContent?.sections[selectedPageContentIndex].contents[contentsI].content[contentI].elements[elementI].img"
                                          class="img-fluid img-thumbnail" style="width: 15vw;height: auto" alt="...">
                                      </picture>
                                    </div>
                                  </div>
                                  <input (change)="changeImg($event.target,[contentsI,contentI,elementI])" type="file"
                                    class="form-control">

                                  <div class="col-8">
                                    <div class="form-group">
                                      <label>Redirection:
                                        <small>{{fixedPageContent?.sections[selectedPageContentIndex].contents[contentsI].content[contentI].elements[elementI].url}}</small></label>
                                      <input type="text" class="form-control" placeholder="Button Text"
                                        [(ngModel)]="element.url"
                                        style="transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;box-shadow: none;height: auto;background-color: rgba(0,0,0,0);border: 1px solid #E3E3E3;border-radius: 30px;color: #2c2c2c;line-height: normal;font-size: .8571em;position: relative;" />
                                    </div>
                                  </div>
                                </ng-container>
                              </div>
                            </div>
                          </div>



                        </ng-container>
                      </ng-container>
                      <!--    <pagination-controls (pageChange)="p = $event"></pagination-controls>-->

                      <div class="row btn-row">
                        <div class="col-6">
                          <button class="annulation-btn" type="button">Annuler
                          </button>
                        </div>
                        <div class="col-6">
                          <button class="validate-btn" value="submit" type="button" (click)="updateSection()"
                            [disabled]="loading">Valider
                          </button>
                        </div>
                      </div>


                      <!--                      <p><b>Titre section : </b>{{pageContent.sections[0].title}}</p>-->
                      <!--                      <p><b>Arrière plan section : </b>{{pageContent.sections[0].background}}</p>-->
                      <!--                      <ng-container *ngFor="let contents of pageContent.sections[0].contents; let contentsI= index">-->
                      <!--                        <div class="row btn-row mb-4" style="display: block;width: 50%;float: initial">-->
                      <!--                          <div class="col-4">-->
                      <!--                            <button class="validate-btn" style="border-radius: 10px">{{contents.language}}</button>-->
                      <!--                          </div>-->
                      <!--                        </div>-->
                      <!--                        <ng-container *ngFor="let content of contents.content">-->
                      <!--                          <div *ngIf="content.content_type=='buttons'">-->
                      <!--                            <p>Type contenu: {{content.content_type}}</p>-->
                      <!--                            <div class="row btn-row mb-4" style="width: 100%;float: initial">-->
                      <!--                              <ng-container class="col-4" *ngFor="let element of content.elements">-->
                      <!--                                <div class="col-4">-->
                      <!--                                  <p class="mt-2 mb-1">button:</p>-->
                      <!--                                  <button class="validate-btn"-->
                      <!--                                          style="border-radius: 10px">{{element.btn_text}}</button>-->
                      <!--                                  <p class="mt-2 mb-1">{{element.btn_redirection}}</p>-->
                      <!--                                </div>-->
                      <!--                                &lt;!&ndash;                                <li>Redirection: {{element.btn_redirection}}</li>&ndash;&gt;-->
                      <!--                                &lt;!&ndash;                                <li class="mt-1 mb-4">Text: {{element.btn_text}}</li>&ndash;&gt;-->
                      <!--                              </ng-container>-->
                      <!--                            </div>-->
                      <!--                          </div>-->
                      <!--                        </ng-container>-->
                      <!--                      </ng-container>-->

                      <!--                      <p><b>Titre : </b>{{cover_section[0].title}}</p>-->
                      <!--                      <p><b>Texte du bouton 1 : </b>{{cover_section[1].text_btn_1}}</p>-->
                      <!--                      <p><b>Texte du bouton 2 : </b>{{cover_section[2].text_btn_2}}</p>-->
                      <!--                      <p><b>Texte du bouton 3 : </b>{{cover_section[3].text_btn_3}}</p>-->
                      <!--                      &lt;!&ndash; <p><b>Photo : </b>test</p> &ndash;&gt;-->
                      <!--                      <br>-->
                      <!--                      <form *ngIf="cover" [formGroup]="coverForm" (ngSubmit)="update_cover()"-->
                      <!--                            enctype="multipart/form-data">-->
                      <!--                        <h5><b>Modifier le contenu actuel :</b></h5>-->
                      <!--                        <div class="form-div">-->
                      <!--                          <label for="title-cover"><b>Titre :</b>&nbsp;&nbsp;</label>-->
                      <!--                          <input type="text" name="title-cover" id="title-cover"-->
                      <!--                                 [(ngModel)]="cover.title" formControlName="title"-->
                      <!--                                 placeholder="Titre de la section"><br>-->
                      <!--                          <label for="text-btn-cover"><b>Texte du bouton-->
                      <!--                            1:</b>&nbsp;&nbsp;</label>-->
                      <!--                          <input type="text" name="text_btn_1" id="text-btn-cover"-->
                      <!--                                 [(ngModel)]="cover.text_btn_1" formControlName="text_btn_1"-->
                      <!--                                 placeholder="Texte 1"><br>-->
                      <!--                          <label for="text-btn-cover"><b>Texte du bouton-->
                      <!--                            2:</b>&nbsp;&nbsp;</label>-->
                      <!--                          <input type="text" name="text_btn_2" id="text-btn-cover"-->
                      <!--                                 [(ngModel)]="cover.text_btn_2" formControlName="text_btn_2"-->
                      <!--                                 placeholder="Texte 2"><br>-->
                      <!--                          <label for="text-btn-cover"><b>Texte du bouton-->
                      <!--                            3:</b>&nbsp;&nbsp;</label>-->
                      <!--                          <input type="text" name="text_btn_3" id="text-btn-cover"-->
                      <!--                                 [(ngModel)]="cover.text_btn_3" formControlName="text_btn_3"-->
                      <!--                                 placeholder="Texte 3">-->
                      <!--                          &lt;!&ndash; <input type="file" id="img-cover" name="" accept="image/*"> &ndash;&gt;-->
                      <!--                          &lt;!&ndash; <label for="img-cover"><b>Photo :</b>&nbsp;&nbsp;</label> &ndash;&gt;-->

                      <!--                        </div>-->
                      <!--                        <br><br><br>-->
                      <!--                        <div class="row btn-row">-->
                      <!--                          <div class="col-6">-->
                      <!--                            <button class="annulation-btn" type="button"-->
                      <!--                                    (click)="reset_coverForm()">Annuler-->
                      <!--                            </button>-->
                      <!--                          </div>-->
                      <!--                          <div class="col-6">-->
                      <!--                            <button class="validate-btn" value="submit" type="button"-->
                      <!--                                    (click)="update_cover()" id="form-submit"-->
                      <!--                                    [disabled]="loading">Valider-->
                      <!--                            </button>-->
                      <!--                          </div>-->
                      <!--                        </div>-->
                      <!--                      </form>-->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>