import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ReplacePipe } from 'src/app/pipe/replace.pipe';
import { HomePageService } from 'src/app/_shared/home-page.service';

@Component({
  selector: 'app-faq-cms',
  templateUrl: './faq-cms.component.html',
  styleUrls: ['./faq-cms.component.scss']
})
export class FaqCMSComponent implements OnInit {

  constructor(private homeService: HomePageService,
    private toastr: ToastrService,
    public replace: ReplacePipe,) { }
  loading = false;
  submitted = false;
  p: number =1;
  cont: any;
  titles: string[] = [];

  selectedPageContentIndex = 0;
  fixedPageContent: any;
  selectedPageContent: any;

  get_page_content() {
    this.homeService.get_page_content('faq').subscribe(homeContent => {
      this.fixedPageContent = homeContent;
      this.selectedPageContent = homeContent;
      
      for (let i = 0; i < this.fixedPageContent?.sections[0]?.contents[0]?.content[0].elements.length; i++) {
        this.titles.push(this.fixedPageContent?.sections[0]?.contents[0]?.content[0].elements[i].text_1)
        
      }
      
      let i=0;
      for (let j = 0; j < this.fixedPageContent?.sections[1]?.contents[0].content.length; j++) {
        if(this.fixedPageContent?.sections[1]?.contents[0].content[j].content_name == 'title'){
          
          this.fixedPageContent.sections[1].contents[0].content[j].elements[0].text_1 = this.titles[i]
          i+=1
        }
      }
      this.fixedPageContent.sections[1].section_name = 'FAQ';
      this.selectedPageContent.sections[1].section_name = this.selectedPageContent.sections[1].section_name.toUpperCase();
        //console.log(element.elements[0].text_1)

        
     
      
    });
    
  }

  
  


  changeBackgroundSection(input) {
    if(input.files[0].size < 5*1024*1024)
    {
    if (input.files && input.files[0]) {
      var reader = new FileReader();
      reader.onload = (e: any) => {
        //console.log('Got here: ', e.target.result);
        let obj = e.target.result;
        // console.log(obj)
        this.fixedPageContent.sections[this.selectedPageContentIndex].background = obj;
        this.selectedPageContent.sections[this.selectedPageContentIndex].background = obj;
      };
      reader.readAsDataURL(input.files[0]);
    }
  }else
  this.toastr.error('Image très large! merci de ne pas dépasser 5 Mo');
  }



  changeBackgroundImg(input, arrayElementIndex) {
    if(input.files[0].size < 5*1024*1024)
    {
    if (input.files && input.files[0]) {
      var reader = new FileReader();
      reader.onload = (e: any) => {
        //console.log('Got here: ', e.target.result);
        let obj = e.target.result;
        // console.log(obj)
        this.selectedPageContent.sections[this.selectedPageContentIndex].contents[arrayElementIndex[0]].content[arrayElementIndex[1]].elements[arrayElementIndex[2]].background_img = obj;
       // console.log(this.selectedPageContent.sections[this.selectedPageContentIndex].contents[arrayElementIndex[0]].content[arrayElementIndex[1]].elements[arrayElementIndex[2]])
      };
      reader.readAsDataURL(input.files[0]);
    }
  }else
  this.toastr.error('Image très large! merci de ne pas dépasser 5 Mo');
  }


  changeImg(input, arrayElementIndex) {
    if(input.files[0].size < 5*1024*1024)
    {
    if (input.files && input.files[0]) {
      var reader = new FileReader();
      reader.onload = (e: any) => {
        //console.log('Got here: ', e.target.result);
        let obj = e.target.result;
        // console.log(obj)
        this.selectedPageContent.sections[this.selectedPageContentIndex].contents[arrayElementIndex[0]].content[arrayElementIndex[1]].elements[arrayElementIndex[2]].img = obj;
      };
      reader.readAsDataURL(input.files[0]);
    }
  }else
  this.toastr.error('Image très large! merci de ne pas dépasser 5 Mo');
  }


  ngOnInit(): void {
    this.get_page_content();

      
    
    
  
    
  }
  updateSection() {
    this.submitted = true;
    this.loading = true;
    this.homeService.update_section_content('A propos', this.selectedPageContent.sections[this.selectedPageContentIndex]).subscribe((data) => {
      let str = (this.replace.transform(this.selectedPageContent.sections[this.selectedPageContentIndex].section_name, '_', ' '));
      this.toastr.success('La section ' + str + ' a été modifiée avec succès!');
      setTimeout(() => {
        window.location.reload();
      }, 1000);
      
    });
  }
}
