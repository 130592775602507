import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-preloader',
  templateUrl: './preloader.component.html',
  styleUrls: ['./preloader.component.scss']
})
export class PreloaderComponent implements OnInit {

  constructor() { }

  //time : any;
  async start(time) {
    
    
    
  }
  async remove(){
    await new Promise((f) => setTimeout(f, 0));
    const element = document.getElementById('preloader');
    element.parentNode.removeChild(element);
  }

  ngOnInit(): void {

    //this.removeLoader(this.time);
  }

}
