import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { find, pull } from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ProductsService } from 'src/app/_shared/products.service';
import { ProjectService } from 'src/app/_shared/project.service';
import { environment } from 'src/environments/environment';
const types = ["image/png", "image/png", "image/jpeg"]
declare var $: any;


@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddComponent implements OnInit {
  isloading =false
  title = 'upload-image';
  filesPath = ['','','',''];
  unumber:any;
  uploadUrl = `${environment.uploadUrl}`

  categories = [
    { id: 1, name: "NOUVEAUTÉS" },
    { id: 2, name: "PROJETS" },
    { id: 3, name: "SHOWROOM" },
    { id: 4, name: "ÉVÉNEMENTS" },
    { id: 5, name: "INFORMATIONS" },
    { id: 6, name: "PRODUITS" },
  ];

  fixedProjectStructure = {
    sections: [
      {
        hide: false,
        section_name: 'couverture',
        title: '',
        background: '',
        contents: [
          {
            language: 'fr',
            content: [
              {
                elements: [
                  {
                    title: ''
                  }
                ],
                content_name: 'title',
                content_type: 'title'
              },
              {
                elements: [
                  {
                    description: ''
                  }
                ],
                content_name: 'description',
                content_type: 'description'
              },
              {
                elements: [
                  {
                    btn_text: 'Découvrir'
                  }
                ],
                content_name: 'buttons',
                content_type: 'buttons'
              }
            ]
          }
        ]
      },
      {
        hide: true,
        section_name: 'section 2',
        title: 'section 2',
        background: '',
        category: '',
        contents: [
          {
            language: 'fr',
            content: [
              {
                elements: [
                  {
                    description: ''
                  }
                ],
                content_name: 'paragraphes',
                content_type: 'paragraphes'
              },
              {
                elements: [
                  {
                    img: ''
                  }
                ],
                content_name: 'background_section',
                content_type: 'image'
              },
              {
                elements: [
                  {
                    description: ''
                  },
                  {
                    description: ''
                  }
                ],
                content_name: 'paragraphes',
                content_type: 'paragraphes'
              },
            ]
          }
        ]
      },
      {
        hide: true,
        section_name: 'section 3',
        title: 'section 3',
        background: '',
        category: '',
        contents: [
          {
            language: 'fr',
            content: [
              {
                elements: [
                  {
                    title: ''
                  }
                ],
                content_name: 'title',
                content_type: 'title'
              },
              {
                elements: [
                  {
                    img: ''
                  }
                ],
                content_name: 'img_card',
                content_type: 'image'
              },
              {
                elements: [
                  {
                    description: ''
                  }
                ],
                content_name: 'paragraphes',
                content_type: 'paragraphes'
              },
              {
                elements: [
                  {
                    btn_text: 'Nos projet',
                    btn_redirection: '/projets'
                  },
                  {
                    btn_text: 'Nos Produits',
                    btn_redirection: '/produits'
                  }
                ],
                content_name: 'buttons',
                content_type: 'buttons'
              },
              {
                elements: [
                  {
                    background_img: ''
                  }
                ],
                content_name: 'background_right_section',
                content_type: 'image'
              }
            ]
          }
        ]
      },
      {
        hide: true,
        section_name: 'section 4',
        title: 'section 4',
        background: '',
        category: '',
        contents: [
          {
            language: 'fr',
            content: [
              {
                elements: [
                  {
                    title: ''
                  }
                ],
                content_name: 'title',
                content_type: 'title'
              },
              {
                elements: [
                  {
                    img: ''
                  }
                ],
                content_name: 'img_card',
                content_type: 'image'
              },
              {
                elements: [
                  {
                    description: ''
                  }
                ],
                content_name: 'paragraphes',
                content_type: 'paragraphes'
              }
            ]
          }
        ]
      },
      {
        hide: false,
        section_name: 'SEO',
        title: 'SEO',
        contents: [
          {
            language: 'fr',
            content: [
              {
                elements: [
                  {
                    description: ''
                  }
                ],
                content_name: 'paragraphes',
                content_type: 'paragraphes'
              },
              {
                elements: [
                  {
                    keywords: []
                  }
                ],
                content_name: 'keywords',
                content_type: 'keywords'
              }
            ]
          }
        ]
      },
    ],
    page_url: '',
    page_name: '',
    description: '',
    hide_page: false,
    deleted_page: false,
    page_type: 'actualités',
    category: 'NOUVEAUTÉS',
    background: ''
  };
  projectStructure = {
    sections: [
      {
        hide: false,
        section_name: 'couverture',
        title: '',
        background: '',
        contents: [
          {
            language: 'fr',
            content: [
              {
                elements: [
                  {
                    title: ''
                  }
                ],
                content_name: 'title',
                content_type: 'title'
              },
              {
                elements: [
                  {
                    description: ''
                  }
                ],
                content_name: 'description',
                content_type: 'description'
              },
              {
                elements: [
                  {
                    btn_text: 'Découvrir'
                  }
                ],
                content_name: 'buttons',
                content_type: 'buttons'
              }
            ]
          }
        ]
      },
      {
        hide: true,
        section_name: 'section 2',
        title: 'section 2',
        background: '',
        category: '',
        contents: [
          {
            language: 'fr',
            content: [
              {
                elements: [
                  {
                    description: ''
                  }
                ],
                content_name: 'paragraphes',
                content_type: 'paragraphes'
              },
              {
                elements: [
                  {
                    img: ''
                  }
                ],
                content_name: 'background_section',
                content_type: 'image'
              },
              {
                elements: [
                  {
                    description: ''
                  },
                  {
                    description: ''
                  }
                ],
                content_name: 'paragraphes',
                content_type: 'paragraphes'
              },
            ]
          }
        ]
      },
      {
        hide: true,
        section_name: 'section 3',
        title: 'section 3',
        background: '',
        category: '',
        contents: [
          {
            language: 'fr',
            content: [
              {
                elements: [
                  {
                    title: ''
                  }
                ],
                content_name: 'title',
                content_type: 'title'
              },
              {
                elements: [
                  {
                    img: ''
                  }
                ],
                content_name: 'img_card',
                content_type: 'image'
              },
              {
                elements: [
                  {
                    description: ''
                  }
                ],
                content_name: 'paragraphes',
                content_type: 'paragraphes'
              },
              {
                elements: [
                  {
                    btn_text: 'Nos projet',
                    btn_redirection: '/projets'
                  },
                  {
                    btn_text: 'Nos Produits',
                    btn_redirection: '/produits'
                  }
                ],
                content_name: 'buttons',
                content_type: 'buttons'
              },
              {
                elements: [
                  {
                    background_img: ''
                  }
                ],
                content_name: 'background_right_section',
                content_type: 'image'
              }
            ]
          }
        ]
      },
      {
        hide: true,
        section_name: 'section 4',
        title: 'section 4',
        background: '',
        category: '',
        contents: [
          {
            language: 'fr',
            content: [
              {
                elements: [
                  {
                    title: ''
                  }
                ],
                content_name: 'title',
                content_type: 'title'
              },
              {
                elements: [
                  {
                    img: ''
                  }
                ],
                content_name: 'img_card',
                content_type: 'image'
              },
              {
                elements: [
                  {
                    description: ''
                  }
                ],
                content_name: 'paragraphes',
                content_type: 'paragraphes'
              },
            ]
          }
        ]
      },
      {
        hide: false,
        section_name: 'SEO',
        title: 'SEO',
        contents: [
          {
            language: 'fr',
            content: [
              {
                elements: [
                  {
                    description: ''
                  }
                ],
                content_name: 'paragraphes',
                content_type: 'paragraphes'
              },
              {
                elements: [
                  {
                    keywords: []
                  }
                ],
                content_name: 'keywords',
                content_type: 'keywords'
              }
            ]
          }
        ]
      }
    ],
    page_url: '',
    page_name: '',
    description: '',
    hide_page: false,
    deleted_page: false,
    page_type: 'actualités',
    category: 'NOUVEAUTÉS',
    background: '',

  };

  products: any;
  fixedProduct: any;
  // searchproduct: string;
  id: any;
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ],
    toolbarHiddenButtons: [

      [
        'backgroundColor',
        'customClasses',
        'link',
        'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
      ]
    ]
  };

  @ViewChild('tagInput') tagInputRef: ElementRef;
  form: FormGroup;
  BackgroundImage: any;
  sectionImage2: any;
  sectionImage3: any;
  sectionImage4: any;
  checked: boolean;
  NetUrl: String;
  checkedURL: boolean;

  constructor(private spinner: NgxSpinnerService,
    private fb: FormBuilder, private toastr: ToastrService,
    private productService: ProductsService,
    private actRoute: ActivatedRoute,
    private projectService: ProjectService,
    private router: Router) {
    this.id = this.actRoute.snapshot.paramMap.get('id');
  }

  ngOnInit(): void {
    this.productService.get_all_product().subscribe(data => {
      this.fixedProduct = data;

    });
    this.form = this.fb.group({
      tag: [undefined],
    });
  }

  focusTagInput(): void {
    this.tagInputRef.nativeElement.focus();
  }

 
  onKeyUp(event: KeyboardEvent): void {
    const inputValue: string = this.form.controls.tag.value;
    if (event.code === 'Backspace' && !inputValue) {
      this.removeTag();
      return;
    } else {
      if (event.code === 'Comma' || event.code === 'Space') {
        this.addTag(inputValue);
        this.form.controls.tag.setValue('');
      }
    }
  }

  addTag(tag: string): void {
    if (tag[tag.length - 1] === ',' || tag[tag.length - 1] === ' ') {
      tag = tag.slice(0, -1);
    }
    if (tag.length > 0 && !find(this.projectStructure.sections[4].contents[0].content[1].elements[0]['keywords'], tag)) {
      this.projectStructure.sections[4].contents[0].content[1].elements[0]['keywords'].push(tag);
    }
  }

  removeTag(tag?: string): void {
    if (!!tag) {
      pull(this.projectStructure.sections[4].contents[0].content[1].elements[0]['keywords'], tag);
    } else {
      this.projectStructure.sections[4].contents[0].content[1].elements[0]['keywords'].splice(-1);
    }
  }




  // addGalleryImg() {
  //   // @ts-ignore
  //   this.projectStructure.sections[3].contents[0].content[2].elements.push({img: ''});
  // }

  /**
   * READER FOR SECTION 1
   */
  changeBackgroundSection(input) {
    if (input.files[0] != undefined) {
      if(types.indexOf(input.files[0].type) == -1){
        this.toastr.error('L\’image doit être de type png, jpg ou jpeg.')
        $("#photofrsec1").val('')
        this.filesPath[0] =''
        this.BackgroundImage = this.uploadUrl + "$2b$10$TnB0yYcAWl3fUazGEfLxA.png";
        this.projectStructure.background = "";

        return;
      }
      if (input.files[0].size < 10 * 1024 * 1024) {
        if (input.files && input.files[0]) {
          this.filesPath[0] = input.files[0]
          this.projectStructure.background = input.files[0].name;
          const reader = new FileReader();
          reader.onload = (e: any) => {
            const obj = e.target.result;
            this.BackgroundImage = obj
          };
          reader.readAsDataURL(input.files[0]);
        }
      } else
        this.toastr.error('Image très large! merci de ne pas dépasser 10 Mo');
    } else {
      this.filesPath[0] =''
      this.BackgroundImage = this.uploadUrl + "$2b$10$TnB0yYcAWl3fUazGEfLxA.png";
      this.projectStructure.background = "";
    }


  }

  addImg(input, arrayElementIndex, section_number) {
    if(input.files[0] != undefined){
      if(types.indexOf(input.files[0].type) == -1){
        this.toastr.error('L\’image doit être de type png, jpg ou jpeg.')
        if(section_number == 2)
        {$("#photofrsec2").val('')
        this.filesPath[1] =''

        this.sectionImage2 = this.uploadUrl +"$2b$10$TnB0yYcAWl3fUazGEfLxA.png";
        this.projectStructure.sections[arrayElementIndex[0]].contents[arrayElementIndex[1]].content[arrayElementIndex[2]].elements[arrayElementIndex[3]]['img'] = "";
      }
        if(section_number == 3)
        {$("#photofrsec3").val('');
        this.filesPath[2] =''

        this.sectionImage3 = this.uploadUrl +"$2b$10$TnB0yYcAWl3fUazGEfLxA.png";
        this.projectStructure.sections[arrayElementIndex[0]].contents[arrayElementIndex[1]].content[arrayElementIndex[2]].elements[arrayElementIndex[3]]['img'] = "";

      }

        if(section_number == 4)
        {$("#photofrsec4").val('')
        this.filesPath[3] =''

        this.sectionImage4 = this.uploadUrl +"$2b$10$TnB0yYcAWl3fUazGEfLxA.png";
        this.projectStructure.sections[arrayElementIndex[0]].contents[arrayElementIndex[1]].content[arrayElementIndex[2]].elements[arrayElementIndex[3]]['img'] = "";
}
        return;
      }
      if (input.files[0].size < 10 * 1024 * 1024) {
        if (input.files && input.files[0]) {
          const reader = new FileReader();
        reader.onload = (e: any) => {
          const obj = e.target.result;
          
      
          
          if(section_number == 2){
            this.sectionImage2 = obj
          } if (section_number == 3){
            this.sectionImage3 = obj

          } if(section_number == 4){
            this.sectionImage4 = obj

          }
        };
        reader.readAsDataURL(input.files[0]);
          this.filesPath[Number(section_number)-1]=  input.files[0]

          this.projectStructure.sections[arrayElementIndex[0]].contents[arrayElementIndex[1]].content[arrayElementIndex[2]].elements[arrayElementIndex[3]]['img'] = input.files[0].name;
        }
      } else
        this.toastr.error('Image très large! merci de ne pas dépasser 10 Mo');
      // ANNULER 
    }else{
      this.filesPath[Number(section_number)-1] = '';
      
      // Invalid  image
      if(section_number == 2){
        this.filesPath[1] =''

        this.sectionImage2 = this.uploadUrl +"$2b$10$TnB0yYcAWl3fUazGEfLxA.png";
        this.projectStructure.sections[arrayElementIndex[0]].contents[arrayElementIndex[1]].content[arrayElementIndex[2]].elements[arrayElementIndex[3]]['img'] = "";
      } if (section_number == 3){
        this.filesPath[2] =''

        this.sectionImage3 = this.uploadUrl +"$2b$10$TnB0yYcAWl3fUazGEfLxA.png";
        this.projectStructure.sections[arrayElementIndex[0]].contents[arrayElementIndex[1]].content[arrayElementIndex[2]].elements[arrayElementIndex[3]]['img'] = "";


      } if(section_number == 4){
        this.filesPath[3] =''

        this.sectionImage4 = this.uploadUrl +"$2b$10$TnB0yYcAWl3fUazGEfLxA.png";
        this.projectStructure.sections[arrayElementIndex[0]].contents[arrayElementIndex[1]].content[arrayElementIndex[2]].elements[arrayElementIndex[3]]['img'] = "";


      }
    }
    
  }

  transformChar() {
    let titleToUdapte = this.projectStructure.page_name
    titleToUdapte = titleToUdapte.replace(/\W+(?!$)/g, '-').toLowerCase();
    titleToUdapte = titleToUdapte.replace(/\W$/, '').toLowerCase();

    this.NetUrl=titleToUdapte;
    this.projectStructure.page_url = '/actualites/' + titleToUdapte;

  }
  generateUrlPoject() {
    this.transformChar();
    this.projectStructure.page_url = (this.projectStructure.page_url[this.projectStructure.page_url.length - 1] == '_') ? this.projectStructure.page_url.slice(0, -1) : this.projectStructure.page_url;
  }

  initname(){
    this.unumber =  new Date().getTime();
    this.projectStructure.background= this.unumber+"_Section_0.png";
    // Section 1
    if(this.projectStructure.sections[1].contents[0].content[1].elements[0]['img']){
      this.projectStructure.sections[1].contents[0].content[1].elements[0]['img'] = this.unumber+"_Section_1.png";

    }

    // Section 2
    if(this.projectStructure.sections[2].contents[0].content[2].elements[0]['img']){
      this.projectStructure.sections[2].contents[0].content[2].elements[0]['img'] = this.unumber+"_Section_2.png";

    }

    // Section 3
    if(this.projectStructure.sections[3].contents[0].content[2].elements[0]['img']){
      this.projectStructure.sections[3].contents[0].content[2].elements[0]['img'] = this.unumber+"_Section_3.png";
    }

  }
  async submit() {
    this.checkExist(); 
    let validProject = true;

    
    if (this.projectStructure.page_name == '') {
      validProject = false;
      this.toastr.error('Ajouter un titre')
      return;
    }
    if (this.projectStructure.page_url.length < 7) {
      validProject = false;
      this.toastr.error('L\'Url doit avoir 7 caractéres')
      return;
    }
    if (this.projectStructure.background == '') {
      this.toastr.error('Ajouter une photo  de couverture')
      validProject = false;
      return;
    }
    if(this.checked){
      validProject = false;
      this.toastr.error('Le titre de l\'actualité est déja utilisé.');      
      return;
    }
    if(this.checkedURL){
      validProject = false;
      this.toastr.error('L\'Url de l\'actualité est conforme à une URL déja utilisé.');
      

      return;
    }
    if (validProject) {
      this.initname();

        this.isloading =true
        const formData: FormData = new FormData();
        var newFile : any ;
      for (let index = 0; index < this.filesPath.length; index++) {
        var name = this.unumber+"_Section_"+index+'.png';
         newFile = new File([this.filesPath[index]], name, {type: this.filesPath[index]['type']});
        formData.append('files', newFile);
      }
       await this.projectService.uploadImagesNews(formData).subscribe(res => {
        if(res['response'] == "OK"){
                let urlToSavedInBd = this.projectStructure.page_url.split("/")[2];
          if(urlToSavedInBd[(urlToSavedInBd.length)-1] == "-")
          {
            
            urlToSavedInBd =urlToSavedInBd.slice(0,(urlToSavedInBd.length)-1)
          }
          this.projectStructure.page_url=urlToSavedInBd
          this.projectService.add_project(this.projectStructure).subscribe(data => {

            this.projectStructure = this.fixedProjectStructure;
            let idUser =localStorage.getItem("user")
            localStorage.setItem("reload","1")
            setTimeout(() => {              
              this.router.navigate(['user-profile/' + idUser + '/actualités']);
              this.isloading = false;
            }, );
          });
        }else{
          this.isloading = false;
          this.toastr.error('Une erreur lors du téléchargement des photos');
        }
        },(err)=>{
          this.isloading = false;
          this.toastr.error('Une erreur lors du téléchargement des photos');
        })
       
      
        //   let urlToSavedInBd = this.projectStructure.page_url.split("/")[2];
        //   if(urlToSavedInBd[(urlToSavedInBd.length)-1] == "-")
        //   {
            
        //     urlToSavedInBd =urlToSavedInBd.slice(0,(urlToSavedInBd.length)-1)
        //   }
        //   this.projectStructure.page_url=urlToSavedInBd
        //  await this.projectService.add_project(this.projectStructure).subscribe(data => {

        //     this.projectStructure = this.fixedProjectStructure;
        //     let idUser =localStorage.getItem("user")
        //     localStorage.setItem("reload","1")
        //     setTimeout(() => {
              
              
              
        //       this.router.navigate(['user-profile/' + idUser + '/actualités'])
        //     }, );
        //   });
        


      
    


    }
  }

  generateBannerTitle() {
    this.projectStructure.sections[0].title = this.projectStructure.page_name;
    this.projectStructure.sections[0].contents[0].content[0].elements[0]['title'] = this.projectStructure.page_name;
  }

  navigateListProject() {
    this.router.navigate(['user-profile/' + this.id + '/actualités']);
  }

  removeProduct(indexprd: number) {
    this.projectStructure.sections[5].contents[0].content[1].elements.splice(indexprd, 1);
  }

  // verfication existance url & titre 
  checkExist(){
    if(this.projectStructure.page_name){
      let k
      k =this.projectStructure.page_name[0]
    if(k == "/")
    this.projectStructure.page_name = "_" +this.projectStructure.page_name.slice(1)
    this.projectService.check_actualite_existance(this.projectStructure.page_name).subscribe(resultat =>{

      if(resultat['message'] == "Exist"){
        this.checked =true;
      }
      else{
        this.checked= false;
      }
    })
  }else{
    this.checked= false
  }
  }

  checkUrlExist(){
    if(this.projectStructure.page_url  && this.projectStructure.page_url != null){

    
    this.projectService.check_Url_actualite_existance(this.NetUrl).subscribe((checking)=>{
      if(checking['message'] == "Exist"){
        this.checkedURL =true;
      }
      else{
        this.checkedURL= false;
      }
    })
  }else {
    this.checkedURL= false;
  }
  }

}
