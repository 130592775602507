<div class="cadre_scroll">
  <section class="content ecommerce-page">
    
    <div class="block-header">
      <div class="row">
        <div class="col-lg-7 col-md-6 col-sm-12">
          <h2>Liste des projets
            <small class="text-muted">Bienvenue au Backoffice de Plaisir du Jardin</small>
          </h2>
        </div>
      <!--  <div class="col-lg-5 col-md-6 col-sm-12">
          <ul class="breadcrumb float-md-right">
            <li class="breadcrumb-item"><a routerLink="../../"><i class="zmdi zmdi-home"></i>
              Backoffice</a>
            </li>
            <li class="breadcrumb-item"><a>Projets</a></li>
            <li class="breadcrumb-item active">Liste des projets</li>
          </ul>
        </div> -->
      </div>
    </div>
    <div class="container-fluid">
      <div class="row clearfix">
        <div class="col-lg-12">
          <div class="card">
            <div class="body">
              <app-preloader></app-preloader>
              <div class="row">
                <div class="container-filter__wrapper-bottom">
                  <form class="filters flex-content col-12">

                  <!--  <div class="col-lg-3">
                      <div class="search-wrapper">
                        <input class="search-input"
                               type="text" name="search"
                               placeholder="rechercher" autocomplete="off">

                      </div>
                    </div> -->
                  </form>
                </div>
                <!--                <div class="row m-2">-->
                <!--                  <div class="col-lg-3">-->
                <!--                    <input type="checkbox" (click)="displayingDeletedProjects()"/><small>Afficher les projets-->
                <!--                    supprimer</small>-->
                <!--                  </div>-->
                <!--                </div>-->
                <div class="col-lg-12 col-12 project-listing">
                  <div class="row-project">
                    <ng-container *ngFor="let project of projectslist">
                      <div class="project-listing__item col-lg-4 col-md-12" *ngIf="project.deleted_page == false">
                        <!--                      *ngIf="(showDeletedProject == true && project.deleted_page == true) || project.deleted_page == false"-->
                        <a>
                          <div class="project-listing__item-cover-wrapper">
                            <div class="project-listing__item-cover"
                                 style="background-image: url({{uploadUrl}}{{project.background}});">
                            </div>
                            <div class="content | flex-content flex-content--column">
                              <h3>{{project.page_name}}</h3>
                              <div>
                                <div class="cta margin-top-small" *ngIf="project.deleted_page == false">
                                  <i class="zmdi zmdi-delete mr-3" data-toggle="tooltip" data-placement="top"
                                     title="Supprimer" style="cursor:pointer;" data-toggle="modal"
                                     data-target="#addProductModal"
                                     (click)="projectToDelete = project"></i>
                                  <i
                                    [routerLink]="['/user-profile','623884b56d30c01c241d1703','projet',project._id,'modifier']"
                                    class="zmdi zmdi-edit mr-3" data-toggle="tooltip" data-placement="top"
                                    title="Modifier"></i>
                                  <i *ngIf="!project.hide_page" class="zmdi zmdi-eye" data-toggle="tooltip"
                                     data-placement="top" title="Masquer" style="cursor:pointer;"
                                     (click)="updateDisplayProject(project)"></i>
                                  <i *ngIf="project.hide_page" class="zmdi zmdi-eye-off" data-toggle="tooltip"
                                     data-placement="top" title="Afficher" style="cursor:pointer;"
                                     (click)="updateDisplayProject(project)"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

<!-- Modal -->
<div class="modal fade" id="addProductModal" tabindex="-1" role="dialog"
     aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Êtes vous sûr(e) de vouloir supprimer ce projet? </h5>
        <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
       </button>-->
      </div>
      <div class="modal-body">
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="projectToDelete = ''">Annuler
        </button>
        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="deleteDisplayProject()">Supprimer</button>
      </div>
    </div>
  </div>
</div>
