import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { find, pull } from "lodash";
import { ToastrService } from "ngx-toastr";
import { HomePageService } from "src/app/_shared/home-page.service";
import { environment } from "src/environments/environment";
import { ProductsService } from "../../../../_shared/products.service";
import { ProjectService } from "../../../../_shared/project.service";
import { async } from "rxjs/internal/scheduler/async";
const types = ["image/png", "image/png", "image/jpeg"];

@Component({
  selector: "app-update",
  templateUrl: "./update.component.html",
  styleUrls: ["./update.component.scss"],
})
export class UpdateComponent implements OnInit {
  result;
  title = "upload-image";
  filesPath = ["", "", "", ""];
  uploadUrl = `${environment.uploadUrl}`;

  categories = [
    { id: 1, name: "NOUVEAUTÉS" },
    { id: 2, name: "PROJETS" },
    { id: 3, name: "SHOWROOM" },
    { id: 4, name: "ÉVÉNEMENTS" },
    { id: 5, name: "INFORMATIONS" },
    { id: 6, name: "PRODUITS" },
  ];

  fixedProjectStructure: any;
  firstBackgroundadded: any;
  projectStructure: any;
  products: any;
  fixedProduct: any;
  searchproduct: string;
  projectId: any;
  id: any;
  @ViewChild("tagInput") tagInputRef: ElementRef;
  form: FormGroup;
  selectedPageContent: any;
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    placeholder: "Enter text here...",
    translate: "no",
    defaultParagraphSeparator: "p",
    defaultFontName: "Arial",
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: "redText",
        class: "redText",
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ],
    toolbarHiddenButtons: [
      [
        //'fontSize',
        //'textColor',
        "backgroundColor",
        "customClasses",
        "link",
        "unlink",
        "insertImage",
        "insertVideo",
        "insertHorizontalRule",
        "removeFormat",
        //'toggleEditorMode'
      ],
    ],
  };
  checked: boolean;
  checkedURL: boolean;
  NetUrl: any;
  BackgroundImage: string;
  ImageSection2: any;
  ImageSection3: any;
  ImageSection4: any;
  DeletedSection2Image: boolean;
  DeletedSection3Image: boolean;
  DeletedSection4Image: boolean;
  idUser;
  isloading: boolean = false;
  haskey: boolean = false;
  keySave: any;
  unumber
  constructor(
    private toastr: ToastrService,
    private productService: ProductsService,
    private actRoute: ActivatedRoute,
    private projectService: ProjectService,
    private router: Router,
    private fb: FormBuilder,
    private homeService: HomePageService
  ) {
    this.projectId = this.actRoute.snapshot.paramMap.get("projectId");
    this.id = this.actRoute.snapshot.paramMap.get("id");
  }

  ngOnInit(): void {
    this.unumber = this.projectId
    this.idUser = localStorage.getItem("user");
    this.projectService.get_project_by_id(this.projectId).subscribe((data) => {
      this.fixedProjectStructure = data;
      this.BackgroundImage =
        this.uploadUrl + this.fixedProjectStructure.background;
      // init images
      if (
        !this.fixedProjectStructure.sections[1].contents[0].content[1]
          .elements[0]["img"] ||
        this.fixedProjectStructure.sections[1].contents[0].content[1]
          .elements[0]["img"].length == 0
      ) {
        this.ImageSection2 =
          this.uploadUrl + "$2b$10$TnB0yYcAWl3fUazGEfLxA.png";
      } else {
        this.ImageSection2 =
          this.uploadUrl +
          this.fixedProjectStructure.sections[1].contents[0].content[1]
            .elements[0]["img"];
      }

      if (
        !this.fixedProjectStructure.sections[2].contents[0].content[2].elements[0]["img"] ||
        this.fixedProjectStructure.sections[2].contents[0].content[2].elements[0]["img"].length == 0
      ) {
        this.ImageSection3 =this.uploadUrl + "$2b$10$TnB0yYcAWl3fUazGEfLxA.png";
      } else {
        this.ImageSection3 =
          this.uploadUrl +
          this.fixedProjectStructure.sections[2].contents[0].content[2].elements[0]["img"];
      }

      if (
        !this.fixedProjectStructure.sections[3].contents[0].content[2].elements[0]["img"] ||
        this.fixedProjectStructure.sections[3].contents[0].content[2]
          .elements[0]["img"].length == 0
      ) {
        this.ImageSection4 =
          this.uploadUrl + "$2b$10$TnB0yYcAWl3fUazGEfLxA.png";
      } else {
        this.ImageSection4 =
          this.uploadUrl +
          this.fixedProjectStructure.sections[3].contents[0].content[2]
            .elements[0]["img"];
      }

      this.firstBackgroundadded = this.fixedProjectStructure.background;
      if (
        this.firstBackgroundadded.indexOf("_") > -1 &&
        this.firstBackgroundadded.indexOf("Section_0") > -1
      ) {
        this.haskey = true;
        this.keySave = this.firstBackgroundadded.split("_")[0];
      }

      this.projectStructure = data;
    });
    this.productService.get_all_product().subscribe((data) => {
      this.fixedProduct = data;
    });
    this.form = this.fb.group({
      tag: [undefined],
    });
    setTimeout(() => {
      this.get_page_content();
    }, 8000);
  }

  focusTagInput(): void {
    this.tagInputRef.nativeElement.focus();
  }

  onKeyUp(event: KeyboardEvent): void {
    const inputValue: string = this.form.controls.tag.value;
    if (event.code === "Backspace" && !inputValue) {
      this.removeTag();
      return;
    } else {
      if (event.code === "Comma" || event.code === "Space") {
        this.addTag(inputValue);
        this.form.controls.tag.setValue("");
      }
    }
  }

  addTag(tag: string): void {
    if (tag[tag.length - 1] === "," || tag[tag.length - 1] === " ") {
      tag = tag.slice(0, -1);
    }
    if (
      tag.length > 0 &&
      !find(
        this.projectStructure.sections[4].contents[0].content[1].elements[0][
          "keywords"
        ],
        tag
      )
    ) {
      this.projectStructure.sections[4].contents[0].content[1].elements[0][
        "keywords"
      ].push(tag);
    }
  }

  removeTag(tag?: string): void {
    if (!!tag) {
      pull(
        this.projectStructure.sections[4].contents[0].content[1].elements[0][
          "keywords"
        ],
        tag
      );
    } else {
      this.projectStructure.sections[4].contents[0].content[1].elements[0][
        "keywords"
      ].splice(-1);
    }
  }

  changeBackgroundSection(input) {
    if (input.files[0] != undefined) {
      if (types.indexOf(input.files[0].type) == -1) {
        this.toastr.error("L’image doit être de type png, jpg ou jpeg.");
        this.filesPath[0] = "";
        this.BackgroundImage =
          this.uploadUrl + this.fixedProjectStructure.background;
        this.projectStructure.background = "";

        return;
      }
      if (input.files[0].size < 10 * 1024 * 1024) {
        if (input.files && input.files[0]) {
          this.filesPath[0] = input.files[0];
          this.projectStructure.background = input.files[0].name;
          const reader = new FileReader();
          reader.onload = (e: any) => {
            const obj = e.target.result;
            this.BackgroundImage = obj;
          };
          reader.readAsDataURL(input.files[0]);
          return ;
        }
      } else
        {this.toastr.error("Image très large! merci de ne pas dépasser 10 Mo");}
    } else {
      this.filesPath[0] = "";
      this.BackgroundImage = this.uploadUrl + this.firstBackgroundadded;
      this.projectStructure.background = "";
    }
  }



  addImg(input, arrayElementIndex, section_number) {
    if (input.files[0].size < 10 * 1024 * 1024) {
      if (types.indexOf(input.files[0].type) == -1) {
        this.toastr.error("L’image doit être de type png, jpg ou jpeg.");
        return;
      }
      if (input.files && input.files[0]) {
        // this.filesPath.push(input.files[0])
        this.fixedProjectStructure.sections[arrayElementIndex[0]].contents[
          arrayElementIndex[1]
        ].content[arrayElementIndex[2]].elements[arrayElementIndex[3]]["img"] =
          input.files[0].name;
        var reader = new FileReader();
        reader.onload = (e: any) => {
          let obj = e.target.result;
          if (section_number == 2) {
            this.DeletedSection2Image = false;
            this.filesPath[1] = input.files[0];
            this.projectStructure.sections[1].contents[0].content[1].elements[0]["img"] = input.files[0].name;
            this.fixedProjectStructure.sections[1].contents[0].content[1].elements[0]["img"] = input.files[0].name;

            this.ImageSection2 = obj;
          } else if (section_number == 3) {
            this.DeletedSection3Image = false;
            this.filesPath[2] = input.files[0];
            this.projectStructure.sections[2].contents[0].content[2].elements[0]["img"] = input.files[0].name;
            this.fixedProjectStructure.sections[2].contents[0].content[2].elements[0]["img"] = input.files[0].name;

            this.ImageSection3 = obj;
          } else {
            this.DeletedSection4Image = false;
            this.filesPath[3] = input.files[0];
            this.projectStructure.sections[3].contents[0].content[2].elements[0]["img"] = input.files[0].name;
            this.fixedProjectStructure.sections[3].contents[0].content[2].elements[0]["img"] = input.files[0].name;

            this.ImageSection4 = obj;
          }
        };

        reader.readAsDataURL(input.files[0]);
      }
    } else
     { this.toastr.error("Image très large! merci de ne pas dépasser 10 Mo");}

  }

  transformChar() {
    let titleToUdapte = this.projectStructure.page_name;
    titleToUdapte = titleToUdapte.replace(/\W+(?!$)/g, "-").toLowerCase();
    titleToUdapte = titleToUdapte.replace(/\W$/, "").toLowerCase();

    this.NetUrl = titleToUdapte;
    this.projectStructure.page_url =  titleToUdapte;
  }

  generateUrlPoject() {
    this.transformChar();
    this.projectStructure.page_url =
      this.projectStructure.page_url[
        this.projectStructure.page_url.length - 1
      ] == "_"
        ? this.projectStructure.page_url.slice(0, -1)
        : this.projectStructure.page_url;
  }
  get_page_content() {
    this.homeService.get_page_content_act("home").subscribe((homeContent) => {
      this.selectedPageContent = homeContent;
    });
  }

  checkExist() {
    if (
      this.projectStructure.page_name &&
      this.projectStructure.page_name != ""
    ) {
      let k;
      k = this.projectStructure.page_name[0];
      if (k == "/")
        this.projectStructure.page_name =
          "_" + this.projectStructure.page_name.slice(1);
      this.projectService
        .check_actualite_existanceupadte(
          this.projectStructure.page_name,
          this.projectStructure._id
        )
        .subscribe((resultat) => {
          if (resultat["message"] == "Exist") {
            this.checked = true;
          } else {
            this.checked = false;
          }
        });
    } else {
      this.checked = false;
    }
  }

  checkURLExist() {
    var url = this.projectStructure.page_url
    if (url && url != "") {
      this.projectService
        .check_Urlactualite_existanceForupadte(
          this.NetUrl,
          this.projectStructure._id
        )
        .subscribe((resultat) => {
          if (resultat["message"] == "Exist") {
            this.checkedURL = true;
          } else {
            this.checkedURL = false;
          }
        });
    }
  }

  submit() {
    let validProject = true;
    if (this.projectStructure.page_name == "") {
      validProject = false;
      this.toastr.error("Ajouter un titre");
      return;
    }
    if (this.checked) {
      validProject = false;
      this.toastr.error("Le titre de l'actualité est déja utilisé.");
      return;
    }
    if (this.checkedURL) {
      validProject = false;
      this.toastr.error(
        "L'Url de l'actualité est conforme à une URL déja utilisé."
      );

      return;
    }
    // if (this.projectStructure.page_url.length < 7) {
    //   validProject = false;
    //   this.toastr.error("error has been  detected");
    //   return;
    // }
    if (this.projectStructure.background == "") {
      this.toastr.error("Ajouter une photo  de couverture");
      validProject = false;
      return;
    }
    if (validProject) {
      // if (this.haskey && this.keySave) {
      //   this.initname();
      // }
      setTimeout(async () => {
        this.isloading = true;
        if (
          this.selectedPageContent?.sections[9]?.contents[0]?.content[0]
            ?.elements[0]?.actualities.length > 0 &&
          this.selectedPageContent?.sections[9]?.contents[0]?.content[0]?.elements[0]?.actualities.findIndex(
            (obj) => obj._id == this.projectId
          ) != -1
        ) {
          let objIndex =
            this.selectedPageContent?.sections[9]?.contents[0]?.content[0]?.elements[0]?.actualities.findIndex(
              (obj) => obj._id == this.projectId
            );
          this.selectedPageContent.sections[9].contents[0].content[0].elements[0].actualities[
            objIndex
          ].page_name = this.projectStructure.page_name;
          this.selectedPageContent.sections[9].contents[0].content[0].elements[0].actualities[
            objIndex
          ].page_url = this.projectStructure.page_url;
          this.selectedPageContent.sections[9].contents[0].content[0].elements[0].actualities[
            objIndex
          ].description = this.projectStructure.description;

          this.homeService
            .update_section_content(
              "home",
              this.selectedPageContent.sections[9]
            )
            .subscribe((data) => {});
        }

        if (this.filesPath.length > 0) {
          const formData: FormData = new FormData();
          var newFile : any ;
          if (this.haskey == true) {
            this.initname();
            for (let index = 0; index < this.filesPath.length; index++) {
              if(this.filesPath[index] != ""){
              var name = this.keySave + "_Section_" + index+".png";
               newFile = new File([this.filesPath[index]], name, {
                type: this.filesPath[index]["type"],
              });
            }

              formData.append("files", newFile);

          }
             await this.projectService.uploadImagesNews(formData).subscribe((res) => {
              if(res['response'] == "OK"){
                localStorage.setItem("reload","1")
                        if(this.projectStructure.page_url[(this.projectStructure.page_url.length)-1] == "-")
                {
                  
                  this.projectStructure.page_url =this.projectStructure.page_url.slice(0,(this.projectStructure.page_url.length)-1)
                }
                this.projectService
                  .update_project(this.projectStructure)
                  .subscribe(
                    (data) => {
                      this.toastr.success(
                        "L'actualité a été modifier avec succès!"
                      );
                    },
                    (error) => {
                      if (error.status == 200) {
                        setTimeout(() => {
                          this.result = true;
                          this.toastr.success(
                            "L'actualité a été modifiée avec succès!"
                          );
                          this.router
                            .navigate([
                              "user-profile/" + "idUser" + "/actualités",
                            ])
                            .then(() => {});
                        });
                      } else {
                        this.toastr.error(
                          "L'actualité n'a pas été modifiée avec succès!"
                        );
                      }
                    }
                  );
              }else{
                this.toastr.error('Une erreur lors du téléchargement des photos');

              }
             },(err)=>{
              this.toastr.error('Une erreur lors du téléchargement des photos');

             });

              // if (index == Number(this.filesPath.length - 1)) {
               
              //   if(this.projectStructure.page_url[(this.projectStructure.page_url.length)-1] == "-")
              //   {
                  
              //     this.projectStructure.page_url =this.projectStructure.page_url.slice(0,(this.projectStructure.page_url.length)-1)
              //   }
                
             // }
            
          }
           else {
            this.initname2();
            const formData: FormData = new FormData();

            for (let index = 0; index < this.filesPath.length; index++) {
              if(this.filesPath[index] != ""){
              var name = this.unumber+"_Section_"+index+".png";
              newFile = new File([this.filesPath[index]], name, {type: this.filesPath[index]['type']});
              }
            
                formData.append('files', newFile);
                    }
              await this.projectService.uploadImagesNews(formData).subscribe((res) => { 
                if(this.projectStructure.page_url[(this.projectStructure.page_url.length)-1] == "-")
              {
                
                this.projectStructure.page_url =this.projectStructure.page_url.slice(0,(this.projectStructure.page_url.length)-1)
              }
              this.projectService
                  .update_project(this.projectStructure)
                  .subscribe(
                    (data) => {
                      this.toastr.success(
                        "L'actualité a été modifier avec succès!"
                      );
                    },
                    (error) => {
                      if (error.status == 200) {
                        setTimeout(() => {
                          this.toastr.success(
                            "L'actualité a été modifiée avec succès!"
                          );
                          this.router
                            .navigate([
                              "user-profile/" + "idUser" + "/actualités",
                            ])
                            .then(() => {});
                        });
                      } else {
                        this.toastr.error(
                          "L'actualité n'a pas été modifiée avec succès!"
                        );
                      }
                    }
                  );
            
            });
          }

               
                
              
            
          
        } else if (this.filesPath.length == 0) {
          setTimeout(() => {
            this.isloading = true;
            localStorage.setItem("reload","1")
            this.projectService.update_project(this.projectStructure).subscribe(
              (data) => {},
              (error) => {
                this.toastr.error(
                  "L'actualité n'a pas été modifiée avec succès!"
                );
              }
            );
          });
        }
      },);
    }
  }

  generateBannerTitle() {
    this.projectStructure.sections[0].title = this.projectStructure.page_name;
    this.projectStructure.sections[0].contents[0].content[0].elements[0][
      "title"
    ] = this.projectStructure.page_name;
  }

  navigateListProject() {
    this.router.navigate(["user-profile/" + this.id + "/actualités"]);
  }

  removeProduct(indexprd: number) {
    this.projectStructure.sections[5].contents[0].content[1]["elements"].splice(
      indexprd,
      1
    );
  }

  deleteImage(image, section) {
    if (section == 2) {
      this.DeletedSection2Image = true;
      this.ImageSection2 = this.uploadUrl + " $2b$10$TnB0yYcAWl3fUazGEfLxA.png";
      this.projectStructure.sections[1].contents[0].content[1].elements[0][
        "img"
      ] = "";
      this.fixedProjectStructure.sections[1].contents[0].content[1].elements[0][
        "img"
      ] = "";
    } else if (section == 3) {
      this.DeletedSection3Image = true;
      this.ImageSection3 = this.uploadUrl + " $2b$10$TnB0yYcAWl3fUazGEfLxA.png";
      this.projectStructure.sections[2].contents[0].content[2].elements[0][
        "img"
      ] = "";
      this.fixedProjectStructure.sections[2].contents[0].content[2].elements[0][
        "img"
      ] = "";
    } else {
      this.DeletedSection4Image = true;
      this.ImageSection4 = this.uploadUrl + " $2b$10$TnB0yYcAWl3fUazGEfLxA.png";
      this.projectStructure.sections[3].contents[0].content[2].elements[0][
        "img"
      ] = "";
      this.fixedProjectStructure.sections[3].contents[0].content[2].elements[0][
        "img"
      ] = "";
    }
  }
  initname() {

    if(this.filesPath[0] !=""){
    this.projectStructure.background = this.keySave + "_Section_0"+".png";
    }
    // Section 1
    if(this.filesPath[1] !=""){
    if ( this.projectStructure.sections[1].contents[0].content[1].elements[0]["img"]) {
      this.projectStructure.sections[1].contents[0].content[1].elements[0][
        "img"
      ] = this.keySave + "_Section_1"+".png";
      this.fixedProjectStructure.sections[1].contents[0].content[1].elements[0][
        "img"
      ] = this.keySave + "_Section_1"+".png";
    }
  }

    // Section 2
    if(this.filesPath[2] !=""){
    if (this.projectStructure.sections[2].contents[0].content[2].elements[0]["img"]) {
      this.projectStructure.sections[2].contents[0].content[2].elements[0][
        "img"
      ] = this.keySave + "_Section_2"+".png";
      this.fixedProjectStructure.sections[2].contents[0].content[2].elements[0][
        "img"
      ] = this.keySave + "_Section_2"+".png";
    }
  }

    // Section 3
    if(this.filesPath[3] !=""){
    if (this.projectStructure.sections[3].contents[0].content[2].elements[0]["img"]) {
      this.projectStructure.sections[3].contents[0].content[2].elements[0][
        "img"
      ] = this.keySave + "_Section_3"+".png";
      this.fixedProjectStructure.sections[3].contents[0].content[2].elements[0][
        "img"
      ] = this.keySave + "_Section_3"+".png";
    }
  }
  }

  initname2(){

    if(this.filesPath[0] !=""){
    this.projectStructure.background= this.unumber+"_Section_0"+".png";
    }
    // Section 1
    if(this.filesPath[1] !=""){
    if(this.projectStructure.sections[1].contents[0].content[1].elements[0]['img']){
      this.projectStructure.sections[1].contents[0].content[1].elements[0]['img'] = this.unumber+"_Section_1"+".png";

    }
  }

    // Section 2
    if(this.filesPath[2] !=""){
    if(this.projectStructure.sections[2].contents[0].content[2].elements[0]['img']){
      this.projectStructure.sections[2].contents[0].content[2].elements[0]['img'] = this.unumber+"_Section_2"+".png";

    }
  }
    // Section 3
    if(this.filesPath[3] !=""){
    if(this.projectStructure.sections[3].contents[0].content[2].elements[0]['img']){
      this.projectStructure.sections[3].contents[0].content[2].elements[0]['img'] = this.unumber+"_Section_3"+".png";
    }
  }
  }
}
