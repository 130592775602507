import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgxPaginationModule } from 'ngx-pagination';
import { ResponsiveModule } from 'ngx-responsive';
import { ToastrModule } from 'ngx-toastr';
//import { NgxBootstrapMultiselectModule } from 'ngx-bootstrap-multiselect';
//import { MultiSelectAllModule } from '@syncfusion/ej2-angular-dropdowns';
//import { NgSelectModule } from '@ng-select/ng-select'; 
import { NgMultiSelect9DropDownModule } from 'ng-multiselect-dropdown9';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SigninComponent } from './backoffice/signin/signin.component';
import { SignupComponent } from './backoffice/signup/signup.component';
import { UserProfileComponent } from './backoffice/user-profile/user-profile.component';
//import {MainDashboardComponent} from './backoffice/user-profile/main-dashboard/main-dashboard.component';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomeCMSComponent } from './backoffice/pages/cms/home-cms/home-cms.component';
import { AuthInterceptor } from './_shared/authconfig.interceptor';
import { ControlMessagesComponent } from './_shared/messages/message-controls';
import { FilterPipe } from './_shared/pipes/filter.pipe';
import { SafePipe } from './_shared/pipes/safe.pipe';


import { SwiperModule } from 'swiper/angular';

import { GalleryModule } from '@ks89/angular-modal-gallery';
import 'hammerjs';
import 'mousetrap';
// import {KEYBOARD_CONFIGURATION, KeyboardService} from '@ks89/angular-modal-gallery/lib/services/keyboard.service';

import { DeviceDetectorModule } from 'ngx-device-detector';

// Importing the custom directive
import { LazyloadImageDirective } from 'src/app/_shared/directive/lazyload-image.directive';

// import filepond module
import { FilePondModule, registerPlugin } from 'ngx-filepond';

// import and register filepond file type validation plugin
import * as FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
// tslint:disable-next-line:import-spacing
import * as FilepondPluginImagePreview from 'filepond-plugin-image-preview';

// tslint:disable-next-line:import-spacing
import * as FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';

registerPlugin(FilePondPluginFileValidateType, FilepondPluginImagePreview, FilePondPluginFileValidateSize);

import { AngularEditorModule } from '@kolkov/angular-editor';
import { AddComponent } from './backoffice/pageBuilder/project/add/add.component';
import { ListComponent } from './backoffice/pageBuilder/project/list/list.component';
import { UpdateComponent } from './backoffice/pageBuilder/project/update/update.component';
import { ReplacePipe } from './pipe/replace.pipe';
//import { ProjectsV2Component } from './frontoffice/projects-v2/projects-v2.component';
import { AddComponent as AddActualiteComponent } from './backoffice/pageBuilder/actualite/add/add.component';
import { ListComponent as ListActualiteComponent } from './backoffice/pageBuilder/actualite/list/list.component';
import { PreloaderComponent } from './backoffice/_common/preloader/preloader.component';
/*
import { ListComponent as ListFamilleComponent } from './backoffice/pageBuilder/famille/list/list.component';
import { ListComponent as ListMarqueComponent } from './backoffice/pageBuilder/marque/list/list.component';
*/
import { UpdateComponent as UpdateActualiteComponent } from './backoffice/pageBuilder/actualite/update/update.component';
//import { UpdateComponent as UpdateMarqueComponent } from './backoffice/pageBuilder/marque/update/update.component';
//import { NewsV2Component } from './frontoffice/news-v2/news-v2.component';
//import { NewsdetailV2Component } from './frontoffice/news-v2/newsdetail-v2/newsdetail-v2.component';
//import { CollectionsV2Component } from './frontoffice/collections-v2/collections-v2.component';
/*
import { AddComponent as AddFamilleComponent } from './backoffice/pageBuilder/famille/add/add.component';
import { AddComponent as AddMarqueComponent } from './backoffice/pageBuilder/marque/add/add.component';
*/
import { AproposComponent } from './backoffice/pages/cms/apropos/apropos.component';
import { ContactBOComponent } from './backoffice/pages/cms/contact-bo/contact-bo.component';
import { FaqCMSComponent } from './backoffice/pages/cms/faq-cms/faq-cms.component';
import { NgxSpinnerModule } from "ngx-spinner";




@NgModule({
  declarations: [
    AppComponent,
    SigninComponent,
    SignupComponent,
    UserProfileComponent,
  //  MainDashboardComponent,
    ControlMessagesComponent,
    PreloaderComponent,
    SafePipe,
    FilterPipe,
    LazyloadImageDirective,
    HomeCMSComponent,
    SafePipe,
    ReplacePipe,
    ListComponent,
    AddComponent,
    UpdateComponent,
    //ProjectsV2Component,
    ListActualiteComponent,
    AddActualiteComponent,
   /* ListMarqueComponent,
    ListFamilleComponent,*/
    UpdateActualiteComponent,
   // UpdateMarqueComponent,
    //NewsV2Component,
    //NewsdetailV2Component,
    //CollectionsV2Component,
   // AddMarqueComponent,
    AproposComponent,
    ContactBOComponent,
    FaqCMSComponent,
    //AddFamilleComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    NgxPaginationModule,
    ResponsiveModule.forRoot(),
    SwiperModule,
    DeviceDetectorModule.forRoot(),
    GalleryModule.forRoot({shortcuts: ['ctrl+s', 'meta+s'], disableSsrWorkaround: true}),
    FilePondModule,
    AngularEditorModule,
    NgMultiSelect9DropDownModule.forRoot(),
    NgxSpinnerModule
    //NgxBootstrapMultiselectModule,
    //MultiSelectAllModule,
    //NgSelectModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {provide: LocationStrategy, useClass: PathLocationStrategy},
    ReplacePipe
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
