import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AuthService } from "../../_shared/auth.service";
import { HomePageService } from "../../_shared/home-page.service";

@Component({
  selector: "app-user-profile",
  templateUrl: "./user-profile.component.html",
  styleUrls: ["./user-profile.component.scss"],
})
export class UserProfileComponent implements OnInit {
  currentUser: Object = {};
  id: any;
  listPages: any;

  constructor(
    public authService: AuthService,
    private actRoute: ActivatedRoute,
    private homePageCms: HomePageService
  ) {
    this.id = this.actRoute.snapshot.paramMap.get("id");
    this.authService.getUserProfile(this.id).subscribe((res) => {
      
      this.currentUser = res.msg;
    });
    this.homePageCms.get_page_list().subscribe(listdata => {
      //console.log('lissssssss',listdata);
      this.listPages = listdata;
    })
  }
  logout(){
    this.authService.doLogout();
  }

  ngOnInit() {
    // if (!localStorage.getItem("page_js")) {
    //   localStorage.setItem("page_js", "no reload");
    //   location.reload();
    //   console.log(localStorage.getItem("page_js"));
    // } else {
    //   localStorage.removeItem("page_js");
    // }
  }
}
